import "../../../assets/css/style.css";

import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import "../../../assets/jquery/jquery.docout.js";
import "../../../assets/jquery/jquery.min.js";

import Jquerycomp from "../../../component/jquery/jquery";
import { useHistory } from "react-router-dom";
import useArticleDetailsHooks from "./ArticalDetailsHooks";

function ArticleDetailPage() {
  let history = useHistory();

  const {
    imag,
    mostvieweddata,
    featureddata,
    advertisementdata,
    blogdata,
    resultt,
    id,
  } = useArticleDetailsHooks();
  //function for most viewd data design
  const renderMostViedImage = () => {
    return (
      <div className="artdet_featrd_img_contnr">
        <div className="row">
          {" "}
          <div className="artdet_img_headd"> 最新コラム一覧</div>
          {mostvieweddata &&
            mostvieweddata.data &&
            mostvieweddata.data.blog.map((vieww, i) => {
              return (
                <div className="col-sm-6 algn_centr">
                  <a
                    className="remv_undrln"
                    href={"/article-details/" + vieww._id}
                  >
                    {" "}
                    <img
                      src={imag + vieww.image}
                      className="artdet_multi_img"
                    />{" "}
                    <div className="artdet_date_txt_contnr">
                      {" "}
                      <div className="artdet_img_below_date"> {vieww.date}</div>
                      <div className="artdet_img_below_txt">
                        {vieww.title}{" "}
                      </div>{" "}
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  //function for most featured data design
  const renderMostFeaturedArtDet = () => {
    return (
      <div className="artdet_col2_contnr1">
        <div className="column_col2_contnr1_head"> おすすめ記事一覧</div>
        {featureddata.data &&
          featureddata.data.blog.map((featrd, i) => {
            return (
              <div className="column_rightcont1">
                {" "}
                <div className="row margin_btm">
                  <div className="col-sm-3">
                    {" "}
                    <a
                      className="remv_undrln"
                      href={"/article-details/" + featrd._id}
                    >
                      <img
                        src={imag + featrd.image}
                        className="column_mostviewd_img"
                      />
                    </a>
                  </div>{" "}
                  <div className="col-sm-9">
                    <a
                      className="remv_undrln"
                      href={"/article-details/" + featrd._id}
                    >
                      {" "}
                      <div className="column_col2_contnr1_date">
                        {" "}
                        {featrd.date}{" "}
                      </div>
                      <div className="column_col2_contnr1_text">
                        {featrd.title}
                      </div>
                    </a>
                  </div>
                </div>
                <hr className="hrstyl" />{" "}
              </div>
            );
          })}
      </div>
    );
  };

  //function for banner design
  const renderAdvertiseBannersArtDet = () => {
    return (
      <div>
        {advertisementdata &&
          advertisementdata.data &&
          advertisementdata.data.banner.map((advrtsmnt, i) => {
            return (
              <div className="artdet_col2_contnr2">
                <div className="artdet_contnr2_txt">
                  <a target="_blank" href={"http://" + advrtsmnt.link}>
                    {" "}
                    <img src={imag + advrtsmnt.image} />
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    );
  };


  const renderTOCandTextContentsArtDet = () => {
    return (
      <div className="artdet_detailed_txt_contnr flt">
        <Jquerycomp id={id} />
      </div>
    );
  };
  //redirect to column page 

  const renderReturnToColumnButton = () => {
    return (
      <div className="algn_centr whitebg">
        <button onClick={() => history.push("/column")} className="artdet_btn ">
          <div className="fltlft">記事一覧に戻る</div>
          <div className="fltrgt">
            <i class="fas fa-chevron-right"></i>
          </div>
        </button>{" "}
      </div>
    );
  };

  const renderImageTitle = () => {
    return (
      <span className="artdet_main_head_font">
        {blogdata && blogdata.data && blogdata.data.title}{" "}
      </span>
    );
  };

  //redirect to single page
  const renderSingleImageArtDet = () => {
    return (
      <div>
        {" "}
        <img
          className="art_det_img"
          src={imag + (blogdata && blogdata.data && blogdata.data.image)}
        />
      </div>
    );
  };
  //toc heading design
  const renderTOCheading = () => {
    return (
      <div>
        <div className="artdet_heading__bar">目次</div>{" "}
        <div className="artdet_heading__para_cont">
          <div className="artdet_shorts_txt_contnr">
            {resultt &&
              resultt.map((res, i) => {
                return <div className="artdet_bullet_head_txt">.{res}</div>;
              })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        <Header />
        <div className="main_container1 flt">
          <div className="container">
            <div className="artdet_img_container">
              <div className="row">
                <div className="col-sm-8">
                  <div className="article_det_img_txt_contnr flt">
                    {renderImageTitle()}
                    {renderSingleImageArtDet()}
                    {renderTOCheading()}
                    {renderTOCandTextContentsArtDet()}
                    {renderReturnToColumnButton()}
                    <hr className="hrline_artdet"></hr>
                    {renderMostViedImage()}
                  </div>
                </div>
                <div className="col-sm-4">
                  {renderMostFeaturedArtDet()}
                  {renderAdvertiseBannersArtDet()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ArticleDetailPage;