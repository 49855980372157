import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import InfiniteScroll from "react-infinite-scroller";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import attach from "../../../../assets/image/attach_file.png"
import attach from "../../../../assets/images/icon-add.png";
import atchfile from "../../../../assets/images/icon-file.png";

import atchimg from "../../../../assets/images/icon-photo.png";
import atchvideo from "../../../../assets/images/icon-video.png";
import send from "../../../../assets/images/send_instance.png";
import "./chat.css";
import "./mainUI.css";
import jQuery from "jquery";
import Sidebar from "../../UserPanel/Userpanel";
import MessageServices from "../../../../_services/message.services";
import $ from "jquery";
import message from "../../../_common/Message/message";
import io from "socket.io-client";
import * as Scroll from "react-scroll";
import ReactPlayer from "react-player";
import { Player } from "video-react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import FileViewer from "react-file-viewer";
import Linkify from "react-linkify";
// import Jquerycomp from "../../../component/jquery/jquery";
import "../../../../assets/jquery/jquery.docout.js";
import "../../../../assets/jquery/jquery.min.js";
import Jquerycomp from "../../../jquery/jquery";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { notficationAction } from "../../../../_actions/notification.action";
import { setLocalData } from "../../../../utility";

const finaluserid1 = localStorage.getItem(
  `${process.env.REACT_APP_PROJECT_NAME}` + "_user_id_fetched"
);
// const finaluserid = localStorage.getItem(JSON.parse(finaluserid1));

function Chat({ socket, username, settingChatId }) {
  console.log("chat page");
  const [chatidd, setChatidd] = useState("");

  const [getchatt, setGetchatt] = useState(null);
  const [getmessagee, setGetmessagee] = useState([]);
  const [fileuploadd, setFileuploadd] = useState([]);
  const [filetype, setFiletype] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [filedetails, setFiledetials] = useState([]);
  const [getroleid, setGetroleid] = useState([]);
  const [imagee, setImagee] = useState({ preview: "", raw: "" });
  const [fileUploadAPIinput, setFileUploadAPIinput] = useState();
  const [scrollplace, setScrollplace] = useState();
  const [videopop, setVideopop] = useState(false);
  const [imagepop, setImagepop] = useState(false);
  const [chatcount, setChatcount] = useState();
  const [loader, setLoader] = useState(false);
  const [projectTwoButtonData, setProjectTwoButtonsData] = useState([]);
  const [moreOpt, setMoreOpt] = useState(false);
  const [ShowAvialert, setShowAvialert] = useState(false);
  const [ImgShowFileSizealert, setImgShowFileSizealert] = useState(false);
  const [PdfShowFileSizealert, setpdfShowFileSizealert] = useState(false);
  const [chatCount, setchatCount] = useState(1);

  const dispatch = useDispatch();
  // let adm_id = `${process.env.REACT_APP_admm_id}`
  // let adm_id = "5c1b2f3537c29512c4294680";
  let history = useHistory();
  const imag = process.env.REACT_APP_MEDIA_DOMAIN;

  var underscoreID1 = localStorage.getItem(
    `${process.env.REACT_APP_PROJECT_NAME}` + "_UsersId"
  );
  var underscoreID = JSON.parse(underscoreID1);

  var author = localStorage.getItem(
    `${process.env.REACT_APP_PROJECT_NAME}` + "_UserInfo"
  );
  const [pagee, setPagee] = useState(2);
  const [totpagenumm, setTotpagenumm] = useState();
  const [pagecurr, setPagecurr] = useState();
  const [videourlusestate, setVideourlusestate] = useState();
  // const [chatStatusHere, setChatStatusHere] = useState(0);

  const [imageurlusestate, setImageurlusestate] = useState();
  const [filedetailsusesate, setFiledetialsusesate] = useState();
  const [fileSentToServer, setFileSentToServer] = useState();
  const [showFileSizeAlert, setShowFileSizealert] = useState(false);
  const [projectaccepted, setProjectaccepted] = useState(false);
  const [projectButtonsShow, setProjectButtonsShow] = useState(true);

  const [msgidusestate, setMsgiddusestate] = useState();
  const notficationCount = useSelector(
    (state) => state.noficationCount.chatCount
  );

  const bottomRef = useRef();
  const [imageAsFile, setImageAsFile] = useState();
  const [imageAsUrl, setImageAsUrl] = useState();
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  const scrollToBottom = (val = 0) => {
    console.log("scroll to bottom");
    var objDiv = document.getElementById("chatdiv");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  const showMore = () => {
    setMoreOpt(!moreOpt);
  };

  let search = "";

  setTimeout(() => {
    // console.log("Auto scroll")
    // jQuery.each(jQuery("textarea[data-autoresize]"), function () {
    //   var offset = this.offsetHeight - this.clientHeight;
    //   var resizeTextarea = function (el) {
    //     jQuery(el)
    //       .css("height", "auto")
    //       .css("height", el.scrollHeight + offset);
    //   };
    //   jQuery(this)
    //     .on("keyup input", function () {
    //       resizeTextarea(this);
    //     })
    //     .removeAttr("data-autoresize");
    // });
    // scrollToBottom(200);
  }, 200);

  function resizeTextArea() {
    setTimeout(() => {
      $("textarea").val("");
      $("textarea").css({ height: "44px" });
    }, 100);
  }

  const FileInputHandler = (e) => {
    setMoreOpt(false);
    // image var holds the file object which has a type property
    var filees = e.target.files[0];
    setFiledetialsusesate(e.target.files[0]);
    console.log("coool" + filees.type);
    //  console.log("okkk" + JSON.stringify(filees)); // this will output the mime, i.e "image/png" or "image/jpg"
    var filetypes = filees.type.split("/");
    var finalfiletype = filetypes[1];
    setImageAsFile(finalfiletype);

    if (e.target.files.length !== 0) {
      console.log("GODDDDD" + JSON.stringify(e.target.files));
      setFileUploadAPIinput(e.target.files[0]);
      setImagee({
        preview: URL.createObjectURL(e.target.files[0]),
        // raw: e.target.files[0],
      });
      e.target.value = "";
      // let filedetails = e.target.files[0];
      // setFiledetials(filedetails);
    }
    // alert(JSON.stringify();
    console.log(filees);
    if (filees.size > 100000000) {
      // alert(JSON.stringify(filees.size));
      setShowFileSizealert(true);
      setImagee({ preview: null });
      setTimeout(() => {
        setShowFileSizealert(false);
      }, 2500);
    }

    var fileType = filees.type.split("/")[0];

    if (filees.size > 8000000 && fileType === "image") {
      setImgShowFileSizealert(true);
      setImagee({ preview: null });
      setTimeout(() => {
        setImgShowFileSizealert(false);
      }, 2500);
    }

    if (filees.size > 20000000 && fileType === "pdf") {
      setpdfShowFileSizealert(true);
      setImagee({ preview: null });
      setTimeout(() => {
        setpdfShowFileSizealert(false);
      }, 2500);
    }

    if (filees.type === "video/avi") {
      // alert(JSON.stringify(filees.size));
      setShowAvialert(true);
      setImagee({ preview: null });
      setTimeout(() => {
        setShowAvialert(false);
      }, 2500);
    }
  };

  if (underscoreID == null) {
    history.push("/login");
  }

  const close = () => {
    setImagee({ preview: null });
  };

  function Playvideo(VideoUrl) {
    setVideourlusestate(VideoUrl);
    setVideopop(true);
  }

  function OpenImage(ImageDBUrl) {
    setImageurlusestate(ImageDBUrl);
    setImagepop(true);
  }

  const closepop = () => {
    setVideopop(false);
  };

  const closeImgpop = () => {
    setImagepop(false);
  };

  const ProjectAccepted = (msggidd) => {
    let chat_id = chatidd;

    // if (chat_id !== false) {
    //   let page = "";
    //   let search = "";
    //   MessageServices.get_message(chat_id, page, search).then((data) => {
    //     setGetmessagee(
    //       data &&
    //       data.data &&
    //       data.data.message &&
    //       data.data.message.docs.reverse()
    //     );
    //   });
    // }
    confirmAlert({
      message: "そのプロジェクトを受け入れたいと確信していますか？",
      buttons: [
        {
          label: "はい",
          onClick: () => {
            // event.stopPropagation();
            //commented alert(msggidd);
            const msgId = msggidd;
            let chat_status = 1;
            MessageServices.AcceptOrCorrect(chat_status, msgId).then(
              (acceptdocorrectiondata) => {
                setProjectTwoButtonsData(acceptdocorrectiondata);
                // setLoader(false);

                // setFileSentToServer(fileUploadData);
                // sendAttachmentToServer(fileUploadData);
              }
            );
            // setChatStatusHere(1);
            setProjectaccepted(true);
            // setProjectButtonsShow(false);

            // if (chat_id !== false) {
            //   let page = "";
            //   let search = "";
            //   MessageServices.get_message(chat_id, page, search).then(
            //     (data) => {
            //       setGetmessagee(
            //         data &&
            //         data.data &&
            //         data.data.message &&
            //         data.data.message.docs.reverse()
            //       );
            //     }
            //   );
            // }
            // if (chat_id !== false) {
            //   let page = "";
            //   let search = "";
            //   MessageServices.get_message(chat_id, page, search).then(
            //     (data) => {
            //       setGetmessagee(
            //         data &&
            //         data.data &&
            //         data.data.message &&
            //         data.data.message.docs.reverse()
            //       );
            //     }
            //   );
            // }
            // if (chat_id !== false) {
            //   let page = "";
            //   let search = "";
            //   MessageServices.get_message(chat_id, page, search).then(
            //     (data) => {
            //       setGetmessagee(
            //         data &&
            //         data.data &&
            //         data.data.message &&
            //         data.data.message.docs.reverse()
            //       );
            //     }
            //   );
            // }
            // if (chat_id !== false) {
            //   let page = "";
            //   let search = "";
            //   MessageServices.get_message(chat_id, page, search).then(
            //     (data) => {
            //       setGetmessagee(
            //         data &&
            //         data.data &&
            //         data.data.message &&
            //         data.data.message.docs.reverse()
            //       );
            //     }
            //   );
            // }
            // if (chat_id !== false) {
            //   let page = "";
            //   let search = "";
            //   MessageServices.get_message(chat_id, page, search).then(
            //     (data) => {
            //       setGetmessagee(
            //         data &&
            //         data.data &&
            //         data.data.message &&
            //         data.data.message.docs.reverse()
            //       );
            //     }
            //   );
            // }
            if (chat_id !== false) {
              let page = "";
              let search = "";
              MessageServices.get_message(chat_id, page, search).then(
                (data) => {
                  setGetmessagee(
                    data &&
                      data.data &&
                      data.data.message &&
                      data.data.message.docs.reverse()
                  );
                }
              );
            }
            if (chat_id !== false) {
              let page = "";
              let search = "";
              MessageServices.get_message(chat_id, page, search).then(
                (data) => {
                  setGetmessagee(
                    data &&
                      data.data &&
                      data.data.message &&
                      data.data.message.docs.reverse()
                  );
                }
              );
            }
          },
        },
        {
          label: "いいえ",
          onClick: () => null,
        },
      ],
    });
  };

  useEffect(() => {
    console.log("useE get all chats");
    MessageServices.getchat().then((data) => {
      setGetchatt(data);

      if (data !== null) {
        let page = "";
        let search = "";
        let chat_id = data.data && data.data.length > 0 && data.data[0].chat_id;
        // settingChatId(chat_id);

        localStorage.setItem("chatId", chat_id);
        // alert("111again" + chat_id);
        //  alert(chat_id);
        setChatidd(chat_id);
        // alert("222again" + chat_id);

        // alert(chat_id);
        if (chat_id !== false) console.log("get singlemessga");
        MessageServices.get_message(chat_id, page, search).then((data) => {
          setGetmessagee(
            data &&
              data.data &&
              data.data.message &&
              data.data.message.docs.reverse()
          );

          setGetroleid(
            data &&
              data.data &&
              data.data.message &&
              data.data.message.docs &&
              data.data.message.docs._id
          );

          setFiletype(
            data &&
              data.data &&
              data.data.message &&
              data.data.message.file_type
          );
        });
      }
    });
    scrollToBottom(1000); //added
    dispatch(notficationAction.notificationClear(chatidd));
  }, [notficationCount]);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date_ob1 = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });

  var date_ob = new Date(date_ob1);
  let date = ("0" + date_ob.getDate()).slice(-2);
  let month = monthNames[date_ob.getMonth()];
  let year = date_ob.getFullYear();
  let hours = date_ob.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  var time = date + "-" + month + "-" + year + " " + hours;

  // function ScrollDown() {
  //   window.scroll({
  //     top: document.body.offsetHeight,
  //     left: 0,
  //     behavior: 'smooth',
  //   });
  // async function AcceptOrCorrect(chat_status, chatt_idd) {
  //   var bodyFormData = new FormData();
  //   bodyFormData.append("chat_status", chat_status);
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       ...authHeader(),
  //       "Content-Type": "multipart/form-data",
  //     },
  //     url: `${process.env.REACT_APP_BASE_URL}users/chat-status/${chatt_idd}`,
  //     data: bodyFormData,
  //   };

  // }

  // const ProjectAccepted = (msggidd) => {
  //   alert(msggidd);
  //   const msgId = msggidd;
  //   let chat_status = 1;
  //   MessageServices.AcceptOrCorrect(chat_status, msgId).then(
  //     (acceptdocorrectiondata) => {
  //       setProjectTwoButtonsData(acceptdocorrectiondata);
  //       // setLoader(false);

  //       // setFileSentToServer(fileUploadData);
  //       // sendAttachmentToServer(fileUploadData);
  //     }
  //   );

  //   setProjectaccepted(true);
  //   setProjectButtonsShow(false);
  // };

  const Correction_btn_handler = (msggidd) => {
    // history.push("/cp");
    // setMsgiddusestate(msgIdd)
    // commented alert(msggidd);
    const chat_status = -1;
    const msgId = msggidd;
    MessageServices.AcceptOrCorrect(chat_status, msgId).then(
      (acceptdocorrectiondata) => {
        setProjectTwoButtonsData(acceptdocorrectiondata);
        // setLoader(false);

        // setFileSentToServer(fileUploadData);
        // sendAttachmentToServer(fileUploadData);
      }
    );
    let chat_id = chatidd;

    if (chat_id !== false) {
      let page = "";
      let search = "";
      MessageServices.get_message(chat_id, page, search).then((data) => {
        setGetmessagee(
          data &&
            data.data &&
            data.data.message &&
            data.data.message.docs.reverse()
        );
      });
    }
    // setChatStatusHere(-1);
    const win = window.open("/cp", "_self");
    win.focus();
  };

  async function SocketEmit(message) {
    // message.userId = message.userId._id;
    console.log("lax1 " + JSON.stringify(getmessagee));

    setGetmessagee([...getmessagee, message]);
    console.log("lax2  " + JSON.stringify(getmessagee));
    console.log(getmessagee.length);

    setImagee({ preview: null });

    // alert(JSON.stringify(message));
    if (socket !== null) socket.emit("new message", message);
    // alert("hi");
    setTimeout(() => {
      scrollToBottom(2000);
    }, 200);

    setFileUploadAPIinput("");
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom(1000);
    }, 500);
  }, []);

  // DownloadMedia = (function (file_path)

  //   function DownloadMedia()
  //   {
  //     var a = window.createElement("a");
  //     window.body.appendChild(a);
  //     a.style = "display: none";
  //     return function (fileName) {
  //       var json = JSON.stringify(fileName),
  //         blob = new Blob([json], { type: "text/stream" }),
  //         url = window.URL.createObjectURL(blob);
  //       a.href = url;
  //       a.download = fileName;
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     };

  // };

  const SendMessage = async () => {
    let file_path = fileUploadAPIinput;
    console.log("checking file path" + imagee.preview);

    console.log(
      "checking file uploadAPIinput ^^^^^$$$$$$$$$$$$^^^^^^" + imageAsFile
    );
    console.log(
      "checking file uploadAPIinput ^^^^^^#######^^^^^" + fileUploadAPIinput
    );
    if (imagee.preview) {
      setLoader(true);
      MessageServices.fileUpload(file_path).then((fileUploadData) => {
        setLoader(false);

        setFileSentToServer(fileUploadData);
        sendAttachmentToServer(fileUploadData);
      });
    } else sendMsgToServer();
  };

  // if (
  //   fileSentToServer &&
  //   fileSentToServer.status &&
  //   fileSentToServer.status.code !== 0
  // ) {
  //   alert("sending");
  // } else {
  //   // alert("success");
  // }

  const sendAttachmentToServer = (fileUploadData) => {
    let file_path = fileUploadAPIinput;

    setFileuploadd(fileUploadData);
    if (imageAsFile && file_path && currentMessage !== null) {
      const message = {
        message: currentMessage.trim(),
        userId: underscoreID,
        file_path:
          fileUploadData &&
          fileUploadData.data &&
          fileUploadData.data.file_path,
        type: "image",
        file_name: fileUploadData.data.file_name,
        file_type:
          fileUploadData &&
          fileUploadData.data &&
          fileUploadData.data.file_type,
        category: 1,
        chat_id: chatidd,
        time: "just now",
        mId: underscoreID + "_" + Date.now(),
      };
      // alert("after send msg again1" + message.chat_id);

      var userdata = message;
      userdata.userId = JSON.parse(author);

      SocketEmit(message);
      resizeTextArea();
      // alert(JSON.stringify(message));
      // alert(underscoreID);
    }

    setCurrentMessage("");
  };

  const sendMsgToServer = () => {
    const message = {
      // room: room,
      // image:imagee,
      message: currentMessage.trim(),
      userId: underscoreID,
      file_path: "",
      // image: imagee,
      type: "message",
      file_name: "",
      file_type: "",
      category: 1,
      chat_id: chatidd,
      time: "just now",
      mId: underscoreID + "_" + Date.now(),
      // author:author
    };
    // alert("after send msg again22" + message.chat_id);

    // alert(JSON.stringify(message));
    var userdata = message;
    userdata.userId = JSON.parse(author);

    SocketEmit(message);

    resizeTextArea();
    //  alert(underscoreID);

    setCurrentMessage("");
  };

  useEffect(() => {
    socket.on("new message", (data) => {
      // alert(data)
      // setGetmessagee([...getmessagee,data])
      // setchatCount((count)=>count+1);
      console.log("hii");
      dispatch(notficationAction.notificationClear(chatidd));
    });
  }, [socket]);

  useEffect(() => {
    if (
      !localStorage.getItem(`${process.env.REACT_APP_PROJECT_NAME}` + "reload")
    ) {
      //  alert('ffff');
      localStorage.setItem(
        `${process.env.REACT_APP_PROJECT_NAME}` + "reload",
        1
      );

      window.location.reload();
    }

    window.addEventListener("scroll", HandleScroll);
    return () => window.removeEventListener("scroll", HandleScroll);
  }, []);

  function HandleScroll(event) {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (scrollTop === 0) {
      console.log("Fetch more list items!%%%");

      let chat_ide = chatidd;

      console.log("chat id isssssssss" + chat_ide);

      var objDiv = document.getElementById("chatdiv");

      var objscrollheightprev = objDiv.scrollHeight;
      MessageServices.get_message(chat_ide, pagee, search).then((data) => {
        console.log("pagination  " + JSON.stringify(data));
        setGetmessagee(data.data.message.docs.reverse().concat(getmessagee));
        setPagee(pagee + 1);
        setPagecurr(data.data.message.page);
        setTotpagenumm(data.data.message.totalPages);

        var newdata = getmessagee[getmessagee.length - 11];

        dispatch(notficationAction.notificationClear(chat_ide));

        var objscrollheightnext = objDiv.scrollHeight;
        if (
          (data && data.data && data.data.message && data.data.message.page) !==
          (data &&
            data.data &&
            data.data.message &&
            data.data.message.totalPages)
        ) {
          $("#chatdiv").animate(
            { scrollTop: objscrollheightnext - objscrollheightprev },
            "fast"
          );
        }
      });
    }
  }

  // if
  //   (pagecurr !== totpagenumm
  // ) {
  //   $("#chatdiv").animate({ scrollTop: 300 }, "fast");
  // }
  // $(document).ready(function () {
  //   $(".atchimglbl").click(function () {
  //     $(".option_view").toggle();
  //   });
  // });

  useEffect(() => {
    dispatch(notficationAction.notificationClear(chatidd));
  }, [chatidd]);

  return (
    // <div className="flex">
    <div className="chat-window" id="chatwindowdiv">
      {loader && (
        <Loader
          type="TailSpin"
          color="#00BFFF"
          height={100}
          width={100}
          //3 secs
          className="loader"
        />
      )}

      <div className="chat-header">
        <p>管理者とチャット</p>
        <p className="chat-sub-header">
          この度は印コーディングサービスをご利用いただきありがとうございます。このチャット画面から全て進行いたします。{" "}
          <br />
          ご連絡、お見積もりデータの送付、お見積もり、制作開始進行、修正対応、ご納品。{" "}
          <br />
          わからないことがあればどんあことでもお気軽にご連絡ください。 <br />
          <br /> <br />
          ＜＜いまだけキャンペーン＞＞ <br />
          お友達に登録時に「チャットよりご紹介様様のお名前をチャットしてください」とお伝えください！{" "}
          <br />
          お友達の方はご紹介様のお名前をまずはチャットいただければ、ご依頼時1万円の割引チケットをお渡しいたします。{" "}
          <br />
          （このチャットの後にお願いします） <br />
          <br />
          またご紹介いただいた方にもクーポンを配布します！
          <br />
          是非ご連絡おまちしております。
        </p>
      </div>
      {/* <div className="chat-body" onScroll={handleScroll()}> */}
      <div
        id="chatdiv"
        className="chat-body scrollcss"
        onScroll={(event) => HandleScroll(event)}
      >
        {videopop && (
          <div className="videopopup">
            <i className="fas fa-times-circle rm_icon1" onClick={closepop}>
              {" "}
            </i>
          </div>
        )}

        {imagepop && (
          <div className="videopopup">
            <i className="fas fa-times-circle rm_icon1" onClick={closeImgpop}>
              {" "}
            </i>
          </div>
        )}

        {/* <ScrollToBottom className="message-container" id="scrolldiv"> */}
        {/* <div className="message-container" > */}

        {/* <InfiniteScroll
            pageStart={0}
            loadMore={HandleScroll()}
            hasMore={true || false}
          > */}
        {/* {console.log(
            "trying to print message" +
              JSON.stringify(
                getmessagee.data &&
                  getmessagee.data.message &&
                  getmessagee.data.message.docs
              )
          )} */}

        {getmessagee &&
          getmessagee.map((messageCont, i) => {
            return (
              //                 <InfiniteScroll
              //   dataLength={messageCont.message.length} //This is important field to render the next data
              //   next={messageCont.message}
              //   hasMore={true}
              //   loader={<h4>Loading...</h4>}
              //   endMessage={
              //     <p style={{ textAlign: 'center' }}>
              //       <b>Yay! You have seen it all</b>
              //     </p>
              //   }

              // >
              <div
                className="message"
                id={
                  underscoreID ===
                  (messageCont.userId && messageCont.userId._id)
                    ? "other"
                    : "you"
                }
              >
                <div className="c_chatdiv flt">
                  {console.log("type is" + messageCont.type)}
                  {messageCont.message && messageCont.type == "link" ? (
                    <div className="c_chatin flt">
                      {(messageCont.file_type == "image" ||
                        messageCont.file_type == "png" ||
                        messageCont.file_type == "PNG") && (
                        // <button
                        //   className="btnonimage"
                        //   onClick={() =>
                        //     OpenImage(imag + messageCont.file_path)
                        //   }
                        // >
                        <div
                          className="message-content1"
                          id={
                            underscoreID ===
                            (messageCont.userId && messageCont.userId._id)
                              ? "other"
                              : "you"
                          }
                        >
                          {" "}
                          <a href={imag + messageCont.file_path} download>
                            <button className="image_download" onClick="">
                              ダウンロード
                              <i class="fas fa-download fadownload"></i>
                            </button>
                          </a>
                          {underscoreID ===
                            (messageCont.userId && messageCont.userId._id) && (
                            <button
                              className="btnonimage"
                              onClick={() =>
                                OpenImage(imag + messageCont.file_path)
                              }
                            >
                              {/* <a href={imag + messageCont.file_path} download> */}

                              <p id="userId">
                                <img src={imag + messageCont.file_path}></img>
                              </p>
                              {/* </a> */}
                            </button>
                          )}
                          {underscoreID !==
                            (messageCont.userId && messageCont.userId._id) && (
                            <button
                              className="btnonimage"
                              onClick={() =>
                                OpenImage(imag + messageCont.file_path)
                              }
                            >
                              <p id="userId">
                                <img src={imag + messageCont.file_path}></img>
                              </p>
                            </button>
                          )}
                        </div>

                        // </button>
                      )}

                      {messageCont.file_type == "video" && (
                        // imagee.preview && messageCont.file_type == "video" &&
                        <div
                          className="message-contentv"
                          id={
                            underscoreID ===
                            (messageCont.userId && messageCont.userId._id)
                              ? "other"
                              : "you"
                          }
                        >
                          <p id="userId">
                            <div className="playbtn">
                              <a
                                href={imag + messageCont.file_path}
                                download
                                className="downloadtxt"
                              >
                                <button className="video_download" onClick="">
                                  ダウンロード
                                  <i class="fas fa-download fadownload"></i>
                                </button>
                              </a>
                              <button
                                className="playbtnvideo"
                                onClick={() =>
                                  Playvideo(imag + messageCont.file_path)
                                }
                              >
                                <i class="fas fa-play playinvideo"></i>
                              </button>
                              {/* {videopop && (
                              <div className="videopopup">
                                <i
                                  className="fas fa-times-circle rm_icon1"
                                  onClick={closepop}
                                >
                                  {" "}
                                </i>
                              </div>
                            )} */}

                              <video>
                                <source
                                  src={imag + messageCont.file_path}
                                  type="video/mp4"
                                  className="videodisp"
                                />
                              </video>

                              {videopop && (
                                <div className="videopopup">
                                  <i
                                    className="fas fa-times-circle rm_icon1"
                                    onClick={closepop}
                                  ></i>
                                  <div className="videoouterbox">
                                    <Player autoPlay={true}>
                                      <source
                                        src={videourlusestate}
                                        type="video/mp4"
                                      />
                                    </Player>
                                  </div>
                                </div>
                              )}
                              {/* <video className="videodisplay">
                              <source
                                src={imag + messageCont.file_path}
                                type="video/mp4"
                              />
                            </video> */}
                            </div>
                          </p>
                        </div>
                      )}

                      {messageCont.file_type !== "image" &&
                        messageCont.file_type !== "video" &&
                        messageCont.file_type !== "" && (
                          // imagee.preview && messageCont.file_type !== "video" && messageCont.file_type !== "image" &&

                          <div
                            className="message-contentf"
                            id={
                              underscoreID ===
                              (messageCont.userId && messageCont.userId._id)
                                ? "other"
                                : "you"
                            }
                          >
                            <a href={imag + messageCont.file_path} download>
                              <p id="userId">
                                <div
                                  type={messageCont.type}
                                  filePath={imag + messageCont.file_path}
                                >
                                  {" "}
                                  <div className="flex_file" onClick="">
                                    <div>
                                      <i class="fas fa-file filefa"></i>
                                    </div>
                                    <div className="filezip">
                                      {" "}
                                      {messageCont.file_name &&
                                      messageCont.file_name.length > 20
                                        ? messageCont.file_name
                                            .split(".")[0]
                                            .substr(0, 10) +
                                          "..." +
                                          messageCont.file_name.split(".")[1]
                                        : messageCont.file_name}
                                    </div>
                                  </div>
                                  {/* <br /> <br />
                                <div>
                                  <i class="fas fa-download filedownldfa"></i>
                                </div>{" "} */}
                                </div>
                                {/* <hr className="filehr" /> <br />
                              <button className="file_download">
                                Download{" "}
                              </button> */}
                              </p>
                            </a>
                          </div>
                        )}

                      {/* &&&&&&&&&&&& */}
                      <div className="message-content">
                        <a href={messageCont.message}>{messageCont.message}</a>
                      </div>
                      <div className="flt">
                        {" "}
                        {(messageCont.chat_status == 0 ||
                          messageCont.chat_status == -1) &&
                          projectButtonsShow && (
                            <div>
                              <button
                                className="accept_btn"
                                onClick={() => ProjectAccepted(messageCont._id)}
                              >
                                受け入れる
                              </button>
                              <button
                                className="makecorrection_btn"
                                onClick={() =>
                                  Correction_btn_handler(messageCont._id)
                                }
                                target="_self"
                              >
                                修正する
                              </button>{" "}
                            </div>
                          )}
                        {messageCont.chat_status == 1 && (
                          <div className="sayflex">
                            {" "}
                            <div className="acceptedtxt">
                              プロジェクトを受け入れました。
                            </div>
                            <div>
                              {" "}
                              <i class="fas fa-check checkfa"></i>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="message-meta">
                        {/* <p id="author">Sent By:&nbsp;{messageContent.author}</p>{" "} */}
                        {/* <p id="userId">
                          Sent By:&nbsp;
                          {messageCont &&
                            messageCont.userId &&
                            messageCont.userId.name}
                        </p>{" "} */}
                        <p id="time"> {messageCont.time}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="c_chatin flt">
                      {" "}
                      {
                        // messageCont.file_type === "" &&
                        messageCont.message !== "" &&
                          messageCont.message !== null && (
                            <div>
                              <div className="message-content">
                                <Linkify
                                  classname="linkycolor"
                                  properties={{ target: "_blank" }}
                                >
                                  {messageCont.message}
                                </Linkify>

                                <div className="flt"></div>
                              </div>
                              <div className="flt"></div>
                            </div>
                          )
                      }
                      {(messageCont.file_type == "image" ||
                        messageCont.file_type == "png" ||
                        messageCont.file_type == "PNG") && (
                        // <button
                        //   className="btnonimage"
                        //   onClick={() =>
                        //     OpenImage(imag + messageCont.file_path)
                        //   }
                        // >
                        <div
                          className="message-content1"
                          id={
                            underscoreID ===
                            (messageCont.userId && messageCont.userId._id)
                              ? "other"
                              : "you"
                          }
                        >
                          {" "}
                          <a href={imag + messageCont.file_path} download>
                            <button className="image_download" onClick="">
                              ダウンロード
                              <i class="fas fa-download fadownload"></i>
                            </button>
                          </a>
                          {underscoreID ===
                            (messageCont.userId && messageCont.userId._id) && (
                            <button
                              className="btnonimage"
                              onClick={() =>
                                OpenImage(imag + messageCont.file_path)
                              }
                            >
                              {/* <a href={imag + messageCont.file_path} download> */}
                              <p id="userId">
                                <img src={imag + messageCont.file_path}></img>
                              </p>
                              {/* </a> */}
                            </button>
                          )}
                          {underscoreID !==
                            (messageCont.userId && messageCont.userId._id) && (
                            <button
                              className="btnonimage"
                              onClick={() =>
                                OpenImage(imag + messageCont.file_path)
                              }
                            >
                              <p id="userId">
                                <img src={imag + messageCont.file_path}></img>
                              </p>
                            </button>
                          )}
                        </div>

                        // </button>
                      )}
                      {imagepop && (
                        <div className="imgpopup">
                          <i
                            className="fas fa-times-circle rm_icon1"
                            onClick={closeImgpop}
                          ></i>
                          <div className="imageouterbox">
                            {/* <Player>
                                      <source
                                        src={videourlusestate}
                                        type="video/mp4"
                                      />
                              </Player> */}
                            <p id="userId" className="p_outside_image_pop">
                              <img
                                src={imageurlusestate}
                                className="imgseeinpop"
                              ></img>
                            </p>
                          </div>
                        </div>
                      )}
                      {messageCont.file_type == "video" && (
                        // imagee.preview && messageCont.file_type == "video" &&
                        <div
                          className="message-contentv"
                          id={
                            underscoreID ===
                            (messageCont.userId && messageCont.userId._id)
                              ? "other"
                              : "you"
                          }
                        >
                          <p id="userId">
                            <div className="playbtn">
                              <a
                                href={imag + messageCont.file_path}
                                download
                                className="downloadtxt"
                              >
                                <button className="video_download" onClick="">
                                  ダウンロード
                                  <i class="fas fa-download fadownload"></i>
                                </button>
                              </a>
                              <button
                                className="playbtnvideo"
                                onClick={() =>
                                  Playvideo(imag + messageCont.file_path)
                                }
                              >
                                <i class="fas fa-play playinvideo"></i>
                              </button>
                              {/* {videopop && (
                              <div className="videopopup">
                                <i
                                  className="fas fa-times-circle rm_icon1"
                                  onClick={closepop}
                                >
                                  {" "}
                                </i>
                              </div>
                            )} */}

                              <video>
                                <source
                                  src={imag + messageCont.file_path}
                                  type="video/mp4"
                                  className="videodisp"
                                />
                              </video>

                              {videopop && (
                                <div className="videopopup">
                                  <i
                                    className="fas fa-times-circle rm_icon1"
                                    onClick={closepop}
                                  ></i>
                                  <div className="videoouterbox">
                                    <Player autoPlay={true}>
                                      <source
                                        src={videourlusestate}
                                        type="video/mp4"
                                      />
                                    </Player>
                                  </div>
                                </div>
                              )}
                              {/* <video className="videodisplay">
                              <source
                                src={imag + messageCont.file_path}
                                type="video/mp4"
                              />
                            </video> */}
                            </div>
                          </p>
                        </div>
                      )}
                      {messageCont.file_type !== "image" &&
                        messageCont.file_type !== "video" &&
                        messageCont.file_type !== "" && (
                          // imagee.preview && messageCont.file_type !== "video" && messageCont.file_type !== "image" &&

                          <div
                            className="message-contentf"
                            id={
                              underscoreID ===
                              (messageCont.userId && messageCont.userId._id)
                                ? "other"
                                : "you"
                            }
                          >
                            <a href={imag + messageCont.file_path} download>
                              <p id="userId">
                                <div
                                  type={messageCont.type}
                                  filePath={imag + messageCont.file_path}
                                >
                                  {" "}
                                  <div className="flex_file" onClick="">
                                    <div>
                                      <i class="fas fa-file filefa"></i>
                                    </div>
                                    <div className="filezip">
                                      {" "}
                                      {messageCont.file_name &&
                                      messageCont.file_name.length > 20
                                        ? messageCont.file_name
                                            .split(".")[0]
                                            .substr(0, 10) +
                                          "..." +
                                          messageCont.file_name.split(".")[1]
                                        : messageCont.file_name}
                                    </div>
                                  </div>
                                  {/* <br /> <br />
                                <div>
                                  <i class="fas fa-download filedownldfa"></i>
                                </div>{" "} */}
                                </div>
                                {/* <hr className="filehr" /> <br />
                              <button className="file_download">
                                Download{" "}
                              </button> */}
                              </p>
                            </a>
                          </div>
                        )}
                      <div className="message-meta">
                        {/* <p id="author">Sent By:&nbsp;{messageContent.author}</p>{" "} */}
                        {/* ///below actual p commented/// */}
                        {/* <p id="userId">
                      Sent By:&nbsp;{messageCont.userId.name}
                    </p>{" "} */}
                        {/* <p id="time">on {messageCont.time}</p> */}
                        <p className="date_time_css" id="time">
                          {messageCont.time}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              // </InfiniteScroll>
            );
          })}
        {/* </InfiniteScroll> */}
        {/* </ScrollToBottom> */}
        {/* </div> */}
      </div>

      <div ref={bottomRef} className="chat_footer" id="chatfooterdiv">
        <div className="insidechatfooter flt flex">
          {imagee.preview &&
            (imageAsFile === "png" ||
              imageAsFile === "PNG" ||
              imageAsFile === "jpeg" ||
              imageAsFile === "jpg") && (
              <div className="uploaded_file">
                <img src={imagee.preview} alt="dummy" className="preview_img" />{" "}
                <i className="fas fa-times-circle rm_icon" onClick={close}>
                  {" "}
                </i>
              </div>
            )}

          {imagee.preview &&
            imageAsFile !== "png" &&
            imageAsFile !== "PNG" &&
            imageAsFile !== "jpeg" &&
            imageAsFile !== "jpg" &&
            imageAsFile !== "image" &&
            imageAsFile !== "video" &&
            imageAsFile !== "mp4" &&
            imageAsFile !== "mov" &&
            imageAsFile !== "mkv" &&
            imageAsFile !== "quicktime" &&
            imageAsFile !== "webm" &&
            imageAsFile !== "x-flv" && (
              <div className="uploaded_file">
                <div>
                  <i class="fas fa-file-alt filepreview"></i>
                </div>
                <i className="fas fa-times-circle rm_icon" onClick={close}>
                  {" "}
                </i>
              </div>
            )}

          {/* ////////////////////////////////// */}

          {imagee.preview &&
            (imageAsFile === "mp4" ||
              imageAsFile === "video" ||
              imageAsFile === "mov" ||
              imageAsFile === "mkv" ||
              imageAsFile === "quicktime" ||
              imageAsFile === "webm" ||
              imageAsFile === "x-flv") && (
              <div className="uploaded_file">
                <video
                  src={imagee.preview}
                  alt="dummy"
                  className="preview_img"
                />{" "}
                <i className="fas fa-times-circle rm_icon" onClick={close}>
                  {" "}
                </i>
              </div>
            )}

          {/* ////////////////////////////////// */}

          {/* {imagee.preview && (
            <div className="uploaded_file">
              <img src={imagee.preview} alt="dummy" className="preview_img" />{" "}
              <i className="fas fa-times-circle rm_icon" onClick={close}>
                {" "}
              </i>
            </div>
          )} */}
          {/* {imagee.preview && (
            <div className="uploaded_file">
              <Player>
                <source
                  src={imag + imagee.preview}
                  alt="dummy"
                  className="preview_img"
                />{" "}
                <i className="fas fa-times-circle rm_icon" onClick={close}>
                  {" "}
                </i>
              </Player>
            </div>
          )} */}

          {/* just to commit */}
          {showFileSizeAlert && (
            <div className="alert alert-danger" role="alert">
              zipファイルサイズは100MB以下
            </div>
          )}

          {ShowAvialert && (
            <div className="alert alert-danger" role="alert">
              AVI file not supported
            </div>
          )}

          {ImgShowFileSizealert && (
            <div className="alert alert-danger" role="alert">
              画像データサイズは8MB以下
            </div>
          )}

          {PdfShowFileSizealert && (
            <div className="alert alert-danger" role="alert">
              pdfでーたサイズは20MB以下
            </div>
          )}

          <textarea
            id="ipid"
            data-autoresize
            rows="3"
            className="input-group"
            type="text"
            name="message"
            id="grow"
            maxlength="10000"
            value={currentMessage}
            placeholder="ここにメッセージを入力してください..."
            onChange={(event) => {
              setCurrentMessage(event.target.value);
            }}

            // onKeyPress={(event) => {
            //   if (
            //     !event.key === "Enter" &&
            //     currentMessage.trim().length !== 0 &&
            //     SendMessage()
            //   );
            // }}
          />

          {/* onKeyPress={e => {
            if(e.key === 'Enter')
               e.preventDefault()
            }} */}

          {/* </textarea> */}

          <div className="atchimglbl" htmlFor="upload-button">
            {moreOpt && (
              <ul className="option_view">
                <li>
                  <a>
                    <img src={atchfile} alt="image" />
                  </a>
                  <input
                    type="file"
                    id="upload-button"
                    onChange={FileInputHandler}
                    accept=".doc,.docx,.pdf,.xlsx,.zip,.json"
                  />
                </li>
                <li>
                  <a>
                    <img src={atchimg} alt="image" />
                  </a>
                  <input
                    type="file"
                    id="upload-button"
                    onChange={FileInputHandler}
                    accept="image/*"
                  />
                </li>
                <li>
                  <a>
                    <img src={atchvideo} alt="image" />
                  </a>
                  <input
                    type="file"
                    id="upload-button"
                    onChange={FileInputHandler}
                    accept="video/*"
                  />
                </li>
              </ul>
            )}
            <img src={attach} className="atchimg" onClick={showMore} />
          </div>

          {/* <br />
      <button onClick={handleUpload}>Upload</button> */}
        </div>

        {/* <span className="upload-btn-wrapper">
            <input type="file" name="myfile" /> */}
        {/* <img src={attach} alt="white-rice" /> */}
        {/* </span> */}

        {/* <button onClick={sendMessage}>&#9658;</button> */}
        {(currentMessage.trim().length !== 0 || imagee.preview) && (
          <button className="sendbtnchat" onClick={messageList && SendMessage}>
            <img className="sendbtn" src={send} />
          </button>
        )}

        {/* <div>
      <label htmlFor="upload-button">
        {image.preview ? (
          <img src={image.preview} alt="dummy" width="300" height="300" />
        ) : (
          <>
            <img src={attach} className="atchimg" alt="white-rice" />
          </>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: "none" }}
        onChange={handleChange}
      />
      {/* <br />
      <button onClick={handleUpload}>Upload</button> */}
        {/* </div> */}
      </div>
    </div>
    // </div>
  );
}

export default Chat;
