import { useState, useEffect } from "react";
import "./Registration.css";
import { useHistory } from "react-router-dom";
// import message from '../../_Common/Message/message';
import { useDispatch } from "react-redux";
import { alertActions } from "../../../_actions/alert.actions";

import { registrationService } from "../../../_services/registration.services";
import message from "../../_common/Message/message";

function useRegistrationHooks() {
  //to move to the top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //setting state
  const [submitted, setSubmitted] = useState(false);
  const [postalcodeValid, setPostalcodeValid] = useState(true);
  const [postalcodelengthValid, setPostalcodelengthValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [numberValid, setNumberValid] = useState(true);
  const [numberValid1, setNumberValid1] = useState(true);
  const [numberValid2, setNumberValid2] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(true);
  const [passwordValid, setpasswordValid] = useState(true);
  const [preview, setPreview] = useState(true);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [current, setcurrent] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsg1, setErrorMsg1] = useState("");
  const [checked, setChecked] = useState(false);
  //object for different fields
  const [add, setAdd] = useState({
    name: "",
    name_double: "",
    password: "",
    mobile_no: "",
    address: "",
    postal_code: "",
    prefectures: "北海道",
    city: "",
    country: "",
    user_id: "",
    re_password: "",
    email: "",
  });
  //object for 3 fields of mobile number
  const [mbl, setmbl] = useState({
    mbl1: "",
    mbl2: "",
    mbl3: "",
  });

  localStorage.removeItem(`${process.env.REACT_APP_PROJECT_NAME}` + "_UsersId");
  localStorage.removeItem(
    `${process.env.REACT_APP_PROJECT_NAME}` + "_UserInfo"
  );
  localStorage.removeItem(
    `${process.env.REACT_APP_PROJECT_NAME}` + "_access_token"
  );
  localStorage.removeItem(
    `${process.env.REACT_APP_PROJECT_NAME}` + " user_id_fetched"
  );

  //disable submit button before clicking on check box
  function button_disable(e) {
    setChecked(e.target.checked);
  }

  //registration preview page scroll up on load
  useEffect(() => {
    window.scrollTo(0, 0);
    registrationService.getState().then((data) => {
      setcurrent(data);
    });
  }, []);

  //handle change on input fields
  function changeHandler(e) {
    //api call for checking whether user_id already exist or not,based on message
    if (e.target.name === "user_id") {
      registrationService.check_user(e.target.value).then((data) => {
        if (data.status.message === "UserID already exists") {
          setErrorMsg(data.status.message);
          // $(".msg").show();
        } else {
          setErrorMsg("");
        }
      });
    }
    //api call for checking whether email_id already exist or nor based on status code
    if (e.target.name === "email") {
      registrationService.check_email(e.target.value).then((data) => {
        if (data.status.code === 1) {
          setErrorMsg1("Email already exist");
        } else {
          setErrorMsg1("");
        }
      });
    }
    //generate error message on miss match of password
    if (e.target.name === "re_password") {
      if (add.password === e.target.value) {
        setPasswordCheck(true);
      } else {
        setPasswordCheck(false);
      }
    }
    //generate error message on miss match of password
    if (e.target.name === "password") {
      if (add.re_password === e.target.value) {
        setPasswordCheck(true);
      } else {
        setPasswordCheck(false);
      }
    }
    //generate error message on the length and pattern of password
    if (e.target.name === "password") {
      if (e.target.value) {
        //space
        e.preventDefault();
      }
      var filter = /^[0-9\b]+$/;
      if (e.target.value.length < 6) {
        setpasswordValid(false);
      } else {
        setpasswordValid(true);
      }
    }
    //phone number must be 11 digits long
    //  //generate error message on format of first 3 digit of phone number
    if (e.target.name === "mbl1") {
      var filter = /^[0-9\b]+$/;
      if (
        !filter.test(e.target.value) ||
        e.target.value.length < 2 ||
        e.target.value.length > 4
      ) {
        setNumberValid(false);
      } else {
        setNumberValid(true);
      }
    }

    //  //generate error message on format of next 4 digit of phone number
    if (e.target.name === "mbl2") {
      var filter = /^[0-9\b]+$/;
      if (
        !filter.test(e.target.value) ||
        e.target.value.length < 4 ||
        e.target.value.length > 4
      ) {
        setNumberValid1(false);
      } else {
        setNumberValid1(true);
      }
    }

    //  //generate error message on format of last 4 digit of phone number
    if (e.target.name === "mbl3") {
      var filter = /^[0-9\b]+$/;
      if (
        !filter.test(e.target.value) ||
        e.target.value.length < 4 ||
        e.target.value.length > 4
      ) {
        setNumberValid2(false);
      } else {
        setNumberValid2(true);
      }
    }

    //generate error message on format of postal code
    if (e.target.name === "postal_code") {
      var filter = /^[0-9\b]+$/;
      if (!filter.test(e.target.value)) {
        setPostalcodeValid(false);
      } else {
        setPostalcodeValid(true);
      }
    }
    // generate error message on length of postal code
    if (e.target.name === "postal_code") {
      if (e.target.value.length < 7 || e.target.value.length > 7) {
        setPostalcodelengthValid(false);
      } else {
        setPostalcodelengthValid(true);
      }
    }
    // generate error message on format of email id
    if (e.target.name === "email") {
      var filter =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(e.target.value)) {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
    }

    const { name, value } = e.target;

    setAdd((add) => ({ ...add, [name]: value }));
    setmbl((mbl) => ({ ...mbl, [name]: value }));
  }
  //to handle space
  const spaceHandle = (e) => {
    var e = window.event || e;
    var key = e.keyCode;
    //space pressed
    if (key === 32) {
      //space
      e.preventDefault();
    }
  };
  // setTimeout(() => {
  //   $(".msg").hide();
  // }, 5000)

  const handle = async (e) => {
    setSubmitted(true);
    e.preventDefault();
    //password
    if (add.re_password !== add.password) {
      return false;
    }
    //email error message
    if (errorMsg !== "") {
      dispatch(alertActions.error(message.register.error));
      setTimeout(() => dispatch(alertActions.clear()), 4000);
      return false;
    }
    //user_id error message
    if (errorMsg1 !== "") {
      dispatch(alertActions.error(message.register.error1));
      setTimeout(() => dispatch(alertActions.clear()), 4000);
      return false;
    }

    //checking all the fields are filled with some values and not empty
    if (
      add.name &&
      add.name_double &&
      mbl.mbl1 &&
      mbl.mbl2 &&
      mbl.mbl3 &&
      add.postal_code &&
      add.prefectures &&
      add.city &&
      add.country &&
      add.user_id &&
      add.email &&
      add.re_password &&
      numberValid &&
      postalcodeValid &&
      emailValid &&
      passwordValid &&
      postalcodelengthValid &&
      add.name.trim().length !== 0 &&
      add.name_double.trim().length !== 0 &&
      add.country.trim().length !== 0 &&
      add.city.trim().length !== 0
    ) {
      //api call for valid registration
      registrationService.check_user() &&
        registrationService
          .check_email()
          .then((data) => {
            if (data.status.code === 0) {
              //displays registration preview page and hides registration page
              setPreview(false);
              document.body.scrollTop = document.documentElement.scrollTop = 0;
            } else {
              data.data.map((val) => {
                setErrorMsg(val.msg);
              });
              data.data.map((val) => {
                setErrorMsg1(val.msg);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
  };

  let history = useHistory();
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setPasswordCheck(true);
    if (
      add.name &&
      add.name_double &&
      mbl.mbl1 &&
      mbl.mbl2 &&
      mbl.mbl3 &&
      add.postal_code &&
      add.prefectures &&
      add.city &&
      add.country &&
      add.user_id &&
      add.email &&
      add.re_password &&
      checked
    ) {
      //api call for registration
      registrationService.add(add).then((data) => {
        if (data.status.code === 0) {
          setAdd({
            name: "",
            email: "",
            name_double: "",
            password: "",
            // mobile_no:'',
            address: "",
            postal_code: "",
            prefectures: "北海道",
            city: "",
            country: "",
            mbl1: "",
            mbl2: "",
            mbl3: "",
            user_id: "",
            re_password: "",
            id: "",
          });
          //on successful registration redirect to RegistrationThankYou
          history.push("/RegistrationThankYou");

          setSubmitted(false);

          dispatch(alertActions.success(message.register.add));
          //error message disapear after 2 seconds
          setTimeout(() => dispatch(alertActions.clear()), 4000);
        }
      });
    }
  }
  //on click of back button on registration preview this method will be called
  const edit = () => {
    setPreview(true);
  };
  //returning all state and methods to registration.js page
  return {
    submitted,
    postalcodeValid,
    postalcodelengthValid,
    emailValid,
    numberValid,
    numberValid1,
    numberValid2,
    passwordCheck,
    passwordValid,
    preview,
    data,
    current,
    errorMsg,
    errorMsg1,
    checked,
    add,
    mbl,
    button_disable,
    changeHandler,
    edit,
    spaceHandle,
    handleSubmit,
    handle,
  };
}
export default useRegistrationHooks;
