
import { notificationConstants } from "../_constants/notfication.constants";

const initialState ={chatCount:0};

export function noficationCount(state = initialState, action) {
    switch (action.type) {
      case notificationConstants.NOTIFICATION_TOTAL :
        return {
          chatCount:action.payload
        };
      case notificationConstants.NOTIFICATION_CLEAR:
      
        return {
          chatCount:0
        };
      default:
        return state;
    }
  }