import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import { Strings, Colors, Images, Fonts } from "../../../res/strings";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";
import slider22 from "../../../assets/images/cost-min.png";
import kv from "../../../assets/images/kv3.png";
import group12118 from "../../../assets/images/Group12118.png";
import group12119 from "../../../assets/images/Group 12119.png";
import group12144 from "../../../assets/images/Group 12144.png";
import line from "../../../assets/images/line.png";
import se1 from "../../../assets/images/ses1.png";
import se2 from "../../../assets/images/ses2.png";
import se3 from "../../../assets/images/ses3.png";
import se4 from "../../../assets/images/ses4.png";
import se5 from "../../../assets/images/ses5.png";
import se6 from "../../../assets/images/ses6.png";
import se7 from "../../../assets/images/ses7.png";
import se8 from "../../../assets/images/ses8.png";
import se9 from "../../../assets/images/ses9.png";
import works from "../../../assets/images/works-img.png";
import profileImage from "../../../assets/images/profile-image.png";
import image78 from "../../../assets/images/Image 78.png";
import group1999 from "../../../assets/images/Group1999.png";
import lg1 from "../../../assets/images/lg1.png";
import lg4 from "../../../assets/images/lg4.png";
import lg2 from "../../../assets/images/lg2.png";
import "./Price.css";
function Service() {
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // heading display
  const renderGreenBarService = () => {
    return (
      <div className="service_bar flt">
        <img className="img_length" src={kv}></img>
        {/* <div className="service_content5">料金</div> */}
      </div>
    );
  };

  //different button display
  const renderGreenButtonsService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="box_container">
          {/* <div className="mark_logo1 flt">
            <img src={mark_logo} alt="image" />
          </div> */}
          <div className="mark_head1 flt">
            インドのエンジニア能力は
            <br />
            桁外れに優秀です
          </div>
          <div className="sub_title">
            今や大手ア○ゾン、グー○ルの上層部はすべて優秀なインド人エンジニアです
            <br />
            なぜか？IT教育のレベルが高く海外で活躍する人材が教育されています。
          </div>
        </div>

        {/* <div className="service_bar flt">
          <img className="img_length2" src={price_table}></img>
          <img className="img_length1" src={price_table1}></img>
        </div> */}

        {/* <img src={price} className="price-img" alt="image" />
        <div className="price-img">
          <div className="point_cont1">
            ※ ・　トップページを含む7P想定。 <br />
            ・アニメーション（フェードイン） <br />
            ・レスポンシブ対応。 <br />
            ・ワードプレス挿入し、お知らせ・ブログは更新時、トップページへ自動表示。
          </div>
        </div> */}
      </div>
    );
  };

  const renderGreen1BarService = () => {
    return (
      <div className="service_bar2 flt background-b">
        <div className="container special1">
          <p className="main_title white-color">
            わたし達だからできる
            <br />
            インド開拓
          </p>
          <img className="img_length4" src={profileImage}></img>
          <p className="main_sub_t white-color">
            インドは大半が英語を話せますがインド英語とよばれるように少し発音が難しいです。
            <br />
            しかし私たちAVIIのエンジニアは日本語が話せるエンジニアであり
            <br />
            代表とインドへ出向き開拓をおこなってきました。
          </p>
        </div>
        <div className="container special1">
          <p className="main_title white-color">
            ベトナム、台湾などの
            <br />
            アウトソーシングとはレベチ！
          </p>
          <img className="img_length4" src={image78}></img>
          <p className="main_sub_t white-color spacing">
            ＞お金を稼ぐためにとりあえずコーディングできるようになった
            <br />
            ではなく学生からしっかりみっちり勉強しているエンジニアばかりです
            <br />
            <br />
            ＞基礎知識と応用力があるので「出来ない」は基本的になく、
            <br />
            どのように行えば最善で制作できるのかを考えて実行します。
          </p>
        </div>
      </div>
    );
  };
  //the text for respective button
  const renderBlackContents3Service = () => {
    return (
      <div className="service_bar2 flt background-w">
        <div className="container">
          <div className="mark_head1 flt bg-img">
            安心してください！
            <br />
            日本人私たちがきっちり取り次ぎます
          </div>
          <div className="service_bar flt">
            <div className="sub_title">
              株式会社AVII IMAGEWORKSは日本の制作会社です。
              <br />
              私たちがIWEBシステム、アプリ開発を行う時のように橋渡しを行います
              <br />
              MTGはわたしたち株式会社AVII IMAGEWORKSです。
            </div>
            <img className="img_length6" src={group1999}></img>
          </div>
        </div>
      </div>
    );
  };

  //Price section
  const renderGreen2BarService = () => {
    return (
      <div className="service_bar2 flt background-b">
        <p className="main_title greenTitle">Price</p>
        <p className="main_sub_t greenTitle">料金</p>
        <img className="line" src={line}></img>
        <div className="mark_head1 flt greenTitle">
          月額25万〜
          <br />
          エンジニア派遣
        </div>
        <div className="sub_title white-color">
          200時間稼働
          <br />
          <br />
          ＜エンジニア派遣例＞
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <img src={slider22} alt="image" className="pervice-img" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  //Works sections
  const renderGreen3BarService = () => {
    return (
      <div className="service_bar2 flt background-b">
        <img className="line" src={works}></img>
        <p className="main_title white-color">Works</p>
        <p className="main_sub_t white-color">制作実績</p>
        <img className="line" src={line}></img>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se1} alt="image" className="pervice-img" />
              <p className="work-title">JOINT CREW</p>
            </div>
            <div className="col-sm-4">
              <img src={se2} alt="image" className="pervice-img" />
              <p className="work-title">日本児童教育専門学校</p>
            </div>
            <div className="col-sm-4">
              <img src={se3} alt="image" className="pervice-img" />
              <p className="work-title">SANPO神戸</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se4} alt="image" className="pervice-img1" />
              <p className="work-title">Original One Cup OZEKI</p>
            </div>
            <div className="col-sm-4">
              <img src={se5} alt="image" className="pervice-img1" />
              <p className="work-title">日本児童教育専門学校</p>
            </div>
            <div className="col-sm-4">
              <img src={se6} alt="image" className="pervice-img1" />
              <p className="work-title">株式会社脳レボ様</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se7} alt="image" className="pervice-img1" />
              <p className="work-title">株式会社コスメティクスラボ</p>
            </div>
            <div className="col-sm-4">
              <img src={se8} alt="image" className="pervice-img1" />
              <p className="work-title">カジャ団地</p>
            </div>
            <div className="col-sm-4">
              <img src={se9} alt="image" className="pervice-img1" />
              <p className="work-title">TKクリエイト株式会社様</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //Road map sections
  const renderGreen4BarService = () => {
    return (
      <div className="service_bar2 flt background-w imagebg2">
        <div className="container">
          <div className="mark_head1 flt">
            チャットより
            <br />
            どんなことでも即対応
          </div>
          <div className="sub_title">
            エンジニアへの急な仕様変更などわたしたちへチャット連絡いただければ
            <br />
            即座に対応いたします
          </div>
        </div>
      </div>
    );
  };

  //Road map2 sections
  const renderGreen5BarService = () => {
    return (
      <div className="service_bar2 flt background-y">
        <div className="container">
          <p className="main_title margin">Rode map</p>
          <p className="main_sub_t">全てチャットでスピーディーでご連絡</p>
          <img className="line" src={line}></img>
          <img className="img_length6" src={group12118}></img>
        </div>
      </div>
    );
  };
  //Road map3 sections
  const renderGreentwoBarService = () => {
    return (
      <div className="service_bar flt background-l">
        <div className="container">
          <div className="service_bar1 flt">
            <img className="img_length6" src={group12119}></img>
          </div>
        </div>
      </div>
    );
  };
  //Road map4 sections
  const renderGreen7BarService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="container">
          <div className="point_title flt">
            <div className="service_bar1 flt">
              <img className="img_length6" src={group12144}></img>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //Road contact sections
  const renderContactService = () => {
    return (
      <div className="service_bar flt contact">
        <div className="container">
          <div className="service_bar1 flt">
            <div className="container special2">
              <div className="row">
                <div className="col-sm-4">
                  <p className="con-title">Contact</p>
                </div>
                <div className="col-sm-7">
                  <p className="con-sub">
                    マイページよりチャットで簡単やりとり可能
                    <br />
                    <span>
                      <a
                        href="https://recursive-design.com/contact"
                        target="_blank"
                      >
                        お問い合わせはこちらから
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              <div className="reservation-btn">
                <Link to="/login">
                  <img src={lg1} alt="" />
                </Link>
              </div>
              <div className="reservation-btn1">
                <Link to="/design">
                  <img src={lg4} alt="" />
                </Link>
              </div>
              <div className="reservation-btn2">
                <Link to="/web-production">
                  <img src={lg2} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAviiService = () => {
    return (
      <div className="service_bar flt avii">
        <div className="container special2 center">
          <h2>AVII IMAGEWORKS</h2>
          <p>
            数多くのお客様と企画から制作まで行ってきた実績と経験値を生かし
            <br />
            私たちがサポートします！
          </p>
          <a href="https://avii.jp/" target="_blank">
            <button class="button button1">運営会社サイト</button>
          </a>
        </div>
      </div>
    );
  };
  //button display
  const renderMixGreenButtonPDFService = () => {
    return (
      <div className="algn_centr">
        <button
          className="serv_btn"
          onClick={() => history.push("/Registration")}
        >
          <div className="fltlft">
            会員登録なしでPDFで
            <br />
            無料見積もりしてみる
          </div>
          <div className="fltrgttt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        {/* Actual Home page starts */}
        <Header />

        {/*Start of  FAQ section */}
        <div className="flt">
          {renderGreenBarService()}
          <div className="container">
            <div className="serv_container">
              {renderGreenButtonsService()}
              {/* {renderTextContentsServices()} */}
              {/* {renderMixGreenButtonPDFService()} */}
            </div>
          </div>

          {renderGreen1BarService()}

          {/* <div className="container">
            <div className="serv_container">
              {renderTextContentsService()}
            </div>
          </div> */}
          {renderBlackContents3Service()}
          {renderGreen2BarService()}
          {renderGreen3BarService()}
          {renderGreen4BarService()}
          {renderGreen5BarService()}
          {renderGreentwoBarService()}
          {renderGreen7BarService()}
          {renderContactService()}
          {renderAviiService()}
        </div>
        {/*End of  FAQ section */}

        <Footer />
      </div>
      {/* Actual Home page Ends */}
    </div>
  );
}

export default Service;
