import $ from "jquery";
import ArticledetailsServices from "../../_services/articledetails.services";
// import "../../../assets/jquery.docout";
// import ColumnServices from "../../../_services/column.services";
// import { useHistory } from "react-router-dom";
import "../../assets/jquery/jquery.min.js";
import "../../assets/jquery/jquery.docout.js";
import { useState, useEffect } from "react";
import "./jquery.css";
import "../../assets/css/style.css";



function Jquerycomp(props) {
  const [blogdata, setBlogdata] = useState([]);
  const [resultt, setResultt] = useState();


  useEffect(() => {
    ArticledetailsServices.getBlogData(props.id).then((data) => {
      setBlogdata(data);
      let body = data.data && data.data.description;
      console.log("database_retrieved inside useeffect" + body);
      setResultt(body);
    });

    $(document).ready(function () {
      setTimeout(function () {
        $("form#formid").docout();
      }, 1000);
    });
  }, []);

  console.log("databasebody_outside useEffect" + resultt);
  return (
    <div className="image_display flt">
      <form id="formid">
        <hr />

        {resultt && (
          <div
            id="document-outline" className="whitebg flt"
            dangerouslySetInnerHTML={{ __html: resultt }}
          ></div>
        )}
    

      </form>

    </div>
  );
}

export default Jquerycomp;






// function Jquery() {

//   useEffect(() => {
//     console.log("inside useeffect");
//   }, []);



//   return (
//     <div>
//       <form>
//         <h1>hi hello1</h1> <p>my para2</p>
//         <h1><b>Pastor Karl's Bedtime Stories PODCAST</b></h1><h1><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h2"></span></div></div><div class="sp-block sp-text-block "><h3>Introducing&#160;<b>Pastor Karl&#8217;s Bedtime Stories</b>&#160;- A&#160;<u>NEW</u>&#160;podcast for kids from Pastor Karl Bastian!</h3></div></h1><h1>NOW WITH GUS CO-HOSTING!</h1><h1><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h1"></span></div></div><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h3"></span></div></div></h1>Unscripted and fun! End your day talking about God and the Bib
//         <h1><b>Pastor Karl's Bedtime Stories PODCAST</b></h1><h1><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h2"></span></div></div><div class="sp-block sp-text-block "><h3>Introducing&#160;<b>Pastor Karl&#8217;s Bedtime Stories</b>&#160;- A&#160;<u>NEW</u>&#160;podcast for kids from Pastor Karl Bastian!</h3></div></h1><h1>NOW WITH GUS CO-HOSTING!</h1><h1><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h1"></span></div></div><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h3"></span></div></div></h1>Unscripted and fun! End your day talking about God and the Bib
//         <h1><b>Pastor Karl's Bedtime Stories PODCAST</b></h1><h1><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h2"></span></div></div><div class="sp-block sp-text-block "><h3>Introducing&#160;<b>Pastor Karl&#8217;s Bedtime Stories</b>&#160;- A&#160;<u>NEW</u>&#160;podcast for kids from Pastor Karl Bastian!</h3></div></h1><h1>NOW WITH GUS CO-HOSTING!</h1><h1><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h1"></span></div></div><div class="sp-block sp-heading-block "><div class="sp-block-content"><span class="h3"></span></div></div></h1>Unscripted and fun! End your day talking about God and the Bib
//         <h3>Megha</h3> <span>Hello</span> <p>Hi this is my para</p>  <p>this is another para</p> <p>this is another para</p>
//       </form>

//     </div>
//   );
// }

// {
//   $(document).ready(function () {
//     $("form").docout();
//   });
// }
