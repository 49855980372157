import "./App.css";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import Home from "./component/pages/ShirHomePage/HomePage";
import QA from "./component/pages/ShirQA/QA";
import OperatingSystem from "./component/pages/ShirOperating System/Operatingsystem";
import Price from "./component/pages/ShirPrice/Price";
import Prices from "./component/pages/ShirPrices/Price";
import Designs from "./component/pages/ShirDesign/Price";
import IT from "./component/pages/ShirIT/Price";
import Service from "./component/pages/ShirService/Service";
import FirstUser from "./component/pages/ShirFirstUser/FirstUser";
import HowToUse from "./component/pages/ShirHowToUse/HowToUse";
import Thankyou from "./component/pages/ShirThankyou/Thankyou";
import Login from "./component/pages/ShirLogin/Login";
// import RegisterConfirm from "./component/pages/RgisterConfirm/RegisterConfirm";
import Registration from "./component/pages/ShirRegistrationPage/Registration";
import MemberRegistration from "./component/pages/ShirMemberRegistration/MemberRegistration";
import Privacy from "./component/pages/ShirPrivacyPolicy/Privacy";
import Terms from "./component/pages/Terms/Terms";
// import { useDispatch, useSelector } from "react-redux";
import { history } from "./_helpers";
// import { alertActions } from "./_actions";
import { useSelector } from "react-redux";
import Dashboard from "./component/pages/Dashboard/Dashboard";
import { PrivateRoute } from "./_helpers";
import ForgotPassword from "./component/pages/ShirForgot password/ForgotPassword";
import ArticleDetailPage from "./component/pages/ShirArticleDetailPage/ArticleDetailPage";
import Column from "./component/pages/ShirColumn/Column";
import Inquiry from "./component/pages/ShirInquiry/Inquiry";
import Contact from "./component/pages/ShirContactPage/Contact";
import MainUI from "./component/pages/chatbot/client/mainUI";
import settings from "./component/pages/settings/settings";
import ContactThankYou from "./component/pages/ShirContactThankYou/ContactThankYou";
import CommentPointer from "./component/pages/ShirImageTag/CommentPointer";
import RegistrationThankYou from "./component/pages/ShirRegistrationThankYou/RegistrationThankYou";
// import Chat from "./component/pages/chat/Chat";

//Appgh
function App() {
  const alert = useSelector((state) => state.alert);

  require("dotenv").config();
  // var randmid = localStorage.getItem("random3");
  return (
    <div>
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}

      {/* <div className="App"> */}
      {/* <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} />
          <Route path="/QnA" component={QA} />
        </Switch>
      </BrowserRouter> */}
      <Router history={history}>
        <Switch>
          <Route path="/os" component={OperatingSystem} />
          <Route path="/qna" component={QA} />
          <Route path="/price" component={Price} />
          <Route path="/web-production" component={Prices} />
          <Route path="/it-service" component={IT} />
          <Route path="/design" component={Designs} />
          <Route path="/service" component={Service} />
          <Route path="/firstuser" component={FirstUser} />
          <Route path="/howtouse" component={HowToUse} />
          <Route
            path="/RegistrationThankYou"
            component={RegistrationThankYou}
          />
          <Route path="/thankyou" component={Thankyou} />
          <Route path="/member-registration" component={MemberRegistration} />
          <Route path="/login" component={Login} />
          {/* <Route path="/registration-confirm" component={RegisterConfirm} /> */}
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={Terms} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          {/* <Route path={"/forgot-password?id="+ randmid} component={ ForgotPassword}/> */}
          <Route path="/forgot-password" component={ForgotPassword} />
          {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
          <Route path="/column" component={Column} />
          <Route path="/article-details/" component={ArticleDetailPage} />
          <Route path="/inquiry" component={Inquiry} />
          <Route path="/registration" component={Registration} />
          <Route path="/contact" component={Contact} />
          <PrivateRoute path="/user-panel" component={settings} />
          {/* <Route path="/settings" component={settings} /> */}
          <Route path="/ContactThankYou" component={ContactThankYou} />
          <PrivateRoute path="/cp" component={CommentPointer} />
          {/* <Route path="/jq" component={jquery} /> */}
          {/* <Route path="/chat" component={Chat} /> */}
          {/* <Route path="/chat" component={Chat} /> */}
          <PrivateRoute path="/chat" component={MainUI} />

          {/* <Route path="/footer" component={Footer} /> */}
          <Route path="/" component={Home} />
          <Redirect from="*" to="/" />

          {/* <Route path="/Home">
            <Home />
          </Route> */}
          {/* <Route path="/OS">
            <OperatingSystem />
          </Route> */}
          {/* <Route path="/QnA">
            <QA />
          </Route> */}
          {/* <Route path="/service">
            <Service />
          </Route>
          <Route path="/firstuser">
            <FirstUser />
          </Route> */}

          {/* <Route path="/howtouse">
            <HowToUse />
          </Route> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
