import { useState, useEffect } from "react";
import ArticledetailsServices from "../../../_services/articledetails.services";

function useArticleDetailsHooks() {
    const imag = process.env.REACT_APP_MEDIA_DOMAIN; // profile image url
    //display most viewed data
    const [mostvieweddata, setMostvieweddata] = useState([]);
    //display most featured data
    const [featureddata, setFeatureddata] = useState([]);
    //display advertisement banners
    const [advertisementdata, setAdvertisementdata] = useState([]);
    const current = 1;
    const [blogdata, setBlogdata] = useState([]);
    const resultt = '';
    let result = null;
    //to get url
    let idurl = window.location.pathname;
    let idsep = idurl.split("/");
    let id = idsep[2];

    useEffect(() => {
        window.scrollTo(0, 0);
        //API CALL FOR DIFFERENT DATA
        ArticledetailsServices.getBlogData(id).then((data) => {
            setBlogdata(data);
        });
        //api call for most viewed data
        ArticledetailsServices.getMostViewdData(current).then((data) => {
            setMostvieweddata(data);
        });
        //api call for most featured data
        ArticledetailsServices.getFeaturedData(current).then((data) => {
            setFeatureddata(data);
        });
        //api call for banner data
        ArticledetailsServices.getAdvertisementData(current).then((data) => {
            setAdvertisementdata(data);
        });
    }, []);


    return {
        imag,
        mostvieweddata,
        featureddata,
        advertisementdata,
        current,
        blogdata,
        resultt,
        result,
        idurl,
        idsep,
        id
    }

}
export default useArticleDetailsHooks;