import { Component } from 'react';
import ReactCursorPosition from 'react-cursor-position';
import Measure from 'react-measure';
import Point from './point';
import { setPoint, getXYData } from '../../../utility';
import styles from '../../../styles';
import './image.css';
import { commentDiv1 } from '../../../styles';

const style = styles.ImagePreview;


let x = ''
let y = ''
export default class extends Component {

  setComment = event => {
    event.stopPropagation();
    const { dimensions, setNewPoint } = this.props;
    const newPoint = setPoint(dimensions, this.currentPosition);
    const xyData = getXYData(this.currentPosition);
    x = xyData.x;
    y = xyData.y;
    if (newPoint) {
      setNewPoint(newPoint);
    }
  };

  render() {
    const {
      dimensions,
      selectedImage,
      points,
      selectedPoint,
      showPoints,
      newPoint,
      setDimensions,
      setSelectedPoint,
    } = this.props;
    const baseUrl = 'https://d32fg19io9z7j1.cloudfront.net/production';
    var final_img_path = selectedImage.file.includes("data:") ? selectedImage.file : baseUrl + selectedImage.file;
    return (

      <div style={style.main}>
        <Measure bounds onResize={measure => setDimensions(measure.bounds)}>
          {({ measureRef }) => (
            <div className="qqq">
              <ReactCursorPosition
                onPositionChanged={currentPosition =>
                  (this.currentPosition = currentPosition)}
              >

                <div onClick={this.setComment}>
                  {/* <div className="image_display"> */}
                  <div style={commentDiv1} ref={measureRef}>

                    <img
                      style={style.mainImage}
                      alt="#"


                      src={final_img_path}
                    />

                  </div>

                  <div style={style.commentDiv} className="comment_box">
                    {points && points.map(point => (
                      <Point
                        id={point}
                        key={point}
                        x={x}
                        y={y}
                        dimensions={dimensions}
                        showPoints={showPoints}
                        selectedPoint={selectedPoint}
                        setSelectedPoint={setSelectedPoint}
                      />
                    ))}
                    {newPoint && newPoint ? (
                      <Point
                        id={newPoint}
                        key={newPoint}
                        x={x}
                        y={y}
                        dimensions={dimensions}
                        showPoints={showPoints}
                        newPoint
                        selectedPoint={selectedPoint}
                        setSelectedPoint={setSelectedPoint}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {/* </div> */}
              </ReactCursorPosition>
            </div>
          )}
        </Measure>
      </div>
    );
  }
}
