import React, { useEffect } from "react";
import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import { Link, useHistory } from "react-router-dom";
import img from "../../../assets/images/how1.png"
import img1 from "../../../assets/images/arr.png"
// import img2 from "../../../assets/images/3circle_arrow.png"

const HowToUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();
  //heading
  const renderGreenBarHowToUse = () => {
    return (<div className="service_bar flt">

      <img className="img_length" src={img}></img>
      <div className="service_content4">
        ご利用方法
      </div>
    </div>);
  };
  //buttons display
  const renderButtonsHowToUse = () => {
    return (
      <div className="howtouse_buttons_container">
        <div className="row">
          <div className="col-sm-4">
            <a href="#1">
              <button className="howtouse_three_btn ">
                <div className="fltlft">無料お見積もり</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>{" "}
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#2">
              <button className="howtouse_three_btn ">
                <div className="fltlft">マイページ出来ること</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#3">
              <button className="howtouse_three_btn ">
                <div className="fltlft">発注</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>
            </a>
          </div>
        </div>
        <br />
        <div className="row top_space">
          <div className="col-sm-4 ">
            <a href="#4">
              <button className="howtouse_three_btn ">
                <div className="fltlft">対応について</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>{" "}
            </a>
          </div>
        </div>
      </div>
    );
  };
  //actual content design
  const renderTextContentsHowToUse = () => {
    return (
      <div className="howtouse_para_cont">
        {/* estimate */}
        <div>
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="howtouse_texthead"> 見積もり </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="howtouse_para" id="1">
          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;会員登録の仕方&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              会員登録は、ログインページの”初めてご利用になる方”より、会員登録を行ってください。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;マイページのご利用方法&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              マイページより、ご利用履歴の照会、請求書のダウンロード、お客様情報の編集、ご担当者様情報の追加、削除などが可能です。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;データの入稿方法&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              印コーディングサービスでは、お客様ごとのID、パスワードで保護されたファイルサーバーのアカウントを無料でご提供しており<br />ます。Windows、Macともに各種接続方法に対応しており、大容量のデータもスムーズにご入稿いただけます。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div"><span className="bg_clr">&nbsp;&nbsp;入稿から納品まで&nbsp;&nbsp;</span><br />
              <span className="bg_clr2"> ご発注から納品までの流れは、下記の流れになります。</span></div>
            <div className="main_subcontent_div">

              <br />
              STEP1：ご入稿 <br />
              SETP2：お見積もり依頼 <br />
              STEP3：ご発注 <br />
              STEP4：納品
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;入稿&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              デザインデータを下記のデータ種類でアップロードしてくください。
              <br />
              ・ai
              <br />
              ・figuma
              <br />
              ・psd
              <br />
              ・xd
              <br />
              <br />
              データはリンク切れがあると再入稿のご連絡を取らせていただきます。
              <br />
              入稿前に必ずご確認のご準備をお願いいたします。
              <br />
              入稿後はお見積もりをさせていただきます。 <br />
              アニメーション希望等はデザインデータに補足説明をいれていただければ対応可能です。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div"><span className="bg_clr">&nbsp;&nbsp;お見積もり&nbsp;&nbsp;</span><br /><span span className="bg_clr2">入稿いただいたデータをもとにコーディング費用を算出いたします。 </span></div>
            <div className="main_subcontent_div">
              その際、ご不明点等があればメールもしくはお電話で問い合わせを行う場合があります。
              <br />
              <br />
              お見積については入稿後、早ければ当日〜3日営業日以内でご返答させていただきます。
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
        {/* opened page */}
        <div>
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="howtouse_texthead"> マイページで出来ること</div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="howtouse_para" id="2">
          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;ご請求履歴&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              ご請求に履歴に関してご説明いたします。
              <br />
              まず請求の流れですが、お見積もり語、お打ち合わせさせていただき問題なければ請求書を発行させていただきます。
              <br />
              請求書発行後はご入金いただいてからの制作進行となります。
              <br />
              万が一、納品が行われなかった場合の返金対応をさせていただきますのでご安心ください。
              <br />
              請求書が発行された時点で「マイページの請求履歴」に表記されます。
              <br />
              入金まちの場合はステータスが「入金待ち」
              <br />
              入金完了後は「入金確認済み、制作進行中」と表記されます。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div"><span className="bg_clr">&nbsp;&nbsp;ご利用履歴&nbsp;&nbsp;</span><br />
              <span className="bg_clr2">利用履歴はお客様が「見積もり依頼」「請求書発行」「入金待ち」「制作進行中」のステータスにわけてすべて表記される</span></div>
            <div className="main_subcontent_div">
              ようになっております。
              <br />
              途中の進行のものは1年のご連絡がない場合は返金対応は不可となりプロジェクトを中止させていただきます。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;領収書の発行&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              請求書発行の流れに添い請求が発行されます。<br />

              弊社で入金が確認され、制作完了、納品となった時点で領収書は発行が可能となります。
              <br />
              記載ご氏名は、会員入力時の内容となりますのでご了承ください。
              <br />
              名前の変更等がご希望の場合は事前にご相談いただければ対応可能でございます。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;お客様情報の変更&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              お客様の情報を更新する場合はマイページより更新いただくことが可能です。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;パスワードの変更&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              マイページ設定からパスワードの変更、再設定が可能です。
              <br />
              大文字、小文字の組み合わせであり8文字以内のパスワードの設定をお願いしております。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;マイページの便利な使い方&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              マイページは制作依頼がある際は常にどのような状態かをご報告いたします。 進行状況を確認いただけるのでご安心し
              <br />
              て依頼いただけるようにしています。

              ブックマークをおこなっていただければ容易に確認いただけます。
              <br />
              <br />
              またスマホからも確認がいただけます。
              <br />
              マイページを有効活用いただき進捗、制作依頼をスムーズにおこなってください。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr" >&nbsp;&nbsp;チャットツールの使い方&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              お問合せに関しましては、チャットツールを用意しております。印コーディングサービスご利用時にご不明な点がありましたら、
              <br />
              チャットツールからお気軽にお問い合わせください。<br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
        {/* order */}
        <div>
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="howtouse_texthead"> 発注 </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="howtouse_para" id="3">
          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;ご発注&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              お見積もり費用を確認していただきご発注ご判断くださいませ。
              <br />
              発注後入金処理いただいた後は制作進行いたしますのでキャンセル等行うことができません。ご理解、ご不明点等なくして
              <br />
              いただきご発注ください。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;ご入金&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              お支払い方法は銀行振り込みとなります。
              <br />
              入金確認後制作が進行いたします。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;お支払い方法&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              銀行振り込み後制作進行いたします
              <br />
              （今後クレジットカード等のサービスも行える様検討しております）
              <br />
              三菱UFJ銀行　三宮支店
              <br />
              普通預金
              <br />
              口座番号　3506535
              <br />
              カ）アビイメージワークス
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;納品&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              納品に関してはコーディングデータとなります。
              <br />
              お客様ご契約のサーバーにアップロードいただければ問題なくサイトは表示されるように納品させていただきます。
              <br />
              納品後のお客様対応方法に関してはこちらの動画をご覧いただきご対応ください。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;ご請求・お支払い&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              お支払方法は、ご請求書による後払い銀行振込となります。 <br />
              ・ 銀行振り込み <br />
              お客様ご指定のご請求締日に、ご請求書を郵送致します。
              <br />
              ご請求書が到着後、所定のお支払日までにお振込頂きますようお願いいたします。


              <br />
              ※ご請求書について <br />
              通常、ご請求締め後、3日以内に到着致します。お急ぎの場合は、マイページについてより、PDFをダウンロード可能です。{" "}
              <br />

              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
        {/* regasmember */}
        <div>
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="howtouse_texthead"> 対応について</div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="howtouse_para" id="4">
          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;対応時間&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              対応時間は日本時間で朝の9時〜18時が対応時間となります。
              <br />
              インドとは時差3.5時間あることから日本時間では夜の作業となります。日本担当者とインドとの連携は15時から対応して
              <br />
              おります。
            </div>
          </div>

          <div className="main_content_div">
            <div className="main_heading_div bg_clr">&nbsp;&nbsp;対応日にち&nbsp;&nbsp;</div>
            <div className="main_subcontent_div">
              対応可能日程は月曜日から金曜日までとなります。
              <br />
              インドのコーディングは作業量確保のため月曜日から土曜日まで稼働しております。
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //end button display
  const renderRegisterButtonHowToUse = () => {
    return (
      <div className="algn_centr">
        <button
          className="howtouse_btn"
          onClick={() => history.push("/Registration")}
        >
          <div className="fltlft">
            会員登録なしでPDFで
            <br />
            無料見積もりしてみる
          </div>
          <div className="fltrgttt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        <Header />
        {renderGreenBarHowToUse()}
        <div className="flt">
          <div className="container">
            <div className="howtouse_container">

              {renderButtonsHowToUse()}
              {renderTextContentsHowToUse()}
              {/* {renderRegisterButtonHowToUse()} */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HowToUse;
