import React, { useState, useEffect } from "react";
import Gallary from "../../../containers/gallary";
import SelectedImage from "../../../containers/selectedImage";
import { commentImage } from "../../../_services/commentImage.services";
import Fork from "./fork";
import { connect } from "react-redux";
import { alertActions } from "../../../_actions/alert.actions";
import message from "../../_common/Message/message";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import io from "socket.io-client";
import MessageServices from "../../../_services/message.services";
import jQuery from "jquery";
import $ from "jquery";
import AllComments from "./allComments";

function CommentPointer(props) {
  //const REACT_APP_BASE_URL = "http://35.154.88.184:5003/cp/";
  // const REACT_APP_BASE_URL = "http://localhost:3000/cp/";
  const REACT_APP_BASE_URL = "https://recursive-design.com/cp/";
  const [isButtonHide, setButtonHide] = useState(true);
  const [galData, setGalData] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  const [errorMsg1, setErrorMsg1] = useState();
  const [current, setCurrent] = useState();
  const dispatch = useDispatch();
  const [getroleid, setGetroleid] = useState([]);
  const [getmessagee, setGetmessagee] = useState([]);
  const [getchatt, setGetchatt] = useState(null);
  const [chatidd, setChatidd] = useState("");
  const [filetype, setFiletype] = useState([]);
  const [numbers, setNumbers] = useState();
  const [sendlink, setSendlink] = useState();
  let adm_id = "5c1b2f3537c29512c4294680";
  var underscoreID1 = localStorage.getItem("UsersId");
  var underscoreID = JSON.parse(underscoreID1);
  var author = localStorage.getItem("UserInfo");
  const [imagee, setImagee] = useState({ preview: "", raw: "" });
  const [fileUploadAPIinput, setFileUploadAPIinput] = useState();
  const [currentMessage, setCurrentMessage] = useState("");
  let history = useHistory();
  let new_name;

  setTimeout(() => {
    jQuery.each(jQuery("textarea[data-autoresize]"), function () {
      var offset = this.offsetHeight - this.clientHeight;
      var resizeTextarea = function (el) {
        jQuery(el)
          .css("height", "auto")
          .css("height", el.scrollHeight + offset);
      };
      jQuery(this)
        .on("keyup input", function () {
          resizeTextarea(this);
        })
        .removeAttr("data-autoresize");
    });
  }, 1000);

  function resizeTextArea() {
    setTimeout(() => {
      $("textarea").val("");
      $("textarea").css({ height: "44px" });
    }, 100);
  }

  useEffect(() => {
    if (window.location.href.includes("cp/")) {
      setButtonHide(false);
      if (props && props.location && props.location.pathname) {
        var linkId = (props && props.location && props.location.pathname).split(
          "/"
        )[2];

        commentImage
          .getImageId(linkId)

          .then((data) => {
            console.log(data.data.comment);
            setGalData(data.data.comment);
          });
      }
    } else {
      setButtonHide(true);
    }
  }, []);

  var underscoreID = localStorage.getItem("shironuki_UsersId");
  var underscoreID1 = JSON.parse(underscoreID);
  useEffect(() => {
    MessageServices.getchat(adm_id).then((data) => {
      setGetchatt(data);
    });
  }, []);

  new_name = localStorage.getItem("gggggg");

  useEffect(() => {
    if (getchatt !== null) {
      let page = "";
      let search = "";
      let chat_id =
        getchatt.data && getchatt.data.length > 0 && getchatt.data[0].chat_id;

      setChatidd(chat_id);

      MessageServices.get_message(chat_id, page, search).then((data) => {
        setGetmessagee(
          data &&
            data.data &&
            data.data.message &&
            data.data.message.docs.reverse()
        );

        setGetroleid(
          data &&
            data.data &&
            data.data.message &&
            data.data.message.docs &&
            data.data.message.docs._id
        );

        setFiletype(
          data && data.data && data.data.message && data.data.message.file_type
        );
      });
    }
  }, [getchatt]);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date_ob1 = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });

  var date_ob = new Date(date_ob1);
  let date = ("0" + date_ob.getDate()).slice(-2);
  let month = monthNames[date_ob.getMonth()];
  let year = date_ob.getFullYear();
  let hours = date_ob.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  var time = date + "-" + month + "-" + year + " " + hours;

  const sendMsgToServer = (sendlink) => {
    var underscoreID = JSON.parse(localStorage.getItem("shironuki_UsersId"));
    underscoreID1 = underscoreID;
    var message = {
      message: sendlink,
      userId: underscoreID1,
      file_path: "",
      file_name: "",
      type: "message",
      file_type: "",
      category: 1,
      chat_id: chatidd,
      time: time,
      mId: underscoreID1 + "_" + Date.now(),
    };

    SocketEmit(message);
    resizeTextArea();
    setCurrentMessage("");
  };

  async function SocketEmit(message1) {
    var socket = io("https://chat.recursive-design.com/chat-" + chatidd, {
      query: "userId=" + underscoreID1,
    });

    setImagee({ preview: null });

    if (socket !== null) {
      try {
        console.log(socket);
        var soc = socket.emit("new message", message1);

        dispatch(alertActions.success(message.comment.imgComment4));
      } catch (e) {
        console.log(e);
      }
      history.push("/chat");
    }
    setFileUploadAPIinput("");
    setNumbers(1);
    console.log(message);

    setTimeout(() => dispatch(alertActions.clear()), 4000);
  }

  if (props.message) {
    setTimeout(() => dispatch(alertActions.clear()), 4000);
  }

  function submitdata() {
    {
      const ptData = [];
      props.images &&
        props.images.map((images, i) => {
          console.log(images.name);
          if (images.name === "") ptData.push(i);
        });
      // new_name = localStorage.getItem("gggggg");

      console.log(new_name);

      const imageData = [];
      var pointData = [];

      props.images &&
        props.images.length > 0 &&
        props.images.map((image) => {
          pointData = [];
          props.comments &&
            props.comments[image.id] &&
            props.comments[image.id].map((commentD) => {
              pointData.push({
                x: commentD.x,
                y: commentD.y,
                comment: commentD.comment,
                time: commentD.time,
              });
            }); //inner
          imageData.push({
            ...image,
            name: image.name,
            pointData,
          });
        }); //img
      var check = true;
      imageData.map((val) => {
        if (val.pointData.length === 0) {
          check = false;

          dispatch(alertActions.error(message.comment.imgComment));
          setTimeout(() => dispatch(alertActions.clear()), 2000);
        }
      });
      if (check) {
        confirmAlert({
          title: "送信確認を行う",
          message: "詳細情報を送信してよろしいですか？",
          buttons: [
            {
              label: "はい",
              onClick: () => {
                commentImage
                  .add(imageData)

                  .then((data) => {
                    setCurrent(data.data.comment);
                    if (data.status.code === 0) {
                      setErrorMsg(data.status.message);
                    } else {
                      setErrorMsg1(data.status.message);
                    }

                    dispatch(alertActions.success(message.img.add));
                    setTimeout(() => dispatch(alertActions.clear()), 2000);
                    var links = [];
                    setSendlink(data.data.comment[0].link);
                    confirmAlert({
                      title: (
                        <div className="copy_link">
                          以下のリンクをコピーしてください
                        </div>
                      ),
                      message: (
                        <a
                          className="img_link"
                          href={REACT_APP_BASE_URL + data.data.comment[0].link}
                        >
                          {REACT_APP_BASE_URL}
                          {data.data.comment[0].link}
                        </a>
                      ),
                      closeOnEscape: false,
                      closeOnClickOutside: false,
                      buttons: [
                        {
                          label: "退出",
                          onClick: () => null,
                        },
                        {
                          label: "チャットでリンクを送る",
                          onClick: () => {
                            sendMsgToServer(
                              REACT_APP_BASE_URL + data.data.comment[0].link
                            );
                          },
                        },
                      ],
                    });
                  });
              },
            },

            {
              label: "いいえ",
              onClick: () => null,
            },
          ],
        });
      }
    }
  }
  return (
    <div className="img_tag_div">
      <div className="gallary">
        <Gallary data={galData} />
        {props.images.length > 0 && (
          <button
            className={
              isButtonHide ? "simpleSubmitButton" : "simpleSubmitButton1"
            }
            onClick={submitdata}
          >
            投稿
          </button>
        )}
        <SelectedImage />
      </div>

      <div className="comment">
        <AllComments />
      </div>
      <Fork />
      {isButtonHide &&
        props.message &&
        new_name !== undefined &&
        props.message != "" && (
          <div className="alert alert-danger" role="alert">
            {props.message}
          </div>
        )}
    </div>
  );
}
function mapStateToProps(state) {
  const images = state.pointerReducer.images;
  const comments = state.pointerReducer.comments;
  const message = state.pointerReducer.message;
  const message2 = state.pointerReducer.message;
  const dimensions = state.pointerReducer.dimensions;
  return { images, comments, message, message2, dimensions };
}
export default connect(mapStateToProps, {})(CommentPointer);
