import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputSearch from './inputSearch';
import pointerActions from '../../../_actions/pointer.actions';
import { timeDifference } from '../../../utility';
import styles from '../../../styles';
import './image.css';

const { setComments } = pointerActions;
const style = styles.PointComments;
const removePoint = (points, pointId) => {
  const newPoints = [];
  points.forEach(point => {
    if (point !== pointId) {
      newPoints.push(point);
    }
  });
  return newPoints;
};
const removeComment = (comments, deletedComment) => {
  const newComments = [];
  comments.forEach(singleComment => {
    if (deletedComment.id !== singleComment.id) {
      newComments.push(singleComment);
    }
  });
  return newComments;
};
class PointComments extends Component {
  render() {
    const {
      selectedImage,
      points,
      newPoint,
      selectedPoint,
      pointId,
      comments,
      thisPointComments,
      setComments, x, y
    } = this.props;
    if (selectedPoint !== pointId) {
      return <div />;
    }

    const commentComponent = comment => (
      <div style={style.commentComponent} key={comment.id}>
        <div className="commentBody">
          {/* <span style={style.commentUser}>{comment.user}</span> */}
          <span className="spanTime1">{timeDifference(comment.time)}</span>
          {window.location.href.includes("cp/") ? "" :
            <span
              // style={style.commentDelete}
              className="commentDelete"
              onClick={event => {
                event.stopPropagation();
                const newPoints =
                  thisPointComments.length === 1
                    ? removePoint(points, pointId)
                    : points;
                const newComments = removeComment(comments, comment);
                setComments(selectedImage, newPoints, newComments, pointId);
              }}
            >
              <i class="fas fa-trash-alt delete_comment_icon"></i>
            </span>
          }
        </div>
        <span style={style.commentSpan} className="commentSpan1">{comment.comment}</span>
      </div>
    );
    return (
      <div
        onClick={event => {
          event.stopPropagation();
        }}
        style={style.main}
      >
        <div style={style.header}>
          <span className="headerSpan">
            {newPoint ? 'コメント挿入' : 'コメント'}
          </span>
        </div>
        <div style={style.commentsWrapper}>
          {thisPointComments.map(commentComponent)}
        </div>

        {newPoint ?

          <InputSearch
            autoFocus
            clearOnSearch
            placeholder="ここにコメントを入力"
            style={style.input}
            onSearch={comment => {
              if (comment) {
                const newComment = {
                  comment,
                  id: new Date().getTime(),
                  time: new Date().getTime(),
                  user: 'User',
                  pointId, x, y
                };

                const newComments = !this.props.comments || this.props.comments.length === 0 ? [newComment] : [newComment, ...this.props.comments];
                const newPoints = !this.props.points || this.props.points.length === 0 ? [pointId] : [pointId, ...this.props.points];
                setComments(selectedImage, newPoints, newComments);

              }
            }}
          />


          : ''}


      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selectedImage, points, comments, selectedPoint } = state.pointerReducer;
  const { id } = selectedImage;
  const thisPointComments = [];
  comments[id] && comments[id].forEach(comment => {
    if (comment.pointId === selectedPoint) {
      thisPointComments.push(comment);
    }
  });
  return {
    selectedImage,
    points: points[id],
    comments: comments[id],
    thisPointComments,
    selectedPoint
  };
}
export default connect(mapStateToProps, { setComments })(PointComments);