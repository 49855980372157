import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import { Strings, Colors, Images, Fonts } from "../../../res/strings";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";

import slider22 from "../../../assets/images/Price_1.png";
import slider23 from "../../../assets/images/Price_2.png";
import slider24 from "../../../assets/images/Price_3.png";
import slider25 from "../../../assets/images/Price_4.png";
import kv from "../../../assets/images/kv-top.png";
import group12134 from "../../../assets/images/Group12134.png";
import group12118 from "../../../assets/images/Group12118.png";
import group12119 from "../../../assets/images/Group 12119.png";
import group12144 from "../../../assets/images/Group 12144.png";
import group12171 from "../../../assets/images/Group 12171.png";
import line from "../../../assets/images/line.png";
import atsumi from "../../../assets/images/atsumi.png";
import abhi from "../../../assets/images/abhi.png";
import taku from "../../../assets/images/taku.png";
import se1 from "../../../assets/images/ses1.png";
import se2 from "../../../assets/images/ses2.png";
import se3 from "../../../assets/images/ses3.png";
import se4 from "../../../assets/images/ses4.png";
import se5 from "../../../assets/images/ses5.png";
import se6 from "../../../assets/images/ses6.png";
import se7 from "../../../assets/images/ses7.png";
import se8 from "../../../assets/images/ses8.png";
import se9 from "../../../assets/images/ses9.png";
import works from "../../../assets/images/works-img.png";
import lg1 from "../../../assets/images/lg1.png";
import lg4 from "../../../assets/images/lg4.png";
import lg3 from "../../../assets/images/lg3.png";
import "./Price.css";
function Service() {
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // heading display
  const renderGreenBarService = () => {
    return (
      <div className="service_bar flt">
        <img className="img_length" src={kv}></img>
        {/* <div className="service_content5">料金</div> */}
      </div>
    );
  };

  //different button display
  const renderGreenButtonsService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="box_container">
          {/* <div className="mark_logo1 flt">
            <img src={mark_logo} alt="image" />
          </div> */}
          <div className="mark_head1 flt">私達に任せてみませんか？</div>
          <div className="sub_title">
            どこよりも費用も結果もご満足いただける
            <br />
            WEB制作、開発サービスを私たちが提供します
          </div>
        </div>

        {/* <div className="service_bar flt">
          <img className="img_length2" src={price_table}></img>
          <img className="img_length1" src={price_table1}></img>
        </div> */}

        {/* <img src={price} className="price-img" alt="image" />
        <div className="price-img">
          <div className="point_cont1">
            ※ ・　トップページを含む7P想定。 <br />
            ・アニメーション（フェードイン） <br />
            ・レスポンシブ対応。 <br />
            ・ワードプレス挿入し、お知らせ・ブログは更新時、トップページへ自動表示。
          </div>
        </div> */}
      </div>
    );
  };
  const renderGreen1BarService = () => {
    return (
      <div className="service_bar2 flt background">
        <div className="container special1">
          <div className="row">
            <div className="col-sm-4">
              <a
                href="https://www.youtube.com/watch?v=8h4BCUhwW6U"
                target="_blank"
              >
                <img src={atsumi} alt="image" className="pervice-img extra" />
              </a>
            </div>
            <div className="col-sm-7">
              <p className="m-title">Director</p>
              <p className="j-title">ディレクター</p>
              <img className="line1" src={line}></img>
              <p className="m-subtitle">
                <span>計画、設計 </span>
              </p>
              <p className="m-subtitle">
                <span>デザイン方向性をまとめ</span>{" "}
              </p>
              <p className="m-subtitle">
                <span>結果に繋げる担当</span>
              </p>
              <p className="m-descp">
                AVII IMAGEWORKSのWEBサイト制作サービスはお客様の「HPを作る」
                <br />
                「リニューアルする」「システムを作る」に「なぜ？」
                <br />
                を問いかけます。その答えが返ってきたところから計画は始まります。
                <br />
                <br />
                創ったものが結果につながるフックとなるサイト、システム、
                <br />
                APPをお約束します。
              </p>
            </div>
          </div>
        </div>
        <div className="container special1">
          <div className="row">
            <div className="col-sm-7">
              <p className="m-title1">Front engineer</p>
              <p className="j-title">エンジニア</p>
              <img className="line1" src={line}></img>
              <p className="m-subtitle">
                <span>コーディングします </span>
              </p>
              <p className="m-subtitle">
                <span>インドとの架け橋もします</span>{" "}
              </p>
              <p className="m-descp">
                ワードプレスなどのCMSをつかい。お客様で更新できるWEBサイト
                <br />
                からWEBシステム開発、APPまでお任せください。
                <br />
                レスポンシブはもちろん、ページ内アニメーションも
                <br />
                お任せください。
              </p>
            </div>
            <div className="col-sm-4">
              <a
                href="https://www.youtube.com/watch?v=E09o9UOXISI"
                target="_blank"
              >
                <img src={abhi} alt="image" className="pervice-img extra" />
              </a>
            </div>
          </div>
        </div>
        <div className="container special1">
          <div className="row">
            <div className="col-sm-4">
              <img src={taku} alt="image" className="pervice-img extra" />
            </div>
            <div className="col-sm-7">
              <p className="m-title2">Designer</p>
              <p className="j-title">デザイナー</p>
              <img className="line1" src={line}></img>
              <p className="m-subtitle">
                <span>素敵デザインをお届けします </span>
              </p>
              <p className="m-subtitle">
                <span>僕は動画苦手なので</span>{" "}
              </p>
              <p className="m-subtitle">
                <span>デザインで期待に応えます</span>
              </p>
              <p className="m-descp">
                子供の頃から目にはいるものは、なんでこんな形なんだろうか <br />
                と考えていました。実際に人がみるものを作ると <br />
                楽しくて無性にデザインすることにとりかかってしまいます。
                ディレクション連携から方向性をしめして <br />
                もらった内容をもとにデザイン制作していきます。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //the text for respective button
  const renderBlackContentsService = () => {
    return (
      <div className="service_bar2 flt background-b imagebg">
        <div className="container">
          <p className="greenTitle">
            WEBサイト
            <br />
            営業ツール
            <br />
            会員システム
            <br />
            販売サイト
            <br />
            予約サイト
            <br />
            全て対応、制作可能
          </p>
          <img className="line" src={line}></img>
          <div className="service_bar flt">
            <img className="img_length3" src={group12171}></img>
          </div>
        </div>
      </div>
    );
  };
  //the text for respective button
  const renderTextContentsService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="box_container">
          <div className="point_title flt">
            <div className="service_bar1 flt">
              {/* <img className="img_length3" src={group12133}></img>
              <img className="img_length4" src={group12132}></img> */}
              <p className="main_title service">Technical</p>
              <p className="main_sub_t">対応能力</p>
              <img className="line" src={line}></img>
            </div>
            <div className="service_bar flt">
              <img className="img_length4" src={group12134}></img>
              <p className="descp">
                チャットより更新の有無やショップ連携などもご相談いただき <br />
                構築方法をご一緒に検討させていただきます。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //Price section
  const renderGreen2BarService = () => {
    return (
      <div className="service_bar2 flt background-b">
        <p className="main_title white-color">Price</p>
        <p className="main_sub_t white-color">料金</p>
        <img className="line" src={line}></img>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src={slider22} alt="image" className="pervice-img" />
            </div>
            <div className="col-sm-3">
              <img src={slider23} alt="image" className="pervice-img" />
            </div>
            <div className="col-sm-3">
              <img src={slider24} alt="image" className="pervice-img" />
            </div>
            <div className="col-sm-3">
              <img src={slider25} alt="image" className="pervice-img" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  //Works sections
  const renderGreen3BarService = () => {
    return (
      <div className="service_bar2 flt background-b">
        <img className="line" src={works}></img>
        <p className="main_title white-color">Works</p>
        <p className="main_sub_t white-color">制作実績</p>
        <img className="line" src={line}></img>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se1} alt="image" className="pervice-img" />
              <p className="work-title">JOINT CREW</p>
            </div>
            <div className="col-sm-4">
              <img src={se2} alt="image" className="pervice-img" />
              <p className="work-title">日本児童教育専門学校</p>
            </div>
            <div className="col-sm-4">
              <img src={se3} alt="image" className="pervice-img" />
              <p className="work-title">SANPO神戸</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se4} alt="image" className="pervice-img1" />
              <p className="work-title">Original One Cup OZEKI</p>
            </div>
            <div className="col-sm-4">
              <img src={se5} alt="image" className="pervice-img1" />
              <p className="work-title">日本児童教育専門学校</p>
            </div>
            <div className="col-sm-4">
              <img src={se6} alt="image" className="pervice-img1" />
              <p className="work-title">株式会社脳レボ様</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se7} alt="image" className="pervice-img1" />
              <p className="work-title">株式会社コスメティクスラボ</p>
            </div>
            <div className="col-sm-4">
              <img src={se8} alt="image" className="pervice-img1" />
              <p className="work-title">カジャ団地</p>
            </div>
            <div className="col-sm-4">
              <img src={se9} alt="image" className="pervice-img1" />
              <p className="work-title">TKクリエイト株式会社様</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //Road map sections
  const renderGreen4BarService = () => {
    return (
      <div className="service_bar2 flt background-w imagebg2">
        <div className="container">
          <div className="mark_head1 flt">
            完成しても <br />
            がっちりサポート
          </div>
          <div className="sub_title">
            ◉更新マニュアルの作成 <br />
            ◉お客様サーバーへのサイトアップロード
            <br />
            お任せください！
          </div>
        </div>
      </div>
    );
  };

  //Road map2 sections
  const renderGreen5BarService = () => {
    return (
      <div className="service_bar2 flt background-y">
        <div className="container">
          <p className="main_title margin">Rode map</p>
          <p className="main_sub_t">全てチャットでスピーディーでご連絡</p>
          <img className="line" src={line}></img>
          <img className="img_length6" src={group12118}></img>
        </div>
      </div>
    );
  };
  //Road map3 sections
  const renderGreentwoBarService = () => {
    return (
      <div className="service_bar flt background-l">
        <div className="container">
          <div className="service_bar1 flt">
            <img className="img_length6" src={group12119}></img>
          </div>
        </div>
      </div>
    );
  };
  //Road map4 sections
  const renderGreen7BarService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="container">
          <div className="point_title flt">
            <div className="service_bar1 flt">
              <img className="img_length6" src={group12144}></img>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //Road contact sections
  const renderContactService = () => {
    return (
      <div className="service_bar flt contact">
        <div className="container">
          <div className="service_bar1 flt">
            <div className="container special2">
              <div className="row">
                <div className="col-sm-4">
                  <p className="con-title">Contact</p>
                </div>
                <div className="col-sm-7">
                  <p className="con-sub">
                    マイページよりチャットで簡単やりとり可能
                    <br />
                    <span>
                      <a
                        href="https://recursive-design.com/contact"
                        target="_blank"
                      >
                        お問い合わせはこちらから
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              <div className="reservation-btn">
                <Link to="/login">
                  <img src={lg1} alt="" />
                </Link>
              </div>
              <div className="reservation-btn1">
                <Link to="/design">
                  <img src={lg4} alt="" />
                </Link>
              </div>
              <div className="reservation-btn2">
                <Link to="/it-service">
                  <img src={lg3} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAviiService = () => {
    return (
      <div className="service_bar flt avii">
        <div className="container special2 center">
          <h2>AVII IMAGEWORKS</h2>
          <p>
            数多くのお客様と企画から制作まで行ってきた実績と経験値を生かし
            <br />
            私たちがサポートします！
          </p>
          <a href="https://avii.jp/" target="_blank">
            <button class="button button1">運営会社サイト</button>
          </a>
        </div>
      </div>
    );
  };

  //button display
  const renderMixGreenButtonPDFService = () => {
    return (
      <div className="algn_centr">
        <button
          className="serv_btn"
          onClick={() => history.push("/Registration")}
        >
          <div className="fltlft">
            会員登録なしでPDFで
            <br />
            無料見積もりしてみる
          </div>
          <div className="fltrgttt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        {/* Actual Home page starts */}
        <Header />

        {/*Start of  FAQ section */}
        <div className="flt">
          {renderGreenBarService()}
          <div className="container">
            <div className="serv_container">
              {renderGreenButtonsService()}
              {/* {renderTextContentsServices()} */}
              {/* {renderMixGreenButtonPDFService()} */}
            </div>
          </div>
          {renderGreen1BarService()}
          {renderBlackContentsService()}
          <div className="container">
            <div className="serv_container">
              {renderTextContentsService()}
              {/* {renderTextContentsServices()} */}
              {/* {renderMixGreenButtonPDFService()} */}
            </div>
          </div>
          {renderGreen2BarService()}
          {renderGreen3BarService()}
          {renderGreen4BarService()}
          {renderGreen5BarService()}
          {renderGreentwoBarService()}
          {renderGreen7BarService()}
          {renderContactService()}
          {renderAviiService()}
        </div>
        {/*End of  FAQ section */}

        <Footer />
      </div>
      {/* Actual Home page Ends */}
    </div>
  );
}

export default Service;
