import axios from 'axios'
import { authHeader } from '../_helpers';
//test
export const commentImage = {
    add,
    handleResponse,
    addImage,
    getImage,
    getImageId

};



async function add(add) {
    var postdata = {};
    postdata.data = add;
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader()

        },
        url: `${process.env.REACT_APP_BASE_URL}users/addImage`,
        data: postdata
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function getImage() {
    //let pno = 2;
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}users/getImage-all`,
        headers: authHeader()
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}

async function getImageId(link) {
    const requestOptions = {
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}users/getImage?link=${link}`,
        // headers: authHeader()
    };

    return await axios(requestOptions).then(handleResponse);
}


async function addImage(image) {


    // var bodyFormData = {

    //     image: image
    // };
    // console.log(bodyFormData)
    const bodyFormData = new FormData();
    bodyFormData.append("image", image);
    // bodyFormData.append("mobile_no", add.mobile_no)
    // bodyFormData.append("email", add.email)
    // bodyFormData.append("reason", add.reason)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}users/addImage`,
        data: bodyFormData
    };


    return await axios(
        requestOptions
    ).then(handleResponse);
}





function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}

