import React, { useState, useEffect } from "react";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import "./HomePage.css";
import home_banner_pic from "../../../assets/images/home_banner_pic.png";
import home_banner_logo from "../../../assets/images/home_banner_logo.png";
import mark_logo from "../../../assets/images/mark_logo.png";
import mark_map from "../../../assets/images/mark_map.png";
import triangle from "../../../assets/images/triangle.png";
import point_title from "../../../assets/images/point_title.png";
import point1 from "../../../assets/images/arr.png";
import point2a from "../../../assets/images/point2a.png";
import point2b from "../../../assets/images/point2b.png";
import point_india from "../../../assets/images/point_india.png";
import point_japan from "../../../assets/images/point_japan.png";
import point_site_logo from "../../../assets/images/point_site_logo.png";
import point_site_man from "../../../assets/images/point_site_man.png";
// import point_case_logo from '../../../asseets/images/point_case_logo.png';
import point_case from "../../../assets/images/point_case.png";
import partner1 from "../../../assets/images/partner1.png";
import partner2 from "../../../assets/images/partner2.png";
import partner3 from "../../../assets/images/partner3.png";
import partner4 from "../../../assets/images/partner4.png";
import partner5 from "../../../assets/images/partner5.png";
import partner6 from "../../../assets/images/partner6.png";
import partner7 from "../../../assets/images/partner7.png";
import partner8 from "../../../assets/images/partner8.png";
import { useHistory } from "react-router-dom";
import HomeColumnServices from "../../../_services/Homecolumn.services";
import { Link } from "react-router-dom";
import book_man from "../../../assets/images/book_man.png";
import home_man2 from "../../../assets/images/home_man.png";
import app2 from "../../../assets/images/app.png";
// import price from "../../../assets/images/pricing.png";
import topprice from "../../../assets/images/top-price.png";
import pricing1 from "../../../assets/images/pricing-t1.png";
import KV from "../../../assets/images/kv.png";
import lg2 from "../../../assets/images/lg2.png";
import lg3 from "../../../assets/images/lg3.png";
import lg4 from "../../../assets/images/lg4.png";
import kv from "../../../assets/images/kv2.png";
import image789 from "../../../assets/images/Image 789.png";
import slider22 from "../../../assets/images/Price_1.png";
import slider23 from "../../../assets/images/Price_2.png";
import slider24 from "../../../assets/images/Price_3.png";
import slider25 from "../../../assets/images/Price_4.png";
import group12134 from "../../../assets/images/Group12134.png";
import group12118 from "../../../assets/images/Group12118.png";
import group12119 from "../../../assets/images/Group 12119.png";
import group12144 from "../../../assets/images/Group 12144.png";
import group12171 from "../../../assets/images/Group 12171.png";
import line from "../../../assets/images/line.png";
import se1 from "../../../assets/images/ses1.png";
import se2 from "../../../assets/images/ses2.png";
import se3 from "../../../assets/images/ses3.png";
import se4 from "../../../assets/images/ses4.png";
import se5 from "../../../assets/images/ses5.png";
import se6 from "../../../assets/images/ses6.png";
import se7 from "../../../assets/images/ses7.png";
import se8 from "../../../assets/images/ses8.png";
import se9 from "../../../assets/images/ses9.png";
import works from "../../../assets/images/works-img.png";
import group12173 from "../../../assets/images/Group 12173.png";
import profileImage from "../../../assets/images/profile-image.png";
import image78 from "../../../assets/images/Image 78.png";
import group12179 from "../../../assets/images/Group 12179.png";
import group12135 from "../../../assets/images/Group12135.png";
import lg1 from "../../../assets/images/lg1.png";
import avii from "../../../assets/images/avii.png";

function HomePage() {
  const [data, setData] = useState([]);
  const [current, setcurrent] = useState(1);
  let history = useHistory();

  useEffect(() => {
    HomeColumnServices.getData(current).then((data) => {
      setData(data);
      console.log(data && data);
    });
    console.log(data && data.data);
  }, []);
  //column design
  const renderHomeColumn = () => {
    return (
      <div className="flt col_space">
        <div className="news_container">
          <div className="row">
            <div className="col-sm-4">
              <div style={{ display: "flex" }}>
                <span className=".green_verticle_line_news"></span>
                <div className="news">COLUMN</div>
                <div className="news_text">お知らせ</div>
              </div>
            </div>
            <div className="col-sm-7 date">
              <table>
                {data.data &&
                  data.data.blog.map((homcol, i) => {
                    console.log(data.data);
                    return (
                      <tr className="fltlft1">
                        <td className="datestyl">
                          {homcol.date}
                          <span className="green_verticle_line"></span>
                        </td>

                        <Link
                          to={"/article-details/" + homcol._id}
                          className="home_link"
                        >
                          <td>{homcol.title}</td>
                        </Link>
                      </tr>
                    );
                  })}
              </table>
            </div>
            <div className="japtxt">
              <button
                onClick={() => history.push("/column")}
                className="blackbtn1"
              >
                <div className="fltlft">もっと見る</div>
                <div className="fltrgt">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
    );
  };
  const renderGreenBarService = () => {
    return (
      <div className="service_bar flt">
        <img className="img_length" src={kv}></img>
        {/* <div className="service_content5">料金</div> */}
      </div>
    );
  };
  // const renderHomeBanner = () => {
  //   return (
  //     // <div className="home_banner flt">
  //     //     <div className="container">
  //     //         <div className="row">
  //     //             <div className="col-sm-8">
  //     //                 <div className="home_banner_left flt">
  //     //                     <div className="home_banner_logo flt">
  //     //                         <img src={home_banner_logo} alt="image" />
  //     //                     </div>
  //     //                     <div className="home_banner_feat flt">
  //     //                         <span className="green">驚異的なスピード&nbsp;&nbsp;&nbsp;</span>
  //     //                         <span className="white">x</span>
  //     //                         <span className="orange">低コスト&nbsp;&nbsp;&nbsp;</span>
  //     //                     </div>
  //     //                     <div className="home_banner_head flt">
  //     //                         インドコーディング、開発サービス
  //     //                     </div>
  //     //                     <div className="home_banner_cont flt">
  //     //                         デザインを入稿するだけで、誰でもWEBページを作ることができる画期的なサービス！<br />インドの優秀なエンジニアとタッグを組み、日本では実現不可能だった価格を実現。
  //     //                     </div>
  //     //                 </div>
  //     //             </div>
  //     //             <div className="col-sm-4">
  //     //                 <div className="home_banner_right flt">
  //     //                     <img src={home_banner_pic} alt="image" />
  //     //                 </div>
  //     //             </div>
  //     //         </div>
  //     //     </div>

  //     // </div>

  //     // <div className="home_mn flex">
  //     //   {/* <img src={home_man2} alt="image" /> */}
  //     //   <div className="fillera"></div>
  //     //   <div className="homeb">
  //     //     <img
  //     //       src={home_banner_logo}
  //     //       className="home_shir_banner"
  //     //       alt="image"
  //     //     />
  //     //     <div className="home_banner_feat flt">
  //     //       <span className="green">驚異的なスピード&nbsp;</span>
  //     //       <span className="white">x</span>
  //     //       <span className="orange">低コスト&nbsp;</span>
  //     //       <span className="white">x</span>
  //     //       <span className="orange">圧倒的なクオリティ&nbsp;</span>
  //     //     </div>
  //     //     <div className="home_banner_head flt">
  //     //       インドコーディング、開発サービス
  //     //       <h1>
  //     //         インドでコーディング！早い安い日本人対応！アフターサービスも充実！印コーディングサービス！
  //     //       </h1>
  //     //     </div>
  //     //     <div className="home_banner_cont flt">
  //     //       デザインを入稿するだけで、誰でもWEBページを作ることができる画期的なサービス！
  //     //       <br />
  //     //       インドの優秀なエンジニアとタッグを組み、日本では実現不可能だった価格を実現。
  //     //     </div>
  //     //     <div>
  //     //       <img src={topprice} className="home_shir_banner1" alt="image" />
  //     //     </div>
  //     //   </div>
  //     // </div>
  //   );
  // };
  // const markin1 = () => {
  //   return (
  //     <div className="flt flex">
  //       <div className="mark_box_cont">
  //         印（しるし）コーディングサービスとは、デザインをアップロードするだけでWEB
  //         <br />
  //         ページ制作ができる画期的なサービスです。WEBページ制作は、多大なコス
  //         <br />
  //         トが発生するという課題がありました。しかし、私たちはIT大国インドの優秀
  //         <br />
  //         なエンジニアとタッグを組むことで、日本では実現できなかった価格を実現
  //         <br />
  //         し、かつハイクオリティーなWEBページ制作を提供いたします。
  //         <br />
  //         わたしたちは、誰でもWEBページ作成ができる時代を創り出します。
  //       </div>
  //       <div className="mark_map ">
  //         <img src={mark_map} alt="image" />
  //       </div>
  //     </div>
  //   );
  // };
  const renderBlackContents3Service = () => {
    return (
      <div className="service_bar2 flt background-b">
        <div className="container">
          <div className="mark_head1 flt white-color bg-img">
            安心してください！
            <br />
            日本人です！
          </div>
          <div className="service_bar flt">
            <img className="img_length6" src={image789}></img>
            <div className="sub_title white-color">
              ◉日本人が対応します！
              <br />
              ◉制作進行までにオンラインMTG可能
              <br />
              ◉納期は遅れません
              <br />
              ◉見積もり後の追加はかかりません
              <br />
              （追加デザインやアニメーションがおこらないように事前MTGします）
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGreenButtonsService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="box_container">
          {/* <div className="mark_logo1 flt">
            <img src={mark_logo} alt="image" />
          </div> */}
          <div className="mark_head1 flt">
            安い、早い、ハイクオリティー
            <br />
            全ての面で満足間違いなし
          </div>
          <div className="sub_title">
            インドとの時差は3時間
            <br />
            また土曜日もコーディング体制整ってます
          </div>
          <div className="sub_title1">マイページからチャットでの連携が可能</div>
          <div className="span-title">
            不要なお手間をかけずスムーズ進行できるチャット
          </div>
          <img className="img_length3" src={group12173} alt="image" />
        </div>

        {/* <div className="service_bar flt">
          <img className="img_length2" src={price_table}></img>
          <img className="img_length1" src={price_table1}></img>
        </div> */}

        {/* <img src={price} className="price-img" alt="image" />
        <div className="price-img">
          <div className="point_cont1">
            ※ ・　トップページを含む7P想定。 <br />
            ・アニメーション（フェードイン） <br />
            ・レスポンシブ対応。 <br />
            ・ワードプレス挿入し、お知らせ・ブログは更新時、トップページへ自動表示。
          </div>
        </div> */}
      </div>
    );
  };

  const renderGreen1BarService = () => {
    return (
      <div className="service_bar2 flt background-b">
        <div className="container special1">
          <p className="main_title white-color">
            わたし達だからできる
            <br />
            インド開拓
          </p>
          <img className="img_length4" src={profileImage}></img>
          <p className="main_sub_t white-color">
            インドは大半が英語を話せますがインド英語とよばれるように少し発音が難しいです。
            <br />
            しかし私たちAVIIのエンジニアは日本語が話せるエンジニアであり
            <br />
            代表とインドへ出向き開拓をおこなってきました。
          </p>
        </div>
        <div className="container special1">
          <p className="main_title white-color">
            ベトナム、台湾などの
            <br />
            アウトソーシングとはレベチ！
          </p>
          <img className="img_length4" src={image78}></img>
          <p className="main_sub_t white-color spacing">
            ＞お金を稼ぐためにとりあえずコーディングできるようになった
            <br />
            ではなく学生からしっかりみっちり勉強しているエンジニアばかりです
            <br />
            <br />
            ＞基礎知識と応用力があるので「出来ない」は基本的になく、
            <br />
            どのように行えば最善で制作できるのかを考えて実行します。
          </p>
        </div>
      </div>
    );
  };
  // const markin = () => {
  //   return (
  //     <div className="container special">
  //       <div className="mark_logo flt">
  //         <img src={mark_logo} alt="image" />
  //       </div>
  //       <img src={KV} className="kv-image" alt="image" />
  //     </div>
  //     // <div className="container">
  //     //   <div className="markin flt">
  //     //     <div className="mark_logo flt">
  //     //       <img src={mark_logo} alt="image" />
  //     //     </div>
  //     //     <div className="mark_head flt">印(しるし)コーディングって何?</div>

  //     //     {markin1()}
  //     //     <div className="mark_box flt">
  //     //       <div className="row">
  //     //         <div className="col-sm-6">
  //     //           {/* <div className="mark_box_cont flt">
  //     //                               印（しるし）コーディングサービスとは、デザインをアップロードするだけでWEB<br />
  //     //                               ページ制作ができる画期的なサービスです。WEBページ制作は、多大なコス<br />
  //     //                               トが発生するという課題がありました。しかし、私たちはIT大国インドの優秀<br />
  //     //                               なエンジニアとタッグを組むことで、日本では実現できなかった価格を実現<br />
  //     //                               し、かつハイクオリティーなWEBページ制作を提供いたします。<br />
  //     //                               わたしたちは、誰でもWEBページ作成ができる時代を創り出します。

  //     //                           </div> */}
  //     //           <div className="mark_btn flt">
  //     //             <p>
  //     //               <button
  //     //                 onClick={() => history.push("/Registration")}
  //     //                 className="green"
  //     //               >
  //     //                 初めての方は
  //     //                 <br />
  //     //                 会員登録して見積もり
  //     //               </button>
  //     //             </p>
  //     //             <button
  //     //               onClick={() => history.push("/login")}
  //     //               className="black"
  //     //             >
  //     //               ログイン
  //     //             </button>
  //     //           </div>
  //     //         </div>
  //     //         <div className="col-sm-6">
  //     //           {/* <div className="mark_map flt">
  //     //                               <img src={mark_map} alt="image" />
  //     //                           </div> */}
  //     //           <div className="mark_note flt">
  //     //             <div className="mark_note_box">
  //     //               お客様取引実績 <span>800</span>社<br />
  //     //               WEB・システム <span>1200</span>サイト
  //     //               <div className="mark_trainagle">
  //     //                 <img src={triangle} alt="image" />
  //     //               </div>
  //     //             </div>
  //     //           </div>
  //     //         </div>
  //     //       </div>
  //     //     </div>
  //     //   </div>
  //     // </div>
  //   );
  // };

  // const point_main = () => {
  //   return (
  //     <div className="point_main flt">
  //       <div className="container">
  //         <img src={app2} alt="image" />
  //         <div className="point_title flt">
  //           <span>
  //             <img src={point_title} className="point_title_pic" alt="image" />
  //             あなたのサイト最安値で作成します！
  //             <img src={triangle} className="point_title_arrow" alt="image" />
  //           </span>
  //         </div>
  //         <img src={pricing1} alt="image" />
  //         {/* <img src={price} className="price-img" alt="image" />
  //         <div className="price-img">
  //           <div className="point_cont1 flt">
  //             ※ ・　トップページを含む7P想定。 <br />
  //             ・アニメーション（フェードイン） <br />
  //             ・レスポンシブ対応。 <br />
  //             ・ワードプレス挿入し、お知らせ・ブログは更新時、トップページへ自動表示。
  //           </div>
  //         </div> */}
  //         <div className="col-sm-12">
  //           <div className="point_title flt">
  //             <span>
  //               <img
  //                 src={point_title}
  //                 className="point_title_pic"
  //                 alt="image"
  //               />
  //               印コーディングの特徴
  //               <img src={triangle} className="point_title_arrow" alt="image" />
  //             </span>
  //           </div>
  //           <div className="point_box flt">
  //             <div className="point_box_head">
  //               <div className="filler"></div>
  //               <span>POINT</span>
  //               <b>01</b>
  //               <div className="filler"></div>
  //             </div>
  //             <div className="point_tag flt">
  //               <span>デザインデータ連携</span>から
  //               <span>サイト表示サポート</span>
  //               まで
  //               <br />
  //               チャット機能で対応します
  //             </div>
  //             <div className="point_image1 flt">
  //               <img src={point1} alt="image" />
  //             </div>
  //           </div>

  //           <div className="point_box point_box2 flt">
  //             <div className="point_box_head">
  //               <div className="filler"></div>
  //               <span>POINT</span>
  //               <b>02</b>
  //               <div className="filler"></div>
  //             </div>
  //             <div className="point_tag flt">
  //               <span>安心</span>の再現性
  //             </div>
  //             <div className="point_cont flt">
  //               日本人で構成される設計フロント担当と、日本とは比べれないスキルをもったインドエンジニアが
  //               <br />
  //               デザインに対し忠実な再現をおこないます。
  //             </div>
  //             <div className="point_image flt">
  //               <img src={point2a} alt="image" />
  //               <img src={point2b} alt="image" />
  //             </div>
  //             <button
  //               className="point_box_btn"
  //               onClick={() => window.location.replace("/firstuser/#7")}
  //             >
  //               {/* <Link to='/firstuser/#7'>  なぜインドなの？</Link> */}
  //               なぜインドなの？
  //             </button>
  //           </div>
  //         </div>
  //         <div className="row">
  //           <div className="col-sm-6">
  //             <div className="point_box point_box3 flt">
  //               <div className="point_box_head">
  //                 <div className="filler"></div>
  //                 <span>POINT</span>
  //                 <b>03</b>
  //                 <div className="filler"></div>
  //               </div>
  //               <div className="point_tag flt">
  //                 <span>スピード</span>対応
  //               </div>
  //               <div className="point_cont flt">
  //                 とにかく実行力があり。形にすることを先決します。
  //                 <br />
  //                 その後、日本の設計フロントチームと確認テストをおこないます。
  //                 <br />
  //                 3ヶ月システムを2ヶ月にまとめあげた実績をもちます。
  //               </div>
  //               <div className="point_desc flt">
  //                 <div className="filler"></div>
  //                 <div className="point_desc_txt">
  //                   見た目の調整
  //                   <br />
  //                   10時〜18時
  //                   <br />
  //                   (月)〜(金)
  //                   <img
  //                     src={triangle}
  //                     className="point_desc_arrow"
  //                     alt="image"
  //                   />
  //                 </div>
  //                 <div className="point_desc_pic">
  //                   <img src={point_japan} alt="image" />
  //                 </div>
  //                 <div className="filler"></div>
  //               </div>
  //               <div className="point_time flt">時差3.5時間</div>
  //               <div className="point_desc flt">
  //                 <div className="filler"></div>
  //                 <div className="point_desc_txt">
  //                   サーバーの裏方作業
  //                   <br />
  //                   13時〜21時
  //                   <br />
  //                   (月)〜（土）
  //                   <img
  //                     src={triangle}
  //                     className="point_desc_arrow"
  //                     alt="image"
  //                   />
  //                 </div>
  //                 <div className="point_desc_pic">
  //                   <img src={point_india} alt="image" />
  //                 </div>
  //                 <div className="filler"></div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="col-sm-6">
  //             <div className="point_box point_box4 flt">
  //               <div className="point_box_head">
  //                 <div className="filler"></div>
  //                 <span>POINT</span>
  //                 <b>04</b>
  //                 <div className="filler"></div>
  //               </div>
  //               <div className="point_tag flt">低価格</div>
  //               <div className="point_cont flt">
  //                 インド（ペソ）と日本（円）の物価の違いから
  //                 <br />
  //                 大きく安価実現しております。
  //               </div>
  //               <div className="point_box_money flt">
  //                 TOPページコーディング <br />
  //                 <span>￥10,000~</span>
  //               </div>
  //               <div className="point_box_money flt">
  //                 システムサイト設計・構築
  //                 <br />
  //                 <span>￥350,000~</span>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const point_site = () => {
  //   return (
  //     // <div className="point_site flt">
  //     //     <div className="container">
  //     //         <div className="point_sitein flt flex">
  //     //             <div className="point_site_logo">
  //     //                 <img src={point_site_logo} alt="image" />
  //     //             </div>
  //     //             <div className="point_site_txt">
  //     //                 <span>自社のサイトからお客様のサイトも！</span><br />
  //     //                 自社システムからお客様のシステム、アプリまで！
  //     //             </div>
  //     //             <div>
  //     //                 <img src={point_site_man} className="point_site_man" alt="image" />
  //     //             </div>

  //     //         </div>
  //     //     </div>
  //     // </div>
  //     <div className="flt">
  //       <div className="flt">
  //         <div className="row book_man_flex flt">
  //           <div className="col-sm-3">
  //             <img
  //               src={point_site_logo}
  //               alt="image"
  //               className="point_site_logo1"
  //             />
  //           </div>
  //           <div className="col-sm-7">
  //             <div className="point_site_txt">
  //               <span>自社のサイトからお客様のサイトも!</span>
  //               <br />
  //               自社システムからお客様のシステム、アプリまで
  //             </div>
  //           </div>
  //           <div className="col-sm-2">
  //             <img
  //               src={point_site_man}
  //               alt="image"
  //               className="point_site_man"
  //             />
  //           </div>
  //           <div className="reservation-btn">
  //             <Link to="/web-production">
  //               <img src={lg2} alt="" />
  //             </Link>
  //           </div>
  //           <div className="reservation-btn1">
  //             <Link to="/it-service">
  //               <img src={lg3} alt="" />
  //             </Link>
  //           </div>
  //           <div className="reservation-btn2">
  //             <Link to="/design">
  //               <img src={lg4} alt="" />
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  // const point_site1 = () => {
  //   return (
  //     <div className="point_site flt">
  //       <div className="">
  //         <img src={book_man} className="book_man"></img>
  //       </div>
  //     </div>
  //   );
  // };
  // const point_case_main = () => {
  //   return (
  //     <div className="point_case_main flt">
  //       <div className="container">
  //         <div className="point_case flt">
  //           <div className="point_case_logo flt">
  //             {/* <img src={point_case_logo} alt="image" /> */}
  //           </div>
  //           <div className="point_case_tag flt">お客様の声</div>
  //           <div className="row">
  //             <div className="col-sm-4">
  //               <div className="point_case_box flt">
  //                 <div className="point_case_name flt">CASE.01</div>
  //                 <div className="point_case_image flt">
  //                   <img src={point_case} alt="image" />
  //                 </div>
  //                 <div className="point_case_cont flt">
  //                   <div className="point_case_line1 flt">
  //                     〇〇デザイン事務所
  //                   </div>
  //                   <div className="point_case_line2 flt">
  //                     「自社からお客様の案件まで」
  //                   </div>
  //                   <div className="point_case_line3 flt">
  //                     デザイン会社なりのこだわりまでも再現しても
  //                     <br />
  //                     らいました。自社サイトから依頼したのですが、今では
  //                     <br />
  //                     お客様の案件までコーディング依頼をかけさせてもらっ
  //                     <br />
  //                     てます。
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="col-sm-4">
  //               <div className="point_case_box flt">
  //                 <div className="point_case_name flt">CASE.02</div>
  //                 <div className="point_case_image flt">
  //                   <img src={point_case} alt="image" />
  //                 </div>
  //                 <div className="point_case_cont flt">
  //                   <div className="point_case_line1 flt">〇〇株式会社</div>
  //                   <div className="point_case_line2 flt">
  //                     「自社システムを保守メンテまで」
  //                   </div>
  //                   <div className="point_case_line3 flt">
  //                     自社のWEBシステムをつくってもらいましたが、期限よ
  //                     <br />
  //                     り早く完成し、またオーダーしていたものでは足らずの部
  //                     <br />
  //                     分をすでに補ってくれていました。今では保守メンテま
  //                     <br />
  //                     で定期的なお付き合いに。
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="col-sm-4">
  //               <div className="point_case_box flt">
  //                 <div className="point_case_name flt">CASE.03</div>
  //                 <div className="point_case_image flt">
  //                   <img src={point_case} alt="image" />
  //                 </div>
  //                 <div className="point_case_cont flt">
  //                   <div className="point_case_line1 flt">〇〇株式会社</div>
  //                   <div className="point_case_line2 flt">
  //                     「自社からお客様の案件まで」
  //                   </div>
  //                   <div className="point_case_line3 flt">
  //                     デザイン会社なりのこだわりまでも再現しても
  //                     <br />
  //                     らいました。自社サイトから依頼したのですが、今では
  //                     <br />
  //                     お客様の案件までコーディング依頼をかけさせてもらっ
  //                     <br />
  //                     てます。
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  // const partner_container = () => {
  //   return (
  //     <div className="partner_container flt">
  //       <div className="container">
  //         <div className="partner_logo flt">
  //           {/* <img src={point_case_logo} alt="image" /> */}
  //         </div>
  //         <div className="partner_tag flt">取引先企業様</div>
  //         <div className="partner_images flt">
  //           <ul>
  //             <li>
  //               <img src={partner1} alt="image" />
  //             </li>
  //             <li>
  //               <img src={partner2} alt="image" />
  //             </li>
  //             <li>
  //               <img src={partner3} alt="image" />
  //             </li>
  //             <li>
  //               <img src={partner4} alt="image" />
  //             </li>
  //             <li>
  //               <img src={partner5} alt="image" />
  //             </li>
  //             <li>
  //               <img src={partner6} alt="image" />
  //             </li>
  //             <li>
  //               <img src={partner7} alt="image" />
  //             </li>
  //             <li>
  //               <img src={partner8} alt="image" />
  //             </li>
  //             <li>
  //               <img src={partner5} alt="image" />
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  const renderBlackContents1Service = () => {
    return (
      <div className="service_bar2 flt background-w">
        <div className="container">
          <p className="main_title service">Service</p>
          <p className="main_sub_t">サービス内容</p>
          <img className="line" src={line}></img>
          <div className="service_bar flt">
            <img className="img_length8" src={group12179}></img>
          </div>
          <div className="service_bar flt">
            <p className="underlinetext">
              デザインデータを頂ければコーディング可能です
              <br />
              <span>マイページのチャット</span>
              よりダウンロードURLをご連絡ください。
            </p>
            <img className="img_length8" src={group12135}></img>
          </div>
        </div>
      </div>
    );
  };
  const renderBlackContentsService = () => {
    return (
      <div className="service_bar2 flt background-b imagebg">
        <div className="container">
          <p className="greenTitle">
            WEBサイト
            <br />
            営業ツール
            <br />
            会員システム
            <br />
            販売サイト
            <br />
            予約サイト
            <br />
            全て対応、制作可能
          </p>
          <img className="line" src={line}></img>
          <div className="service_bar flt">
            <img className="img_length8" src={group12171}></img>
          </div>
        </div>
      </div>
    );
  };

  const renderTextContentsService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="box_container">
          <div className="point_title flt">
            <div className="service_bar1 flt">
              {/* <img className="img_length3" src={group12133}></img>
              <img className="img_length4" src={group12132}></img> */}
              <p className="main_title service">Technical</p>
              <p className="main_sub_t">対応能力</p>
              <img className="line" src={line}></img>
            </div>
            <div className="service_bar flt">
              <img className="img_length4" src={group12134}></img>
              <p className="descp">
                チャットより更新の有無やショップ連携などもご相談いただき <br />
                構築方法をご一緒に検討させていただきます。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGreen2BarService = () => {
    return (
      <div className="service_bar2 flt background-b">
        <p className="main_title white-color">Price</p>
        <p className="main_sub_t white-color">料金</p>
        <img className="line" src={line}></img>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src={slider22} alt="image" className="pervice-img" />
            </div>
            <div className="col-sm-3">
              <img src={slider23} alt="image" className="pervice-img" />
            </div>
            <div className="col-sm-3">
              <img src={slider24} alt="image" className="pervice-img" />
            </div>
            <div className="col-sm-3">
              <img src={slider25} alt="image" className="pervice-img" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGreen3BarService = () => {
    return (
      <div className="service_bar2 flt background-b">
        <img className="line" src={works}></img>
        <p className="main_title white-color">Works</p>
        <p className="main_sub_t white-color">制作実績</p>
        <img className="line" src={line}></img>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se1} alt="image" className="pervice-img" />
              <p className="work-title">JOINT CREW</p>
            </div>
            <div className="col-sm-4">
              <img src={se2} alt="image" className="pervice-img" />
              <p className="work-title">日本児童教育専門学校</p>
            </div>
            <div className="col-sm-4">
              <img src={se3} alt="image" className="pervice-img" />
              <p className="work-title">SANPO神戸</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se4} alt="image" className="pervice-img1" />
              <p className="work-title">Original One Cup OZEKI</p>
            </div>
            <div className="col-sm-4">
              <img src={se5} alt="image" className="pervice-img1" />
              <p className="work-title">日本児童教育専門学校</p>
            </div>
            <div className="col-sm-4">
              <img src={se6} alt="image" className="pervice-img1" />
              <p className="work-title">株式会社脳レボ様</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img src={se7} alt="image" className="pervice-img1" />
              <p className="work-title">株式会社コスメティクスラボ</p>
            </div>
            <div className="col-sm-4">
              <img src={se8} alt="image" className="pervice-img1" />
              <p className="work-title">カジャ団地</p>
            </div>
            <div className="col-sm-4">
              <img src={se9} alt="image" className="pervice-img1" />
              <p className="work-title">TKクリエイト株式会社様</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGreen4BarService = () => {
    return (
      <div className="service_bar2 flt background-w imagebg2">
        <div className="container">
          <div className="mark_head1 flt">
            完成しても <br />
            がっちりサポート
          </div>
          <div className="sub_title">
            ◉更新マニュアルの作成 <br />
            ◉お客様サーバーへのサイトアップロード
            <br />
            お任せください！
          </div>
        </div>
      </div>
    );
  };

  const renderGreen5BarService = () => {
    return (
      <div className="service_bar2 flt background-y">
        <div className="container">
          <p className="main_title margin">Rode map</p>
          <p className="main_sub_t">全てチャットでスピーディーでご連絡</p>
          <img className="line" src={line}></img>
          <img className="img_length6" src={group12118}></img>
        </div>
      </div>
    );
  };
  //Road map3 sections
  const renderGreentwoBarService = () => {
    return (
      <div className="service_bar flt background-l">
        <div className="container">
          <div className="service_bar1 flt">
            <img className="img_length6" src={group12119}></img>
          </div>
        </div>
      </div>
    );
  };
  //Road map4 sections
  const renderGreen7BarService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="container">
          <div className="point_title flt">
            <div className="service_bar1 flt">
              <img className="img_length6" src={group12144}></img>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //Road contact sections
  const renderContactService = () => {
    return (
      <div className="service_bar flt contact">
        <div className="container">
          <div className="service_bar1 flt">
            <div className="container special2">
              <div className="row">
                <div className="col-sm-4">
                  <p className="con-title">Contact</p>
                </div>
                <div className="col-sm-7">
                  <p className="con-sub">
                    マイページよりチャットで簡単やりとり可能
                    <br />
                    <span>
                      <a
                        href="https://recursive-design.com/contact"
                        target="_blank"
                      >
                        お問い合わせはこちらから
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              <div className="reservation-btn">
                <Link to="/login">
                  <img src={lg1} alt="" />
                </Link>
              </div>
              <div className="reservation-btn1">
                <Link to="/it-service">
                  <img src={lg3} alt="" />
                </Link>
              </div>
              <div className="reservation-btn2">
                <Link to="/web-production">
                  <img src={lg2} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAviiService = () => {
    return (
      <div className="service_bar flt avii">
        <div className="container special2 center">
          <h2>AVII IMAGEWORKS</h2>
          <p>
            数多くのお客様と企画から制作まで行ってきた実績と経験値を生かし
            <br />
            私たちがサポートします！
          </p>
          <a href="https://avii.jp/" target="_blank">
            <button class="button button1">運営会社サイト</button>
          </a>
        </div>
      </div>
    );
  };

  //button display
  const renderMixGreenButtonPDFService = () => {
    return (
      <div className="algn_centr">
        <button
          className="serv_btn"
          onClick={() => history.push("/Registration")}
        >
          <div className="fltlft">
            会員登録なしでPDFで
            <br />
            無料見積もりしてみる
          </div>
          <div className="fltrgttt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <Header />
      <div className="home_container flt">
        {renderGreenBarService()}
        {renderBlackContents3Service()}
        <div className="container">
          <div className="serv_container">
            {renderGreenButtonsService()}
            {/* {renderTextContentsServices()} */}
            {/* {renderMixGreenButtonPDFService()} */}
          </div>
        </div>
        {renderGreen1BarService()}
        {renderBlackContents1Service()}
        {renderBlackContentsService()}
        <div className="container">
          <div className="serv_container">
            {renderTextContentsService()}
            {/* {renderTextContentsServices()} */}
            {/* {renderMixGreenButtonPDFService()} */}
          </div>
        </div>
        {renderGreen2BarService()}
        {renderGreen3BarService()}
        {renderGreen4BarService()}
        {renderGreen5BarService()}
        {renderGreentwoBarService()}
        {renderGreen7BarService()}
        {renderContactService()}
        {renderAviiService()}
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
