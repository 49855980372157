import React from "react";
import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";

const MemberRegistration = () => {
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        {/* Actual Home page starts */}
        <Header />

        {/*Start of  how to use section */}
        <div className="flt">
          <div className="container">
            <div className="memreg_container">
              <div className="memreg_bar">会員登録</div>
              <div className="memreg_para_cont">
                <div className="memreg_para">
                  サンプルテキスト サンプルテキスト サンプルテキスト
                  サンプルテキスト サンプルテキスト サンプルテキスト
                  サンプルテキスト サンプルテキスト サンプルテキスト
                  サンプルテキスト サンプルテキスト サンプルテキスト
                  サンプルテキスト サンプルテキスト サンプルテキスト
                  サンプルテキスト サンプルテキスト サンプルテキスト
                  サンプルテキスト{" "}
                </div>
              </div>{" "}
              <div className="algn_centr">
                <button className="memreg_btn">
                  {" "}
                  <div className="fltlft">TOPに戻る</div>
                  <div className="fltrgt">
                    <i class="fas fa-chevron-right"></i>
                  </div>
                </button>{" "}
              </div>
            </div>
          </div>
        </div>

        {/*End of  how to usesection */}

        <Footer />
      </div>
      {/* Actual Home page Ends */}
    </div>
  );
};

export default MemberRegistration;
