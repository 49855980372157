import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userActions } from "../../../_actions";
import forgotpasswordServices from "../../../_services/forgotpassword.services";
import message from "../../_common/Message/message";
import { confirmAlert } from "react-confirm-alert";
import { alertActions } from "../../../_actions/alert.actions";
const useForgotPasswordHooks = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const ranid = queryParams.get("id");
    let random = ranid;
    const [passwordValid, setpasswordValid] = useState(true);
    localStorage.setItem("random3", random);
    var randmid = localStorage.getItem("random3");
    const [changepwd, setChangepwd] = useState({
        password: "",
        re_password: "",
        random: "",
    });

    useEffect(() => {
        localStorage.removeItem(`${process.env.REACT_APP_PROJECT_NAME}` + "_access_token");
    }, []);

    const [login, setLogin] = useState({
        user_id: "",
        password: "",
    });

    const [submitted, setSubmitted] = useState(false);
    const { user_id, password } = login;
    const [passwordCheck, setPasswordCheck] = useState(true);

    const loggingIn = useSelector((state) => state.authentication.loggingIn);
    const dispatch = useDispatch();
    const location = useLocation();

    function changeHandler(e) {
        if (e.target.name == "password") {
            var filter = /^[0-9\b]+$/;
            if (e.target.value.length < 6) {
                setpasswordValid(false);
            } else {
                setpasswordValid(true);
            }
        }

        if (e.target.name == "re_password") {
            if (changepwd.password === e.target.value) {
                setPasswordCheck(true);
            } else {
                setPasswordCheck(false);
            }
        }

        const { name, value } = e.target;

        setChangepwd((changepwd) => ({ ...changepwd, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        console.log("hiiiiiiiiiiiiiiiii11");

        if (user_id && password) {
            console.log("hiiiiiiiiiiiiiiiii");
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/home" } };
            dispatch(userActions.login(user_id, password, from));
        }
    }

    function ResetPasswordButtonClickHandler(e) {
        setSubmitted(true);
        e.preventDefault();

        if (changepwd.password && changepwd.re_password && passwordValid) {
            confirmAlert({
                title: "送信確認を行う",
                message: "本当にパスワードを変更しますか？",
                buttons: [
                    {
                        label: "はい",
                        onClick: () => {
                            forgotpasswordServices.changepwd(changepwd).then((data) => {
                                if (data.status.code === 1) {
                                    console.log("Link expired")
                                    dispatch(alertActions.error("Link Expired"));
                                    setTimeout(() => dispatch(alertActions.clear()), 4000);
                                }
                                else {
                                    setChangepwd({
                                        password: "",
                                        re_password: "",
                                    });
                                    setSubmitted(false);
                                    dispatch(alertActions.success(message.forgotpassword.success));
                                    setTimeout(() => dispatch(alertActions.clear()), 4000);
                                }

                            });
                        },
                    },
                    {
                        label: "いいえ",
                        onClick: () => null,
                    },
                ],
            });
        }
    }
    return {
        changepwd,
        passwordCheck,
        submitted,
        ResetPasswordButtonClickHandler,
        loggingIn,
        ranid,
        changeHandler,
        passwordValid
    }
}
export default useForgotPasswordHooks;
