const message = {
  login: {
    success: "ログイン完了",
    error: "ユーザーIDかパスワードが間違っています",
  },
  advertisement: {
    add: "Advertisement added successfully",
    update: "Advertisement updated successfully",
    delete: "Advertisement deleted successfully",
    error: "error",
  },
  merchant: {
    add: "Merchant added successfully",
    update: "Merchant updated successfully",
    delete: "Merchant deleted successfully",
    error: "error",
  },
  privacy: {
    success: "プライバシーポリシー更新成功",
    error: "エラー",
  },
  terms: {
    success: "ご利用規約の更新が完了しました",
    error: "エラー",
  },
  contact: {
    add: "連絡先の追加が完了しました",
  },

  updateEmail: {
    success: "メールアドレスの変更が完了しました",
    error: "無効なパスワードが入力されました",
  },

  updateUserId: {
    success: "ユーザーIDの変更が完了しました",
    error: "無効なパスワードが入力されました。",
  },
  forgotpassword: {
    success: "ユーザーIDの変更が完了しました",
    error: "無効なパスワードが入力されました。",
  },

  forgotpassword: {
    success: "パスワードの変更が完了しました",
    error: "無効なパスワードが入力されました",
  },

  mailsent: {
    success: "登録されたメールアドレスにパスワード復旧用のリンクを送信しました",
    error: "ユーザーIDが一致しません",
  },
  register: {
    add: "登録完了",
    error: "ユーザーIDがすでに存在します",
    error1: "メールアドレスがすでに存在します"
  },

  updatepassword: {
    success: "パスワードの更新が完了しました",
    error: "現在のパスワードが間違っています",
  },
  img: {
    add: "画像追加完了",

  },
  comment: {
    imgComment: "変更したい画像の上にコメントを挿入",
    imgComment2: "画像をアップロードしてください"

  },

};
export default message;
