// import { authHeader } from '../_helpers';
import axios from "axios";

const ArticledetailServices = {
  getBlogData,
  getMostViewdData,
  handleResponse,
  getFeaturedData,
  getAdvertisementData
};


async function getAdvertisementData() {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/advertisement`,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}


async function getBlogData(id) {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/blogSingle/${id}`,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}

async function getMostViewdData() {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/blogview`,
    // headers: authHeader()
  };
  return await axios(requestOptions).then(handleResponse);
}

async function getFeaturedData() {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/blogfeatured`,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}

function handleResponse(response) {

  if (!response.statusText) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      //window.location.reload(true);
    }
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data;
}

export default ArticledetailServices;
