import { Component } from 'react';

export default class extends Component {
  state = {
    value: this.props.defaultValue || '',
    editable: true,
  };
  componentDidMount() {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    if (window.location.href.includes('cp/')) {

      this.setState({ editable: false })


    } else {

      this.setState({ editable: true })
    }
  }

  // const dispatch = useDispatch();
  onKeyPress = event => {
    if (event.key === 'Enter') {

      event.preventDefault();
      const { value } = this.state;
      if (value && value.length > 0 && this.props.onSearch) {
        this.props.onSearch(value);
        return false;
      } else {
        return false;
      }
    }
  };



  onChange = event => {
    event.preventDefault();
    const value = event.target.value;
    if (value === ' ') {
      return false;
    }
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }







  };
  render() {
    return (
      this.state.editable ? (
        <input
          className="simpleInput"
          style={this.props.style}
          onKeyPress={this.onKeyPress}
          onChange={this.onChange}
          value={this.state.value}
          placeholder={this.props.placeholder || ''}
          autoFocus={this.props.autoFocus || false}
        />
      ) : ""

    );
  }
}