import axios from 'axios'
import { authHeader } from '../_helpers';

export const registrationService = {
    add,
    handleResponse,
    check_user,
    check_email,
    getState

};






async function add(add) {
    let phone_no = add.mbl1 + '' + add.mbl2 + '' + add.mbl3;
    var bodyFormData = new FormData();
    bodyFormData.append("name", add.name)
    bodyFormData.append("name_double", add.name_double)
    bodyFormData.append("password", add.password)
    bodyFormData.append("mobile_no", phone_no)
    bodyFormData.append("address", add.address)
    bodyFormData.append("email", add.email)
    bodyFormData.append("postal_code", add.postal_code)
    bodyFormData.append("prefectures", add.prefectures)
    bodyFormData.append("city", add.city)
    bodyFormData.append("country", add.country)
    bodyFormData.append("user_id", add.user_id)
    bodyFormData.append("re_password", add.re_password)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}users/registration`,
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}

async function check_user(user_id) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", user_id)

    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}users/userCheck`,
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);

}
async function getState() {
    //let pno = 2;
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}users/getState`,
        headers: authHeader()
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}

async function check_email(email) {
    var bodyFormData = new FormData();
    bodyFormData.append("email", email)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}users/userCheck`,
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);

}






function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}

