import { Component } from 'react';
import { connect } from 'react-redux';
import './img.css';
import BlankCenterDiv from '../component/pages/ShirImageTag/blankCenterDiv';
import ImageHeader from '../component/pages/ShirImageTag/imageHeader';
import ImagePreview from '../component/pages/ShirImageTag/imagePreview';
import actions from '../_actions/pointer.actions';
import styles from '../styles';

const {
  setDimensions,
  editImage,
  deleteImage,
  setNewPoint,
  setSelectedPoint
} = actions;
const style = styles.SelectedImage;

class SelectedImage extends Component {
  render() {
    const {
      dimensions,
      selectedImage,
      notSelected,
      points,
      selectedPoint,
      newPoint,
      showPoints,
      setDimensions,
      editImage,
      deleteImage,
      setNewPoint,
      setSelectedPoint,
    } = this.props;

    if (notSelected) {
      return <BlankCenterDiv text={notSelected} />;
    }

    const imagePreViewProps = {
      dimensions,
      selectedImage,
      points,
      selectedPoint,
      newPoint,
      showPoints,
      setDimensions,
      setNewPoint,
      setSelectedPoint
    };


    return (
      <div
        className="imageheader_main"
        onClick={event => {
          event.stopPropagation();
          setSelectedPoint(null);
        }}
      >
        <div className="image-padding">
          <ImageHeader
            image={selectedImage}
            editImage={editImage}
            deleteImage={deleteImage}
            setSelectedPoint={setSelectedPoint}
          />
          <div style={style.settingsWrapper}>
            {/* <button
              className="simpleButton"
              onClick={() => {
                toggleOptions('showPoints', showPoints);
              }}
            >
              {showPoints ? 'Hide Points' : 'Show Points'}
            </button> */}
            {/* <button
              className="simpleButton"
              onClick={() => {
                toggleOptions('showAllComments', !showAllComments);
              }}
            >
              {showAllComments ? 'Hide Comments' : 'Show Comments'}
            </button> */}
          </div>
          <div className="img_display">
            <div
              style={{
                height: dimensions.height,
                ...style.imageWrapper,
                paddingLeft: '0px'
              }}
            >
              <ImagePreview {...imagePreViewProps} />
            </div>
          </div>
        </div>
        {/* <AllComments /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    dimensions,
    images,
    selectedImage,
    points,
    selectedPoint,
    newPoint,
    showPoints,

  } = state.pointerReducer;
  if (images.length === 0) {
    return {
      notSelected: '新しい画像をアップロードしてください。'
    };
  }
  if (!selectedImage) {
    return {

      notSelected: '写真を選択するか、画像をアップロードしてください。'

    };
  }
  const { id } = selectedImage;
  console.log(id)
  return {
    dimensions,
    selectedImage,
    points: points[id],
    selectedPoint,
    newPoint,
    showPoints,
  };
}
export default connect(mapStateToProps, {
  setDimensions,
  editImage,
  deleteImage,
  setNewPoint,
  setSelectedPoint,
})(SelectedImage);
