import Sidebar from "../UserPanel/Userpanel";
import "./settings.css";
import SettingHook from "./SettingHook";

// import SettingChangesService from "../../../_services/settingchanges.services";

const Settings = () => {
  const {
    showmailform,
    showuseridform,
    showpasswordchangeform,
    passwordCheck,
    useridcheck,
    passwordValid,
    submitted1,
    submitted2,
    submitted3,
    emailValid,
    useridexist,
    update1,
    update2,
    update3,
    onChangeHandler,
    MailChangeHandler,
    PasswordChangeHandler,
    UserIDChangeHandler,
    idUpdateSubmitHandler,
    emailUpdateSubmitHandler,
    passwordUpdateSubmitHandler,
  } = SettingHook();
  //settings page button
  const renderThreeButtonsSettings = () => {
    return (
      <div className="three_buttons">
        <button onClick={MailChangeHandler} className="btn_css">
          メールアドレスの変更
        </button>
        <button onClick={UserIDChangeHandler} className="btn_css">
          ユーザーIDの変更
        </button>
        <button onClick={PasswordChangeHandler} className="btn_css">
          パスワードの変更
        </button>
      </div>
    );
  };
  //change mailID form
  const renderChangeMailIDForm = () => {
    return (
      <div>
        {showmailform && (
          <form autoComplete="off">
            <div className="settings_items ">
              <div className="setting_heading">メールアドレスの変更</div>
              <div className="all3_pwd1">
                <lable className="label_css">現在のパスワード:</lable>
                <br />
                <input
                  type="password"
                  name="password1"
                  onChange={onChangeHandler}
                  value={update1 && update1.password1}
                  className="input_css"
                />
                {submitted1 && update1 && !update1.password1 && (
                  <div className="invalid-feedback">
                    現在のパスワードが必要です
                  </div>
                )}
              </div>
              {/* <br /> */}
              <div className="all3_email1">
                <lable className="label_css">新しいメールアドレス:</lable>
                <br />
                <input
                  name="email"
                  onChange={onChangeHandler}
                  value={update1 && update1.email}
                  className="input_css"
                />{" "}
                {!emailValid && update1 && update1.email && (
                  <div className="invalid-feedback">
                    メールアドレスが間違っています
                  </div>
                )}
                {submitted1 && update1 && !update1.email && (
                  <div className="invalid-feedback">
                    新しいメールアドレスは必要です
                  </div>
                )}
              </div>
              {/* <br /> */}
              <button className="submit_btn" onClick={emailUpdateSubmitHandler}>
                更新する
              </button>
            </div>
          </form>
        )}
      </div>
    );
  };
  //change user ID design
  const renderUserIDChangeForm = () => {
    return (
      <div>
        {showuseridform && (
          <form autoComplete="off">
            <div className="settings_items ">
              <div className="setting_heading">ユーザーIDの変更</div>
              <div className="all3_userid1">
                <lable className="label_css">現在のパスワード:</lable>
                <br />
                <input
                  type="password"
                  name="password2"
                  onChange={onChangeHandler}
                  value={update2 && update2.password2}
                  className="input_css"
                />
                {submitted2 && update2 && !update2.password2 && (
                  <div className="invalid-feedback">
                    現在のパスワードが必要です
                  </div>
                )}
                {/* <br /> */}
              </div>
              <div className="all3_userid1">
                <lable onChange={onChangeHandler} className="label_css">
                  新しいユーザーIDを入力:
                </lable>
                <br />
                <input
                  name="user_id"
                  onChange={onChangeHandler}
                  value={update2 && update2.user_id}
                  className="input_css"
                />
                {submitted2 && update2 && !update2.user_id && (
                  <div className="invalid-feedback">ユーザーIDは無効です</div>
                )}

                {useridexist && update2 && update2.user_id && (
                  <div className="invalid-feedback">User_ID already exist</div>
                )}
                {/* <br /> */}
              </div>
              <div className="all3_userid2">
                <lable className="label_css">新しいユーザーIDを再入力:</lable>
                <br />
                <input
                  onChange={onChangeHandler}
                  name="re_userid"
                  className="input_css"
                  value={update2 && update2.re_userid}
                />
                {submitted2 && update2 && !update2.re_userid && (
                  <div className="invalid-feedback">
                    ユーザーIDの再入力が必要です
                  </div>
                )}
                <br />
                {!useridcheck && update2 && update2.re_userid && (
                  <div className="invalid-feedback">
                    ユーザーIDが一致する必要があります
                  </div>
                )}
              </div>
              <button className="submit_btn1" onClick={idUpdateSubmitHandler}>
                更新する
              </button>
            </div>
          </form>
        )}
      </div>
    );
  };
  //change password change
  const renderPasswordChangeForm = () => {
    return (
      <div>
        {showpasswordchangeform && (
          <form autoComplete="off">
            <div className="settings_items ">
              <div className="setting_heading">パスワードを変更</div>
              <div className="all3_pwd1">
                <lable className="label_css">現在のパスワード:</lable>
                <br />
                <input
                  type="password"
                  name="password"
                  onChange={onChangeHandler}
                  value={update3 && update3.password}
                  className="input_css"
                />
                {submitted3 && update3 && !update3.password && (
                  <div className="invalid-feedback">パスワードは必須です</div>
                )}
              </div>
              {/* <br /> */}
              <div className="all3_pwd1">
                <lable className="label_css">新しいパスワード:</lable>
                <br />
                <input
                  type="password"
                  name="new_password"
                  onChange={onChangeHandler}
                  value={update3 && update3.new_password}
                  className="input_css"
                />
                {submitted3 && update3 && !update3.new_password && (
                  <div className="invalid-feedback">
                    新しいパスワードが必要です
                  </div>
                )}
                {!passwordValid && update3 && update3.new_password && (
                  <div className="invalid-feedback">
                    パスワードは最低6文字以上でなければなりません。
                  </div>
                )}
              </div>

              <div className="all3_pwd1">
                <lable className="label_css">新しいパスワードの再入力:</lable>
                <br />
                <input
                  type="password"
                  value={update3 && update3.re_password}
                  name="re_password"
                  onChange={onChangeHandler}
                  className="input_css"
                />
                {submitted3 &&
                  update3 &&
                  !update3.new_password &&
                  passwordCheck && (
                    <div className="invalid-feedback">
                      新しいパスワードを再入力
                    </div>
                  )}

                {!passwordCheck && (
                  <div className="invalid-feedback">
                    パスワードは一致しなければなりません
                  </div>
                )}
              </div>
              {/* <br /> */}
              <button
                className="submit_btn"
                onClick={passwordUpdateSubmitHandler}
              >
                更新する
              </button>
            </div>
          </form>
        )}
      </div>
    );
  };
  return (
    <div className="user_container">
      {/* <div> </div> */}
      <Sidebar />
      <div className="maincontainer_settings flts">
        <div className="outermost_container flts">
          <div className="all_contnr">
            {renderThreeButtonsSettings()}
            <div className="settings_main_container ">
              {renderChangeMailIDForm()}
              {renderUserIDChangeForm()}
              {renderPasswordChangeForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
