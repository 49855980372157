import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import { Strings, Colors, Images, Fonts } from "../../../res/strings";
import React, { useState, useEffect } from "react";
import img from "../../../assets/images/331.png";
import img3 from "../../../assets/images/441.png";
import img4 from "../../../assets/images/442.png";
import ind_serve from "../../../assets/images/ind_serv.png";
import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";
import mark_logo1 from "../../../assets/images/HP.png";
import mark_logo2 from "../../../assets/images/appi.png";
import mark_logo3 from "../../../assets/images/sys.png";
import price from "../../../assets/images/pricing.png";
import "./Service.css";
function Service() {
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // heading display
  const renderGreenBarService = () => {
    return (
      <div className="service_bar flt">
        <img className="img_length" src={img}></img>
        <div className="service_content">サービス内容</div>
      </div>
    );
  };

  //different button display
  const renderGreenButtonsService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="box_container">
          <div className="mark_head flt">サービス</div>
          <div className="os_content">
            印コーディングサービスでは、デザインを入稿するだけで
            <br />
            ホームページ制作から複雑なシステム開発まで、WEB制作における
            <br />
            幅広いソリューションを、驚きのコストでご提供しております。
            <br />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <img src={mark_logo1} alt="image" className="service-img" />
            <div className="os_content1">
              コーポレートサイト、ランディングページ、リクルートサイト、ECサイトなど、
              デザインを入稿するだけで、さまざまなホームページ制作mp対応が可能です。
              レスポンシブ対応はもちろん、アニメーションやCMS導入まで、理想のページを
              驚きの価格で作ることができます。
            </div>
          </div>
          <div className="col-sm-4">
            <img src={mark_logo2} alt="image" className="service-img" />
            <div className="os_content1">
              SNSアプリ、業務アプリ、ゲームアプリ、WEBアプリなど、複雑なシステム機能を搭載した、
              アプリケーション開発に対応いたします。仕様書を入稿するだけで、
              日本ではありえない価格でアプリケーションがが完成いたします。
            </div>
          </div>
          <div className="col-sm-4">
            <img src={mark_logo3} alt="image" className="service-img" />
            <div className="os_content1">
              企業に合わせたオープン系システムや、Web系システムなどに対応しており、
              ご希望の内容に沿った、さまざまなプログラミング言語を採用しております。
              御社独自のシステムをデータ入稿だけで完成いたします。
            </div>
          </div>
        </div>

        <img src={price} className="price-img" alt="image" />
        <div className="price-img">
          <div className="point_cont1">
            ※ ・　トップページを含む7P想定。 <br />
            ・アニメーション（フェードイン） <br />
            ・レスポンシブ対応。 <br />
            ・ワードプレス挿入し、お知らせ・ブログは更新時、トップページへ自動表示。
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <a href="#1">
              <button className="serv_three_btn ">
                <div className="fltlft">費用</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>{" "}
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#2">
              <button className="serv_three_btn ">
                <div className="fltlft">納期</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#3">
              <button className="serv_three_btn ">
                {" "}
                <div className="fltlft">オプション作業</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>
            </a>
          </div>
          <br />
          <br />

          <div className="col-sm-4">
            <a href="#4">
              <button className="serv_three_btn ">
                {" "}
                <div className="fltlft">修正について</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#5">
              <button className="serv_three_btn ">
                {" "}
                <div className="fltlft">納品データの確認</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  };
  //the text for respective button
  const renderTextContentsService = () => {
    return (
      <div className="serv_para_cont">
        <div id="">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="serv_texthead">費用 </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className=" serv_india1" id="1">
          <img src={ind_serve} className="serv_india"></img>
        </div>
        <div id="">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="serv_texthead">目安費用 </div>
        </div>{" "}
        <hr className="new4" /> <div className=" " id="1"></div>
        <div className="main_subcontent_service serv_para">
          <br />
          インドルピーと日本円レートによって変動します
          <br />
          <br />
          ＞ CMS導入　　　　　　　￥22,400円/1サイトにつき
          <br />
          ＞ お問い合わせ　　¥10,640/1ヵ所
          <br />
          ＞ ハンバーガーメニュー　　　￥11,000
          <br />
          ＞ サイトマニュアル作成　￥20,000円〜 <br />
          ＞ オペレーション費用 ¥20,000
          <br />
          ＞ ＷＥＢシステム作成　　 ¥350,000円〜
          <br />＞{" "}
          <a href="https://recursive-design.com/price"> オプション各種</a>{" "}
          <br />
          <br />
          {/* <u>SEO対策運用はこちら</u>
          <br /> <u>システム設計は問い合わせ確認</u>
          <br /> */}
          <br />
        </div>
        <div id="">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="serv_texthead">優待 </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className=" serv_para" id="1">
          <br />
          定期案件を受けることは弊社としてもありがたく嬉しいことであります。{" "}
          <br />
          インド支部での優秀のエンジニア契約を増員し柔軟に対応することが可能となることから費用を少しでも抑えれるようにご
          <br />
          協力させていただきます。
          <br />
          <br />
          定期案件、一定量のボリューム、一定期間による契約等がある場合はお気軽にお問い合わせください。
          <br />
          <br />
        </div>
        <div id="">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="serv_texthead">なぜこの価格が実現できるのか？ </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className=" serv_para" id="">
          <br />
          私たちAVII
          IMAGEWORKSは日本人エンジニアの思考、センス超える優秀なインドのエンジニアを仲間に迎えインド支部
          <br />
          にてコーディング、開発進行をおこなっております。
          <br />
          <br />
          物価の違いから日本でのコーディング費用の目安とは比べ物にならない金額でコーディング、開発が実現しています。
          <br />
          AVII
          IMAGEWORKSだからこそインドとの掛橋となるUI、UXデザイン技術と進行力、言語対応力から実現しております。
          <br />
          私たちは台湾やベトナム、フィリピンなどへのアウトソーシングではなく自社でインド支社を持っている事が他社との一番大き
          <br />
          な違いです。
          <br />
          <br />
          インドでは自国の言葉はもちろん、英語は基本的に会話できるスタッフがほとんどであり、また開発言語も日本人と比べ物
          <br />
          にならないほどの多くの言語を柔軟に対応できる技術力をもっています。弊社ではそんなインドに支社を設けコーディング、
          <br />
          システム開発をインドの優秀なエンジニアで取り組んでおります。
          <br />
          <br />
          この価格が実現できるのはもちろん物価に違いです。
          <br />
          同等の優秀なスタッフが日本では1人50万円以上する給料の約5分に1で依頼することができます。ニュアンスや言語の違
          <br />
          いはあるので、弊社では日本語対応が可能なインドスタッフとしっかり連携をとりデザイン、設計は日本で日本仕様で行う
          <br />
          ことから日本で開発したものと何ら変わらないものが完成します。しかもインドスタッフは優秀であることから開発速度も日
          <br />
          本の2倍くらいのスピードで完成させることができます。
          <br />
          <br />
          インドの支社とインドの開発会社がつながっていることから過剰な案件が出た際も問題なく対応できるようになっておりま
          <br />
          す。
          <br />
          <br />
        </div>
        <div id="deliverydate">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="serv_texthead">納期 </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="serv_para" id="2">
          <div className="main_content_div">
            <img src={img3}></img>
            <br />
            <br />
            <br />
            <div className="main_subcontent_service">
              8 時間 30
              名での作業体制、および効率的な生産管理システムの活用により、
              <br />
              世界最速クラスの納品スピード、および納期履行率を実現しております。お急ぎの場合、納期のご相談も承っておりますの
              <br />
              で、お気軽にお申付け下さい。<br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
        <div id="optionalwork">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="serv_texthead">オプション作業 </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="serv_para" id="3">
          <div className="serv_para">
            <div className="main_content_div">
              <div className="main_heading_div bg_clr">
                &nbsp;&nbsp;アニメーション&nbsp;&nbsp;
              </div>
              <div className="main_subcontent_service">
                WEBサイトのアニメーションオプションについてです。
                <br />
                <br />
                LPやオフィシャルサイトなど全てのサイトにアニメーションをつけることが可能です。
                <br />
                アニメーションがあるサイトは今となれば当たり前となっています。{" "}
                <br />
                どの部分にそのようなアニメーションをつけるかをご指定いただくのですが、弊社では指定のサイトより動きを選んでいただきデ
                <br />
                ザインデータで指定いただければ指定通りのアニメーションを高築させていただきます。
                {/* <br /><br /> */}
                {/* <span className="clr_red"> アニメーション種類サイト掲載</span> */}
                <br />
                <br />
                パララックスなどサイト掲載のないものなどご相談いただければ対応可能となっておりますのでお気軽にお問い合わせくださ
                <br />
                い。
                <br />
                できる限る柔軟に対応させていただきます。
                <br />
                <br />
              </div>

              <div className="main_heading_div"></div>
              <div className="main_subcontent_service">
                <span className="main_heading_div bg_clr">
                  &nbsp;&nbsp;レスポンシブ&nbsp;&nbsp;
                </span>{" "}
                <br />
                &nbsp; 対応オプションについて解説いたします。
                <br />
                基本的に現代ではスマートフォンの普及によりサイトやシステムサイトはスマートフォンで観覧されることを基本に考えて高築
                <br />
                しております。
                <br />
                スマートフォン用、タブレット用のレスポンシブ後の配置デザインをいただければ基本料金となるお見積もりの金額で行わせ
                <br />
                ていただきます。
                <br />
                <br />
                レスポンシブ対応のデザインをいただけない場合は対応はできかねますので必ずデザインデータのご用意も宜しくお願いいた
                <br />
                します。
                <br></br>
                <br></br>
                <br></br>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div id="optionalwork">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="serv_texthead">修正について </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="serv_para" id="4">
          <div className="serv_para">
            <div className="main_content_div">
              <div className="main_heading_div bg_clr">
                &nbsp;&nbsp;修正方法&nbsp;&nbsp;
              </div>
              <div className="main_subcontent_service">
                ご入稿後、日本人スタッフと打ち合わせを行い初稿日程をご連絡いたします。修正指示は弊社独自の修正ツールまた
                <br />
                はチャットを使用し、修正箇所の指示を行ってください。原則修正は2回までとし、それ以降の修正に関しては、別途費用
                <br />
                が発生いたします。
                <br />
                <br />
                ※修正の際は、修正箇所のスクリーンショットを撮ってください。修正ツールに必要になります。
              </div>
            </div>

            <div className="main_content_div">
              <div className="main_heading_div bg_clr">
                &nbsp;&nbsp;修正内容について&nbsp;&nbsp;
              </div>
              <div className="main_subcontent_service">
                入稿いただいております。デザインにしたがって制作を行います。
                <br />
                デザインの修正はここでは受け付けておりません。もし制作進行後のデザイン修正は別途費用発生となります。
                <br />
                <br />
                修正はデザイン通りではない部分の修正となります。
                <br />
                またアニメーション加減等の修正となります。
              </div>
            </div>

            <div className="main_content_div">
              <div className="main_subcontent_service">
                <span className="main_heading_div bg_clr">
                  &nbsp;&nbsp;修正回数&nbsp;&nbsp;&nbsp;
                </span>
                <br />
                は2回まで行うことが可能です。
                <br />
                入稿→制作→初稿→修正→再稿→修正→納品
              </div>
            </div>

            <div className="main_content_div">
              <div className="main_subcontent_service">
                <span className="main_heading_div bg_clr">
                  &nbsp;&nbsp;納品後の修正&nbsp;&nbsp;&nbsp;
                </span>
                <br /> は別途費用の発生となってしまいます。
                <br />
                また修正が2回で治らなかった場合も別途費用発生となります。
              </div>
            </div>

            {/* <div className="main_content_div">
              <div className="main_heading_div bg_clr">&nbsp;&nbsp;独自開発修正ツール&nbsp;&nbsp;</div>

            </div> */}

            {/* <div className="main_content_div">
              <div className="main_heading_div bg_clr">&nbsp;&nbsp;修正ツール使い方&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div className="main_subcontent_service">

                <br />
                <br />

                <br></br>
              </div>
            </div> */}
          </div>
        </div>
        <div id="deliverydate">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="serv_texthead">納品データの確認について </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="serv_para" id="5">
          <div className="main_content_div">
            <img src={img4}></img>
            <br />
            <br />
            <div className="main_subcontent_service">
              納品データの確認方法＞
              <br />
              ご入稿から修正対応を経て、納品データをお送りいたします。
              <br />
              <br />
              １、納品データの確認については、 <br />
              仮サーバーにアップしたものを確認し、問題ないかご確認いただけます。{" "}
              <br />
              <br />
              ２、納品後ドメイン、サーバー設定、アップロードまでサポートさせていただきます
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //button display
  const renderMixGreenButtonPDFService = () => {
    return (
      <div className="algn_centr">
        <button
          className="serv_btn"
          onClick={() => history.push("/Registration")}
        >
          <div className="fltlft">
            会員登録なしでPDFで
            <br />
            無料見積もりしてみる
          </div>
          <div className="fltrgttt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        {/* Actual Home page starts */}
        <Header />

        {/*Start of  FAQ section */}
        <div className="flt">
          {renderGreenBarService()}
          <div className="container">
            <div className="serv_container">
              {renderGreenButtonsService()}
              {renderTextContentsService()}
              {/* {renderTextContentsServices()} */}
              {/* {renderMixGreenButtonPDFService()} */}
            </div>
          </div>
        </div>
        {/*End of  FAQ section */}

        <Footer />
      </div>
      {/* Actual Home page Ends */}
    </div>
  );
}

export default Service;
