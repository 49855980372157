import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import useTermsHooks from "./TermsHooks";

function Terms() {
  const { data } = useTermsHooks();

  const renderGreenBarTerms = () => {
    return (
      <div className="rectangleT flt">
        <span className="fontclrT">TERMS & CONDITION</span>
      </div>
    );
  };

  const renderTextContentTerms = () => {
    return (
      <div className="flt paddingTerm">
        {/*to remove html element in the content */}
        <p dangerouslySetInnerHTML={{ __html: data.content }}></p>
      </div>
    );
  };

  return (
    <div className="flt">
      <Header />
      <div className="container">
        <div className="main-containerT flt">
          {renderGreenBarTerms()}
          {renderTextContentTerms()}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;