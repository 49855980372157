import "./Registration.css";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import useRegistrationHooks from "./RegistrationHooks";

function Registration() {
  const {
    submitted,
    postalcodeValid,
    postalcodelengthValid,
    emailValid,
    numberValid,
    numberValid1,
    numberValid2,
    passwordCheck,
    passwordValid,
    preview,
    current,
    checked,
    add,
    mbl,
    button_disable,
    changeHandler,
    edit,
    spaceHandle,
    handleSubmit,
    handle,
  } = useRegistrationHooks();

  const renderGreenBarReg = () => {
    return (
      <div className="rectangleReg flt">
        <span className="fontclrReg">新規登録</span>
      </div>
    );
  };

  const renderContentUnderRegBar = () => {
    return (
      <div className="info flt">
        <p>
          以下の情報をご入力のうえ、「入力内容を確認する」ボタンを押してください。
          <br />
          必須と表記してある項目、並びに薄く色づけしてある項目は全て必須項目となります。
        </p>
      </div>
    );
  };

  const renderMemberInfoText = () => {
    return (
      <div className="member-info flt">
        <span className="member">会員情報</span>
        <br />
        お名前や住所、お電話番号を登録してください。
      </div>
    );
  };

  const renderMemberRegistrationForm = () => {
    return (
      <div className="contact-form flt">
        <div className="contact-group flt">
          <div className="row space">
            <div className="col-sm-4 registration_box1">
              <label>
                お名前
                <span>必須</span>
              </label>
            </div>
            <div className="col-sm-8 registration_space">
              <input
                type="text"
                name="name"
                value={add && add.name}
                onChange={changeHandler}
                className={submitted && add && !add.name ? " is-invalid" : ""}
              />
              {submitted &&
                add &&
                add.name &&
                add &&
                add.name.trim().length === 0 && (
                  <div className="invalid-feedback">This field is invalid</div>
                )}
              {submitted && add && !add.name && (
                <div className="invalid-feedback">名前が必要です</div>
              )}
            </div>
          </div>
          {/*name*/}
          <div className="row space">
            <div className="col-sm-4 registration_box">
              <label>
                お名前(全角カナ)
                <span>必須</span>
              </label>
            </div>
            <div className="col-sm-8 registration_space">
              <input
                type="text"
                name="name_double"
                value={add && add.name_double}
                onChange={changeHandler}
                className={
                  submitted && add && !add.name_double ? " is-invalid" : ""
                }
              />
              {submitted &&
                add &&
                add.name_double &&
                add &&
                add.name_double.trim().length === 0 && (
                  <div className="invalid-feedback">This field is invalid</div>
                )}
              {submitted && add && !add.name_double && (
                <div className="invalid-feedback">会社名の入力が必要です</div>
              )}
            </div>
          </div>

          <div className="row space">
            <div className="col-sm-4 registration_box"></div>
            <div className="col-sm-8 registration_space">
              <div className="row">
                <div className="col-sm-2 lblspace">
                  <label>郵便番号</label>
                </div>
                <div className="col-sm-10">
                  <input
                    type="tel"
                    name="postal_code"
                    value={add && add.postal_code}
                    onChange={changeHandler}
                    maxLength="7"
                    minLength="7"
                    className={
                      postalcodeValid && submitted && add && !add.postal_code
                        ? " is-invalid"
                        : ""
                    }
                  />
                  {!postalcodeValid && add && add.postal_code && (
                    <div className="invalid-feedback">
                      郵便番号の入力が必要です
                    </div>
                  )}
                  {submitted && add && !add.postal_code && (
                    <div className="invalid-feedback">
                      郵便番号の入力が必要です
                    </div>
                  )}
                  {!postalcodelengthValid &&
                    add &&
                    add.postal_code &&
                    postalcodeValid && (
                      <div className="invalid-feedback">
                        郵便番号は７桁で入力してください
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="row space">
            <div className="col-sm-4 registration_box">
              <label>
                住所
                <span>必須</span>
              </label>
            </div>
            <div className="col-sm-8 registration_space">
              <div className="row">
                <div className="col-sm-2 lblspace">
                  <label>都道府県</label>
                </div>
                <div className="col-sm-10">
                  <select
                    onChange={changeHandler}
                    name="prefectures"
                    className={
                      submitted && add && !add.prefectures ? " is-invalid" : ""
                    }
                    required
                  >
                    {current &&
                      current.data &&
                      current.data &&
                      current.data.map((val, i) => {
                        return (
                          <option className="state_option" value={val.state}>
                            {val.state}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row space">
            <div className="col-sm-4 registration_box"></div>
            <div className="col-sm-8 registration_space">
              <div className="row">
                <div className="col-sm-2 lblspace">
                  <label>市区町村</label>
                </div>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="country"
                    value={add && add.country}
                    onChange={changeHandler}
                    className={
                      submitted && add && !add.country ? " is-invalid" : ""
                    }
                  />
                  {submitted &&
                    add &&
                    add.country &&
                    add &&
                    add.country.trim().length === 0 && (
                      <div className="invalid-feedback">
                        This field is invalid
                      </div>
                    )}
                  {submitted && add && !add.country && (
                    <div className="invalid-feedback">
                      住所（国）の入力が必要です
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row space">
            <div className="col-sm-4 registration_box"></div>
            <div className="col-sm-8 registration_space">
              <div className="row">
                <div className="col-sm-2 lblspace">
                  <label>番地・建物名</label>
                </div>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="city"
                    value={add && add.city}
                    onChange={changeHandler}
                    className={
                      submitted && add && !add.city ? " is-invalid" : ""
                    }
                  />
                  {submitted &&
                    add &&
                    add.city &&
                    add &&
                    add.city.trim().length === 0 && (
                      <div className="invalid-feedback">
                        This field is invalid
                      </div>
                    )}
                  {submitted && add && !add.city && (
                    <div className="invalid-feedback">
                      住所（市）の入力が必要です
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 registration_box2">
              <label>
                お電話番号
                <span>必須</span>
              </label>
            </div>
            <div className="col-sm-8 registration_space">
              <div className="flex">
                <div className="">
                  <input
                    type="text"
                    name="mbl1"
                    value={mbl && mbl.mbl1}
                    onChange={changeHandler}
                    className={
                      numberValid && submitted && mbl && !mbl.mbl1
                        ? " is-invalid"
                        : ""
                    }
                    maxLength="3"
                    required
                  />
                  {!numberValid && mbl && mbl.mbl1 && (
                    <div className="invalid-feedback">
                      電話番号が間違っています
                    </div>
                  )}
                  {submitted && mbl && !mbl.mbl1 && (
                    <div className="invalid-feedback">
                      電話番号の入力が必要です
                    </div>
                  )}
                </div>
                <div className="contact_line">
                  <p> ___</p>
                </div>

                <div className="">
                  <input
                    type="text"
                    name="mbl2"
                    value={mbl && mbl.mbl2}
                    onChange={changeHandler}
                    className={
                      numberValid1 && submitted && mbl && !mbl.mbl2
                        ? " is-invalid"
                        : ""
                    }
                    maxLength="4"
                    required
                  />
                  {!numberValid1 && mbl && mbl.mbl2 && (
                    <div className="invalid-feedback">
                      電話番号が間違っています
                    </div>
                  )}
                  {submitted && mbl && !mbl.mbl2 && (
                    <div className="invalid-feedback">
                      電話番号の入力が必要です
                    </div>
                  )}
                </div>
                <div className="contact_line">
                  <p> ___</p>
                </div>
                <div className="">
                  <input
                    type="text"
                    name="mbl3"
                    value={mbl && mbl.mbl3}
                    onChange={changeHandler}
                    className={
                      numberValid2 && submitted && mbl && !mbl.mbl3
                        ? " is-invalid"
                        : ""
                    }
                    maxLength="4"
                    required
                  />
                  {!numberValid2 && mbl && mbl.mbl3 && (
                    <div className="invalid-feedback">
                      電話番号が間違っています
                    </div>
                  )}
                  {submitted && mbl && !mbl.mbl3 && (
                    <div className="invalid-feedback">
                      電話番号の入力が必要です
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAccInfoHeading = () => {
    return (
      <div className="member-information flt">
        <span className="member"> アカウント情報</span>
        <br />
        ユーザー情報を登録してください。ログインする際に必要となりますので、お忘れのないよう控えをお取りください。
      </div>
    );
  };

  const renderAccInfoForm = () => {
    return (
      <div className="contact-form flt">
        <div className="contact-group flt">
          <div className="row">
            <div className="col-sm-4 registration_box1">
              <label>
                Eメール
                <span>必須</span>
                <br />
                <p className="alpha"> 半角英数字</p>
              </label>
            </div>
            <div className="col-sm-8 registration_space">
              <input
                type="text"
                name="email"
                value={add.email}
                onChange={changeHandler}
                className={
                  emailValid && submitted && add && !add.email
                    ? " is-invalid"
                    : ""
                }
              />
              {submitted && add && !add.email && (
                <div className="invalid-feedback">
                  メールアドレスの入力が必要です
                </div>
              )}
              {!emailValid && add && add.email && (
                <div className="invalid-feedback">
                  メールアドレスが間違っています
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 registration_box">
              <label>
                ユーザーID
                <span>必須</span>
                <br />
                <p className="alpha"> 半角英数字</p>
              </label>
            </div>
            <div className="col-sm-8 registration_space">
              <input
                type="text"
                name="user_id"
                value={add && add.user_id}
                onChange={changeHandler}
                className={
                  submitted && add && !add.user_id ? " is-invalid" : ""
                }
              />
              {submitted && add && !add.user_id && (
                <div className="invalid-feedback">ユーザIDが必要です</div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 registration_box">
              <label>
                パスワード
                <span>必須</span>
                <br />
                <p className="alpha"> 半角英数字</p>
              </label>
            </div>
            <div className="col-sm-8 registration_space1">
              <input
                type="password"
                name="password"
                value={add && add.password}
                onKeyDown={spaceHandle}
                onChange={changeHandler}
                className={
                  passwordValid && submitted && add && !add.password
                    ? " is-invalid"
                    : ""
                }
              />
              {!passwordValid && (
                <div className="invalid-feedback">
                  パスワードは最低6文字以上でなければなりません。
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 registration_box"></div>
            <div className="col-sm-8 registration_space">
              <p className="reg_policy">
                ※ご希望のユーザーIDを入力してください <br />
                （4文字以上の半角英数字、ハイフン、アンダースコアもご利用いただけます）
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 registration_box">
              <label>
                パスワード再入力
                <span>必須</span>
                <br />
                <p className="alpha"> 半角英数字</p>
              </label>
            </div>
            <div className="col-sm-8 registration_space">
              <input
                type="password"
                name="re_password"
                value={add && add.re_password}
                onKeyDown={spaceHandle}
                onChange={changeHandler}
                className={
                  passwordCheck && submitted && add && !add.re_password
                    ? " is-invalid"
                    : ""
                }
              />
              {!passwordCheck && (
                <div className="invalid-feedback">
                  パスワードが一致する必要があります
                </div>
              )}
              {/* {!passwordCheck && 
                                  <div className="invalid-feedback">Password must be match </div>
                              } */}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 registration_box2"></div>
            <div className="col-sm-8 registration_space">
              <p className="reg_policy">
                ※ご希望のユーザーIDを入力してください <br />
                （4文字以上の半角英数字、ハイフン、アンダースコアもご利用いただけます）
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPrivacyTextAndSubmitButton = () => {
    return (
      <div className="reg-btn flt">
        <div className="agrmntReg">
          <span className="starReg">※</span>
          <span>
            <a className="clrReg" href="privacy" target="_blank">
              プライバシーポリシー
            </a>
            と
            <a className="clrReg" href="terms" target="_blank">
              利用規約
            </a>
          </span>
          <span className="line3Reg">
            ただき、同意の上ボタンを押して送信してください。
          </span>
          <br />
          <input
            type="checkbox"
            className="checkbox"
            defaultChecked={checked}
            onChange={button_disable}
            className={checked && submitted && add && !add.reason ? " " : ""}
          />
          <label className="checklbl">プライバシーポリシーに同意します。</label>
        </div>

        <div className="bt_registration">
          <button
            className={!checked ? "disable btnsty_register" : "btnsty_register"}
            id="check"
            onClick={handle}
          >
            送信内容を確認する
          </button>
        </div>
      </div>
    );
  };
  const renderRegistrationViewGreenBar = () => {
    return <div className="regcon_bar">登録内容</div>;
  };

  const renderMemberInfoHeadingDisplay = () => {
    return (
      <div className="regcon_head1">
        <div className="regcon_main_head_txt"> 会員情報</div>
        <div className="regcon_sub_head_txt">
          {" "}
          会社名や住所、お電話番号を登録してください。
        </div>{" "}
      </div>
    );
  };

  const renderMemberInfoDisplay = () => {
    return (
      <div>
        <div className="regcon_det_cont">
          {" "}
          <div className="row">
            <div className="col-sm-3">
              <div className="regcon_lbls"> お名前</div>
            </div>
            <div className="col-sm-9 registration_space">
              {" "}
              <div className="regcon_displayed_txt"> {add && add.name}</div>
            </div>
          </div>{" "}
        </div>
        <hr className="new1" />
        <div className="regcon_det_cont">
          {" "}
          <div className="row">
            <div className="col-sm-3">
              <div className="regcon_lbls"> お名前(全角カナ)</div>
            </div>
            <div className="col-sm-9 registration_space">
              {" "}
              <div className="regcon_displayed_txt">
                {" "}
                {add && add.name_double}
              </div>
            </div>
          </div>{" "}
        </div>
        <hr className="new1" />
        <div className="regcon_det_cont">
          {" "}
          <div className="row">
            <div className="col-sm-3 ">
              <div className="regcon_lbls"> 住所</div>
            </div>
            <div className="col-sm-9 registration_space">
              {" "}
              <div className="regcon_displayed_txt">
                {" "}
                {add && add.postal_code}
                <br />
                {add && add.country}
                <br />
                {add && add.city}
                <br />
                {add && add.prefectures}
              </div>
            </div>
          </div>{" "}
        </div>
        <hr className="new1" />
        <div className="regcon_det_cont">
          {" "}
          <div className="row">
            <div className="col-sm-3">
              <div className="regcon_lbls"> お電話番号</div>
            </div>
            <div className="col-sm-9 registration_space">
              {" "}
              <div className="regcon_displayed_txt">
                {" "}
                {mbl && mbl.mbl1}
                {mbl && mbl.mbl2}
                {mbl && mbl.mbl3}
              </div>
            </div>
          </div>{" "}
        </div>
        <br />
      </div>
    );
  };

  const renderAccInfoHeadingDisplay = () => {
    return (
      <div className="regcon_head2">
        <div className="regcon_main_head_txt"> アカウント情報</div>
        <div className="regcon_sub_head_txt">
          {" "}
          ユーザー情報を登録してください。ログインする際に必要となりますので、お忘れのないよう控えをお取りください。
        </div>{" "}
      </div>
    );
  };

  const renderAccDetailsDisplay = () => {
    return (
      <div>
        <div className="regcon_det_cont">
          {" "}
          <div className="row">
            <div className="col-sm-3">
              <div className="regcon_lbls"> Eメール</div>
            </div>
            <div className="col-sm-9 registration_space">
              {" "}
              <div className="regcon_displayed_txt"> {add && add.email}</div>
            </div>
          </div>{" "}
        </div>
        <hr className="new1" />
        <div className="regcon_det_cont">
          {" "}
          <div className="row">
            <div className="col-sm-3">
              <div className="regcon_lbls"> ユーザーID</div>
            </div>
            <div className="col-sm-9 registration_space">
              {" "}
              <div className="regcon_displayed_txt"> {add && add.user_id}</div>
            </div>
          </div>{" "}
        </div>
        <hr className="new1" />
        <div className="regcon_det_cont">
          {" "}
          <div className="row">
            <div className="col-sm-3 ">
              <div className="regcon_lbls"> パスワード</div>
            </div>
            <div className="col-sm-9 registration_space">
              {" "}
              <div className="regcon_displayed_txt"> {add && add.password}</div>
            </div>
          </div>{" "}
        </div>{" "}
        <hr className="new1" />
        <div className="regcon_det_cont">
          {" "}
          <div className="row">
            <div className="col-sm-3">
              <div className="regcon_lbls"> パスワード再入力</div>
            </div>
            <div className="col-sm-9 registration_space">
              {" "}
              <div className="regcon_displayed_txt">
                {" "}
                {add && add.re_password}
              </div>
            </div>
          </div>{" "}
        </div>
        <br />{" "}
      </div>
    );
  };

  const renderFinalRegisterButton = () => {
    return (
      <div className="regcon_flex">
        {" "}
        <button className="regcon_btn">
          {" "}
          <div className="fltlft"></div>
          <div className="fltlft margin_lft" onClick={edit}>
            戻る
          </div>
          <div className="fltrgt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>{" "}
        <button className="regcon_btn1" onClick={handleSubmit}>
          {" "}
          <div className="fltlft margin_lft">上記の内容で送信する</div>
          <div className="fltrgt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>{" "}
      </div>
    );
  };
  return (
    <div className="flt">
      <Header />

      {preview === true && (
        <div className="container" id="target2">
          <div className="main-containerReg flt">
            {renderGreenBarReg()}
            {renderContentUnderRegBar()}
            {renderMemberInfoText()}
            <form autocomplete="off">
              {renderMemberRegistrationForm()}
              {renderAccInfoHeading()}
              {renderAccInfoForm()}
            </form>
            {renderPrivacyTextAndSubmitButton()}
          </div>
        </div>
      )}

      {preview === false && (
        <div className="main_container flt" id="target">
          <div className="landing_container flt">
            <div className="flt">
              <div className="container">
                <div className="regcon_container">
                  {renderRegistrationViewGreenBar()}
                  {/* /////////////////////////////////////////////////////////////////////////////////// */}
                  <div className="regcon_items_container">
                    <form autocomplete="off">
                      {renderMemberInfoHeadingDisplay()}
                      {renderMemberInfoDisplay()}
                      {renderAccInfoHeadingDisplay()}
                      {renderAccDetailsDisplay()}
                      {renderFinalRegisterButton()}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*End of  login contents section */}
          </div>
          {/* Actual login page Ends */}
          <footer />
          {/* <Footer /> */}
        </div>
      )}
      <Footer />
    </div>
  );
}
export default Registration;
