import axios from "axios";
import { authHeader } from "../_helpers";

const forgotpasswordServices = {
  SendMail,
  changepwd,
  handleResponse,
};

async function SendMail(finaluserid2) {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/forgot?user_id=${finaluserid2}`,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}

async function changepwd(changepwd) {
  // var random1 = localStorage.getItem('random1');
  console.log("random1 is" + changepwd.random);
  console.log("Hi" + (JSON.stringify(changepwd)));
  var randmid = localStorage.getItem('random3')
  console.log("inside forgot password api call " + randmid)
  var bodyFormData = new FormData();
  bodyFormData.append("random", randmid);
  bodyFormData.append("password", changepwd.password);

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    url: `${process.env.REACT_APP_BASE_URL}users/reset-password`,
    data: bodyFormData,
  };

  return await axios(requestOptions).then(handleResponse);
}

function handleResponse(response) {
  console.log(response);
  if (!response.statusText) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      //window.location.reload(true);
    }
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data;
}

export default forgotpasswordServices;
