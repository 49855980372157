import axios from 'axios'
import { authHeader } from '../_helpers';

 const SettingChangesService = {
     update1,
     update2,
     update3,

    handleResponse,

};


async function update1(update1) {
    var bodyFormData = new FormData();
    bodyFormData.append("email", update1.email)
    // bodyFormData.append("user_id", update.user_id)
    bodyFormData.append("password", update1.password1)
    // bodyFormData.append("new_password", update.new_password)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}users/update-password`,
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}
async function update2(update2) {
    var bodyFormData = new FormData();
    // bodyFormData.append("email", update.email)
    bodyFormData.append("user_id", update2.user_id)
    bodyFormData.append("password", update2.password2)
    // bodyFormData.append("new_password", update.new_password)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}users/update-password`,
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}
async function update3(update3) {
    var bodyFormData = new FormData();
    // bodyFormData.append("email", update.email)
    // bodyFormData.append("user_id", update.user_id)
    bodyFormData.append("password", update3.password)
    bodyFormData.append("new_password", update3.new_password)
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        },
        url: `${process.env.REACT_APP_BASE_URL}users/update-password`,
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}


function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}

export default SettingChangesService;
