import { userConstants } from "../_constants";
import { userService } from "../_services/user.services";
import { alertActions } from "./";
import { history } from "../_helpers";
import message from "../component/_common/Message/message";
export const userActions = {
  login,
  logout,
  getAll,
  delete: _delete,
};

function login(user_id, password, from) {
  return (dispatch) => {
    dispatch(request({ user_id }));

    userService.login(user_id, password).then(
      (user) => {
        dispatch(success(user));
        history.push(from);
        dispatch(alertActions.success(message.login.success));
        setTimeout(() => dispatch(alertActions.clear()), 4000);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(message.login.error));
        setTimeout(() => dispatch(alertActions.clear()), 4000);
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));
    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
