import React, { useEffect, useState } from "react";
import { contactService } from "../../../_services/contact.service";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { alertActions } from "../../../_actions/alert.actions";
import { useHistory } from "react-router-dom";
import message from "../../_common/Message/message";

function useContactPageHooks() {
  let history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [current, setcurrent] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [checked, setChecked] = useState(false);
  const [numberValid, setNumberValid] = useState(true);

  const [add, setAdd] = useState({
    name: "",
    mobile_no: "",
    email: "",
    reason: "",
    about: "",
    id: "",
  });

  //make submit button disable before clicking on checkbox
  function button_disable(e) {
    setChecked(e.target.checked);
  }

  //move to top of the screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function changeHandler(e) {
    //validation for mobile number
    if (e.target.name == "mobile_no") {
      var filter = /^[0-9\b]+$/;
      if (
        !filter.test(e.target.value) ||
        e.target.value.length < 11 ||
        e.target.value.length > 11
      ) {
        setNumberValid(false);
      } else {
        setNumberValid(true);
      }
    }

    //validation for email
    if (e.target.name == "email") {
      var filter =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(e.target.value)) {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
    }
    const { name, value } = e.target;
    setAdd((add) => ({ ...add, [name]: value }));
    console.log(value);
  }

  function handleSubmit(e) {
    console.log(message);
    e.preventDefault();
    setSubmitted(true);
    if (
      add.name &&
      add.mobile_no &&
      add.email &&
      add.reason &&
      add?.about &&
      checked &&
      emailValid &&
      numberValid
    ) {
      //API call
      contactService.add(add).then((data) => {
        if (data.status.code === 0) {
          setAdd({
            name: "",
            mobile_no: "",
            email: "",
            reason: "",
            about: "",
            id: "",
          });
          history.push("/ContactThankYou");
          setSubmitted(false);
          dispatch(alertActions.success(message.contact.add));
          setTimeout(() => dispatch(alertActions.clear()), 1000);
        }
      });
    }
  }
  //return all state and function
  return {
    data,
    current,
    submitted,
    emailValid,
    checked,
    numberValid,
    add,
    button_disable,
    changeHandler,
    handleSubmit,
  };
}
export default useContactPageHooks;
