import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userActions } from "../../../_actions";
import forgotpasswordServices from "../../../_services/forgotpassword.services";
import { alertActions } from "../../../_actions/alert.actions";
import message from "../../_common/Message/message";

const LoginHooks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem(`${process.env.REACT_APP_PROJECT_NAME}` + "reload");
    localStorage.removeItem(
      `${process.env.REACT_APP_PROJECT_NAME}` + "_access_token"
    );
  }, []);

  const [login, setLogin] = useState({
    user_id: "",
    password: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [submittedlink, setSubmittedlink] = useState(false);
  const { user_id, password } = login;
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loginuserid, setLoginuserid] = useState();
  const [maildata, setMaildata] = useState();
  var mail_userid = loginuserid;

  // console.log("msil sppppii" + mail_userid)
  function handleChange(e) {
    const { name, value } = e.target;
    setLogin((login) => ({ ...login, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setSubmittedlink(false);
    if (user_id && password) {
      // get return url from location state or default to home page
      const { from } = location.state || { from: { pathname: "/chat" } };
      dispatch(userActions.login(user_id, password, from));
    }
    localStorage.setItem(
      `${process.env.REACT_APP_PROJECT_NAME}` + "_user_id_fetched",
      login.user_id
    );
    const finaluserid = localStorage.getItem(
      `${process.env.REACT_APP_PROJECT_NAME}` + "_user_id_fetched"
    );
  }

  function handleSubmitLink(e) {
    localStorage.setItem(
      `${process.env.REACT_APP_PROJECT_NAME}` + "_user_id_fetched2",
      login.user_id
    );
    const finaluserid2 = localStorage.getItem(
      `${process.env.REACT_APP_PROJECT_NAME}` + "_user_id_fetched2"
    );
    e.preventDefault();
    setSubmittedlink(true);
    setSubmitted(false);
    localStorage.setItem(
      `${process.env.REACT_APP_PROJECT_NAME}` + "_user_id_fetched",
      login.user_id
    );
    const finaluserid = localStorage.getItem(
      `${process.env.REACT_APP_PROJECT_NAME}` + "_user_id_fetched"
    );
    setLoginuserid(finaluserid2);
    if (finaluserid2) {
      forgotpasswordServices.SendMail(finaluserid2).then((data) => {
        setMaildata(data);
        if (data.status.code === 0) {
          setLogin({
            password: "",
            user_id: "",
          });
          setSubmitted(false);
          setSubmittedlink(false);
          dispatch(alertActions.success(message.mailsent.success));
          setTimeout(() => dispatch(alertActions.clear()), 4000);
        } else {
          dispatch(alertActions.error(message.mailsent.error));
          setTimeout(() => dispatch(alertActions.clear()), 4000);
        }
      });
    }
  }

  return {
    login,
    submitted,
    submittedlink,
    loggingIn,
    maildata,
    dispatch,
    location,
    mail_userid,
    handleChange,
    handleSubmit,
    handleSubmitLink,
  };
};
export default LoginHooks;
