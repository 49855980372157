import axios from "axios";
import { authHeader } from "../_helpers";
//test
export const contactService = {
  add,
  handleResponse,
};

async function add(add) {

  var bodyFormData = new FormData();
  bodyFormData.append("name", add.name);
  bodyFormData.append("mobile_no", add.mobile_no);
  bodyFormData.append("email", add.email);
  bodyFormData.append("about_us", add.about);
  bodyFormData.append("reason", add.reason);
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    url: `${process.env.REACT_APP_BASE_URL}users/contact`,
    data: bodyFormData,
  };

  return await axios(requestOptions).then(handleResponse);
}

function handleResponse(response) {
  console.log(response);
  if (!response.statusText) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      //window.location.reload(true);
    }
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data;
}
