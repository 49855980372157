import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import Slider from "react-slick";
import ReactPaginate from "react-paginate";
import useColumnHooks from "./ColumnHooks";

function Column() {
  const {
    imag,
    profile_url,
    sliderImg,
    blogdata,
    featureddata,
    advertisementdata,
    settings,
    changeCurrentPage,
  } = useColumnHooks();

  const renderSlidingCarousal = () => {
    return (
      <div className="slide_box flt">
        <Slider {...settings}>
          {sliderImg.data &&
            sliderImg.data &&
            sliderImg.data.banner.map((vieww, i) => {
              return (
                <div className="slidespace">
                  <a className="remv_undrln" href={vieww.link} target="_blank">
                    <img src={profile_url + vieww.image} />
                  </a>
                </div>
              );
            })}
        </Slider>
      </div>
    );
  };

  const renderColumnImagesWithPagination = () => {
    return (
      <div className="col-sm-9">
        <div className="column_det_img_txt_contnr flt">
          {/* ///////////////////////////////////////// */}

          <div className="column_featrd_img_contnr">
            <div className="row">
              {" "}
              <div className="column_img_headd"> 最新コラム一覧</div>
              {blogdata.data &&
                blogdata.data.blog &&
                blogdata.data.blog.docs.map((docss, i) => {
                  return (
                    <div className="col-sm-6 algn_center">
                      {" "}
                      <a
                        className="remv_undrln"
                        href={"/article-details/" + docss._id}
                      >
                        <img
                          src={imag + docss.image}
                          className="column_multi_img"
                        />{" "}
                        <div className="column_date_txt_contnr">
                          {" "}
                          <div className="column_img_below_date">
                            {" "}
                            {docss.date}
                          </div>
                          <div className="column_img_below_txt">
                            {docss.title}
                          </div>{" "}
                        </div>{" "}
                      </a>
                    </div>
                  );
                })}
              <div className="paginationdiv flt">
                <ReactPaginate
                  previousLabel={"PREVIOUS"}
                  nextLabel={"NEXT"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={
                    blogdata.data &&
                    blogdata.data.blog &&
                    blogdata.data.blog.totalPages
                      ? blogdata.data.blog.totalPages
                      : 0
                  }
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={changeCurrentPage}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    );
  };

  const renderMostFeaturedImages = () => {
    return (
      <div className="column_col2_contnr1">
        <div className="column_col2_contnr1_head"> おすすめ記事一覧</div>
        {featureddata.data &&
          featureddata.data.blog.map((featrd, i) => {
            return (
              <div className="column_rightcont1">
                {" "}
                <div className="row margin_btm">
                  <div>
                    {" "}
                    <a
                      className="remv_undrln"
                      href={"/article-details/" + featrd._id}
                    >
                      <img
                        src={imag + featrd.image}
                        className="column_mostviewd_img"
                      />
                    </a>
                  </div>{" "}
                  <div>
                    <a
                      className="remv_undrln"
                      href={"/article-details/" + featrd._id}
                    >
                      {" "}
                      <div className="column_col2_contnr1_date">
                        {" "}
                        {featrd.date}{" "}
                      </div>
                      <div className="column_col2_contnr1_text">
                        {featrd.title}
                      </div>
                    </a>
                  </div>
                </div>
                <hr className="hrstyl" />{" "}
              </div>
            );
          })}
      </div>
    );
  };

  const renderAdvertisementBanner = () => {
    return (
      <div className="gap">
        {advertisementdata.data &&
          advertisementdata.data.banner.map((advrtsmnt, i) => {
            return (
              <div className="artdet_col2_contnr2">
                <div className="artdet_contnr2_txt">
                  <a target="_blank" href={"http://" + advrtsmnt.link}>
                    {" "}
                    <img src={imag + advrtsmnt.image} />
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <Header />
      <div className="main_container1 flt">
        {renderSlidingCarousal()}
        <div className="landing_container flt">
          <div className="container">
            <div className="column_img_container">
              <div className="row">
                {renderColumnImagesWithPagination()}
                <div className="col-sm-3">
                  {renderMostFeaturedImages()}
                  {renderAdvertisementBanner()}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Column;
