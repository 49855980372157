import React, { useState, useEffect } from "react";
import PrivacyServices from "../../../_services/Privacy.services";
function usePrivacyPolicyHooks() {
    const [data, setData] = useState([]);
    let type = "privacy";

    useEffect(() => {
        //api call for displaying privacy policy content
        PrivacyServices.getData(type).then((data) => {
            setData(data.data);
        });
    }, []);
    return {
        data, type
    }
}
export default usePrivacyPolicyHooks