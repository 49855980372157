

const colors = {
  primary: '#21C6E1',
  bgComment: 'rgba(0,0,0, .5)',
  selectedPoint: 'red',
  transparent: 'rgba(0,0,0,0)',
  bgDark: '#171717'
};
const isMac = () => {
  if (window.screen.width >= 2000) {
    return true;
  }
  return false;
}
const isLaptop = () => {
  if (window.screen.width >= 1300 && window.screen.width < 1999) {
    return true;
  }
  return false;
}
const isDesktop = () => {
  if (window.screen.width === 1200) {
    return true;
  }
  return false;
}
const isMobile = () => {
  if (window.screen.width < 400) {
    return true;
  }
  return false;
}
var commentDiv1;

if (isMac()) {
  commentDiv1 = {
    width: '1252px', height: '3000px'
  }
} else if (isDesktop()) {
  commentDiv1 = {
    width: '664px', height: '2000px'
  }
}
else if (isMobile()) {
  commentDiv1 = {
    width: '600px', height: '2000px'
  }
}
else {
  commentDiv1 = {
    width: '775px', height: '2000px'
  }
}

const style = {
  Gallary: {
    main: {

      // marginLeft: '2rem',
      width: 'fit-content',
      marginLeft: '10px',
      maxWidth: '70%',
      // height: '25vh',
      display: 'flex',
      background: 'rgb(228 228 228)',
      padding: '10px 0px 10px 0px',
      borderRadius: '10px',
      // borderRadius: '5px',
      height: '143px'
    },

    disable: {
      opacity: 0.4,
      'pointer-events': 'none'
    },
    // main1: {

    //   display: 'flex',
    //   background: colors.bgDark,
    //   padding: '2px',
    //   borderRadius: '5px',
    //   height: '143px'
    // },
    images: {
      // border: '2px solid yellow'
    },


  },
  SingleImage: {
    button: {
      width: '15vw',
      height: '15vh',
      display: 'flex',
      justifyContent: 'center',
      background: colors.transparent,
      margin: '10px 0px',
      outline: 0,
      border: 0

    },
    image: {
      position: 'relative',
      width: '16vw',
      height: '16vh',
      // border: '2px solid yellow'

    },
    name: {
      position: 'absolute',
      width: '15vw',
      textAlign: 'center',
      color: 'black',
      background: colors.bgComment,
      padding: 3,

    }
  },

  SelectedImage: {
    main: {},
    imageBody: { width: '100%', padding: '0px 82px' },
    commentDiv: { position: 'absolute', padding: '0px' },
    imageWrapper: {
      width: '100%',
      // background: colors.bgDark,
      background: 'rgb(255 255 255)',
      padding: '0px',

    },
    settingsWrapper: {
      display: 'flex',
      width: '100%',
      padding: '0px',
      justifyContent: 'center',

    }
  },
  ImageHeader: {
    main: {
      // height: '5vh',
      // display: 'flex',
      // justifyContent: 'center',
      // textAlign: 'center',
      // margin: '25px -8px 15px 9px',

    },
    // name: { color: 'white', marginTop: '5px', marginLeft: '38px' },
    deleteButton: {
      // background: '#fb5757', color: 'black', border: 'none',
      // borderRadius: '6px'
      //       width: '90px !important',
      //       background: 'rgba(0, 165, 83, 1)',
      //   border:'none',
      //     font- family: "Noto Sans JP", sans- serif;
      // font - size: 12px!important;
      // color: rgba(255, 255, 255, 1);
      // text - align: center;
      // padding: 10px;
    },
    editButton: {},
    cancelButton: {}
  },
  ImagePreview: {
    main: {},
    commentDiv: {},
    // commentDiv: { width: '822px', height: '2000px' },
    mainImage: { pointerEvents: 'visible', width: '100%', height: 'auto', padding: '0px 0px', marginLeft: '0px', border: '2px solid black' },
  },

  PointComments: {
    main: {
      width: '20vw',
      marginTop: '10px',
      background: '#e4e4e4',
      textAlign: 'center',
      borderRadius: '3px',
      marginLeft: '-10vw',
      padding: '5px',
      position: 'absolute',
      zIndex: '99999',
      pointerEvents: 'auto'

    },
    header: { position: 'relative', width: '100%' },
    headerSpan: { fontSize: '1em', color: 'rgb(0 0 0)' },
    input: { marginTop: '3px' },
    commentComponent: {
      textAlign: 'left',
      fontSize: '.8em',
      color: 'black',
      margin: '3px'
    },
    commentsWrapper: { maxHeight: '30vh', overflowY: 'auto' },
    commentBody: { display: 'flex', marginTop: '2px' },
    commentUser: {
      fontWeight: 'bold',
      fontStyle: 'italic',
      marginRight: '5px'
    },
    commentTime: { color: 'black' },
    commentDelete: {
      color: '#000000',
      textAlign: 'right',
      float: 'right',
      position: 'absolute',
      top: '28px',
      right: '31px'
      // flex: '1'
    },
    commentSpan: { wordBreak: 'break-all', }
  },
  AllComments: {
    main: {
      width: '25vw',
      top: '4vh',
      position: 'absolute',
      overflowY: 'auto',
      overflowX: 'hidden',
      background: 'rgb(228 228 228)',
      textAlign: 'center',
      borderRadius: '10px',
      zIndex: 1,
      padding: '3px',
      right: '0px',
      opacity: 1,
      transition: 'all .8s ease',
      height: '773px !important',
      marginRight: '33px'
    },
    mainHidden: {
      width: '20vw',
      top: '40vh',
      position: 'absolute',
      overflowY: 'auto',
      overflowX: 'hidden',
      background: 'rgb(228 228 228)',
      textAlign: 'center',
      borderRadius: '5px',
      zIndex: 1,
      padding: '3px',
      right: '0px',
      opacity: 0,
      transition: 'all .8s ease',
      height: '773px !important'
    },
    header: { fontSize: '22px', color: 'rgb(0 0 0)' },
    commentComponent: {
      color: 'rgb(0 0 0)',
      textAlign: 'justify',
      fontSize: '15px',
      padding: '0px 15px',
      marginTop: '9px'
    },
    selecteCommentComponent: {
      // color: 'rgb(0 0 0)',
      // textAlign: 'left',
      // fontSize: '12px',
      // background: '#B8C2CF',
      // paddingLeft: '20px'
      // margin: '15px',
      // borderRadius: '10px',
      // border: '2px solid black',
      // borderStyle: 'inset'

      color: 'rgb(0 0 0)',
      textAlign: 'justify',
      fontSize: '15px',
      padding: '0px 15px',
      background: '#B8C2CF',
      marginTop: '9px'

    },
    commentBody: { display: 'flex', alignItems: 'flex-start' },
    commentUser: {
      fontSize: '12px',
      marginRight: '5px',
      fontStyle: 'italic'
    },
    commentTime: { color: 'rgb(95 93 93)', marginLeft: '38px' },
    commentSpan: { wordBreak: 'break-all', float: 'left', width: '100%', fontSize: '17px' }
  }
};
export default style;
export { colors, isLaptop, isDesktop, isMac, isMobile, commentDiv1 };
