import React, { useEffect } from "react";
import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";
import imgos from "../../../assets/images/os_img.png";
import contactImage from "../../../assets/images/contactImage.png"
import img from "../../../assets/images/446.png"

function OperatingSystem() {
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //heading design
  const renderGreenBarOS = () => {
    return (
      <div className="service_bar flt">

        <img className="img_length" src={img}></img>
        <div className="service_content">
          運営体制
        </div>
      </div>);
  };
  //image display
  const renderImageOS = () => {
    return (
      <div className="box_container">
        <div className="os_content">
          印コーディングサービスでは、お客様からアップロードされたデータを<br />
          印コーディング日本支社で確認をしたのち、インド支社へ展開しコーディングを行なっております。<br />
          日本のサーバーを使用し、セキュリティには万全の体制を築き、お客様にも<br />
          安心してご使用いただける環境を整えております。また、日本とインドの間で、<br />
          定期的なミーティングや研修を行うなど連携体制を築いております。<br />
        </div>
        <div className="box_container1">
          <img src={imgos}></img>
        </div>
       
      </div>
    );
  };
  //redirect button
  const renderRegistrationButtonOS = () => {
    return (
      <div className="algn_centr">
        <button className="os_btn">
          <div className="fltlft" onClick={() => history.push("/Registration")}>
            初めての方は <br />
            会員登録して無料お見積もり
          </div>
          <div className="fltrgttt">
            <i class="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };

  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        <Header />
        {renderGreenBarOS()}
        <div className="flt">
          <div className="container">
            <div className="os_container">

              {renderImageOS()}
              {/* {renderRegistrationButtonOS()} */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default OperatingSystem;