import React, { Component } from 'react';
import './image.css';
import { connect } from 'react-redux';

class singleImage extends Component {

  changeImage(image) {
    console.log(image)
    image(image)
  }
  render() {
    const { image, changSelectedeImage, selectedImage } = this.props;
    console.log(selectedImage)
    const baseUrl = 'https://d32fg19io9z7j1.cloudfront.net/production';
    var final_img_path = image.file.includes("data:") ? image.file : baseUrl + image.file;
    // setTimeout(() => { }, 500)
    return (
      <div >
        <button
          type="button"
          // style={style.button}
          className="image-height"


          onClick={event => {
            event.preventDefault();
            changSelectedeImage(image);

          }}
        >

          <img alt="change" className="images" id={image.id} src={final_img_path}
            className={selectedImage && image.id === selectedImage.id ? "test" : "best"} />
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {

  const {

    selectedImage,

  } = state.pointerReducer;

  return {

    selectedImage,

  };
}


export default connect(mapStateToProps, {

})(singleImage);