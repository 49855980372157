// import { authHeader } from '../_helpers';
import axios from "axios";
import { authHeader } from '../_helpers';

const ColumnServices = {
  getBlogData,
  getMostViewdData,
  handleResponse,
  getSliderImg,
  getData,
  getSliderImgId
  
};




async function getData(pno) {
  //let pno = 2;
  const requestOptions = {
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}users/blog?page=${pno}`,
    headers: authHeader()
  };
  return await axios(
    requestOptions
  ).then(handleResponse);
}



async function getBlogData() {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/blog`,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}

async function getMostViewdData() {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/blogview`,
    // headers: authHeader()
  };
  return await axios(requestOptions).then(handleResponse);
}

async function getSliderImg() {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/banner`,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}
async function getSliderImgId(id) {
  const requestOptions = {
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}users/banner/${id}`,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}


function handleResponse(response) {
  console.log(response.data);

  if (!response.statusText) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      //window.location.reload(true);
    }
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data;
}

export default ColumnServices;
