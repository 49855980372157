
import { useState } from "react";
import { useDispatch } from "react-redux";
import SettingChangesService from "../../../_services/settingchanges.services";
import { registrationService } from "../../../_services/registration.services";
import message from "../../_common/Message/message";
import { confirmAlert } from "react-confirm-alert";
import { alertActions } from "../../../_actions/alert.actions";

const SettingHook = () => {
    //mail form state
    const [showmailform, setShowmailform] = useState(true);
    //userID change form state
    const [showuseridform, setShowuseridform] = useState(false);
    //password change form state
    const [showpasswordchangeform, setShowpasswordchangeform] = useState(false);
    const uidCheck = false;
    const [passwordCheck, setPasswordCheck] = useState(true);
    const [useridcheck, setUseridcheck] = useState(true);
    const [passwordValid, setpasswordValid] = useState(true);
    const [submitted1, setSubmitted1] = useState(false);
    const [submitted2, setSubmitted2] = useState(false);
    const [submitted3, setSubmitted3] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [useridexist, setUseridexist] = useState(false);
    const dispatch = useDispatch();

    const [update1, setUpdate1] = useState({
        password: "",
        email: "",
    });

    const [update2, setUpdate2] = useState({
        user_id: "",
        password: "",
        re_userid: "",
    });

    const [update3, setUpdate3] = useState({
        password: "",
        new_password: "",
        re_password: "",
    });

    const onChangeHandler = (e) => {
        //reenter password validation
        if (e.target.name === "re_password") {
            if (update3.new_password === e.target.value) {
                setPasswordCheck(true);
            } else {
                setPasswordCheck(false);
            }
        }
        //password match validation
        if (e.target.name === "new_password") {
            if (update3.re_password === e.target.value) {
                setPasswordCheck(true);
            } else {
                setPasswordCheck(false);
            }
        }
        //re enter password validation
        if (e.target.name === "re_userid") {
            if (update2.user_id === e.target.value) {
                setUseridcheck(true);
            } else {
                setUseridcheck(false);
            }
        }
        //userId match validation
        if (e.target.name === "user_id") {
            if (update2.re_userid === e.target.value) {
                setUseridcheck(true);
            } else {
                setUseridcheck(false);
            }
        }
        //newpassword validation
        if (e.target.name === "new_password") {
            if (e.target.value.length < 6) {
                setpasswordValid(false);
            } else {
                setpasswordValid(true);
            }
        }
        //email validation
        if (e.target.name === "email") {
            var filter =
                /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!filter.test(e.target.value)) {
                setEmailValid(false);
            } else {
                setEmailValid(true);
            }
        }


        const { name, value } = e.target;
        setUpdate1((update1) => ({ ...update1, [name]: value }));
        setUpdate2((update2) => ({ ...update2, [name]: value }));
        setUpdate3((update3) => ({ ...update3, [name]: value }));
    };
    //on click of mail change button , other two button disappears
    const MailChangeHandler = (e) => {
        setShowuseridform(false);
        setShowmailform(true);
        setShowpasswordchangeform(false);
    };
    //on click of password change button , other two button disappears
    const PasswordChangeHandler = (e) => {
        setShowuseridform(false);
        setShowmailform(false);
        setShowpasswordchangeform(true);
    };
    //on click of userID change button , other two button disappears
    const UserIDChangeHandler = (e) => {
        setShowuseridform(true);
        setShowmailform(false);
        setShowpasswordchangeform(false);
    };

    //api call for userid,password and email change
    const idUpdateSubmitHandler = (e) => {
        e.preventDefault();

        registrationService.check_user(update2.user_id).then((data) => {
            if (data.status.code === 1) {
                setUseridexist(true);
            } else {
                setUseridexist(false);
                if (
                    update2.password2 &&
                    update2.user_id &&
                    update2.re_userid &&
                    useridcheck &&
                    update2.user_id.trim().length !== 0 &&
                    update2.re_userid.trim().length !== 0 &&
                    update2.user_id === update2.re_userid
                ) {
                    confirmAlert({
                        title: "送信確認を行う",
                        message: "詳細情報を送信してよろしいですか？",
                        buttons: [
                            {
                                label: "はい",
                                onClick: () => {
                                    SettingChangesService.update2(update2).then((data) => {
                                        if (data.status.code === 0) {
                                            setUpdate2({
                                                password2: "",
                                                user_id: "",
                                                re_userid: "",
                                            });
                                            setSubmitted2(false);
                                            dispatch(
                                                alertActions.success(message.updateUserId.success)
                                            );
                                            setTimeout(() => dispatch(alertActions.clear()), 4000);
                                        } else {
                                            dispatch(alertActions.error(message.updateUserId.error));
                                            setTimeout(() => dispatch(alertActions.clear()), 4000);
                                        }
                                    });
                                },
                            },
                            {
                                label: "いいえ",
                                onClick: () => null,
                            },
                        ],
                    });
                }
            }
        });

        // if (update2.user_id !== update2.re_userid) {
        //   return false;
        // }

        setSubmitted2(true);
        if (useridexist === false) {
        }
    };
    //api call for userid,password and email change
    const emailUpdateSubmitHandler = (e) => {
        e.preventDefault();
        setSubmitted1(true);

        update1.password1 &&
            update1.email &&
            emailValid &&
            confirmAlert({
                title: "送信確認を行う",
                message: "詳細情報を送信してよろしいですか？",
                buttons: [
                    {
                        label: "はい",
                        onClick: () => {
                            SettingChangesService.update1(update1).then((data) => {
                                if (data.status.code === 0) {
                                    setUpdate1({
                                        password1: "",
                                        email: "",
                                    });
                                    setSubmitted1(false);
                                    dispatch(alertActions.success(message.updateEmail.success));
                                    setTimeout(() => dispatch(alertActions.clear()), 4000);
                                } else {
                                    dispatch(alertActions.error(message.updateEmail.error));
                                    setTimeout(() => dispatch(alertActions.clear()), 4000);
                                }
                            });
                        },
                    },
                    {
                        label: "いいえ",
                        onClick: () => null,
                    },
                ],
            });
    };
    //api call for userid,password and email change
    const passwordUpdateSubmitHandler = (e) => {
        e.preventDefault();
        // if (update3.re_password !== update3.new_password) {
        //   return false;
        // }

        setSubmitted3(true);
        update3.password &&
            update3.new_password &&
            update3.re_password &&
            update3.new_password.trim().length !== 0 &&
            update3.re_password.trim().length !== 0 &&
            passwordValid &&
            passwordCheck &&
            update3.new_password === update3.re_password &&
            confirmAlert({
                title: "送信確認を行う",
                message: "詳細情報を送信してよろしいですか？",
                buttons: [
                    {
                        label: "はい",
                        onClick: () => {
                            SettingChangesService.update3(update3).then((data) => {
                                if (data.status.code === 0) {
                                    setUpdate3({
                                        password: "",
                                        new_password: "",
                                        re_password: "",
                                    });
                                    setSubmitted3(false);
                                    dispatch(
                                        alertActions.success(message.updatepassword.success)
                                    );
                                    setTimeout(() => dispatch(alertActions.clear()), 4000);
                                } else {
                                    dispatch(alertActions.error(message.updatepassword.error));
                                    setTimeout(() => dispatch(alertActions.clear()), 4000);
                                }
                            });
                        },
                    },
                    {
                        label: "いいえ",
                        onClick: () => null,
                    },
                ],
            });
    };
    return {
        showmailform,
        showuseridform,
        showpasswordchangeform,
        uidCheck,
        passwordCheck,
        useridcheck,
        passwordValid,
        submitted1,
        submitted2,
        submitted3,
        emailValid,
        useridexist,
        update1,
        update2,
        update3,
        dispatch,
        onChangeHandler,
        MailChangeHandler,
        PasswordChangeHandler,
        UserIDChangeHandler,
        idUpdateSubmitHandler,
        emailUpdateSubmitHandler,
        passwordUpdateSubmitHandler
    }
}
export default SettingHook;
