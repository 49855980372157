import React, { Component } from 'react';
import InputSearch from './inputSearch';
import styles from '../../../styles';
import './image.css';
import { confirmAlert } from "react-confirm-alert";



const style = styles.ImageHeader;

// eslint-disable-next-line import/no-anonymous-default-export
export default class extends Component {

  state = {
    editable: false,
    name: this.props.image.name,
    button: true
  };


  componentDidMount() {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    if (window.location.href.includes('cp/')) {

      console.log('button hide');
      this.setState({ button: false })


    } else {
      console.log('button show');
      this.setState({ button: true })
    }
  }
  componentWillReceiveProps(nextProps) {
    localStorage.removeItem('reactDotImageState');
    console.log(nextProps)
    if (this.props.image.name !== nextProps.image.name) {
      this.setState({ editable: false, name: nextProps.image.name });

    }

  }


  render() {
    const { image, editImage, deleteImage, setSelectedPoint } = this.props;
    const { name } = this.state;
    return this.state.editable ? (
      <div className="button_img_div1">
        {/* <div style={style.main}> */}
        <InputSearch
          defaultValue={name || ''}
          placeholder={"Please type valid name"}
          onSearch={name => {
            editImage({ ...image, name });
            this.setState({ editable: false, name });
            {
              if (this.state.name === "") {
                alert("hi");
              }
            }
          }}

          autoFocus
        />

        {/* </div> */}
      </div>
    ) :
      <div className="button_img_div">
        {/* <div style={style.main}> */}
        <div className="name_div">
          <span className="nameEdit_div">{name || 'No Name'}</span>
        </div>
        <div className={this.state.button ? "imgedit_div" : "imgedit_div1"}>


          <button
            className="delete_btn"
            style={style.deleteButton}
            // onClick={() => deleteImage(image)}
            onClick={() => {
              confirmAlert({

                message: "そのプロジェクトを受け入れたいと確信していますか？",
                buttons: [
                  {
                    label: "はい",
                    onClick: () => deleteImage(image)
                  },
                  {
                    label: "いいえ",
                    onClick: () => null,
                  }
                ]

              })
            }}
          >
            <i className="fas fa-trash-alt delete_img"></i>
            削除
          </button>
          <button
            className="simpleButton"
            style={style.editButton}
            onClick={event => {
              event.stopPropagation();
              this.setState({ editable: true });
              setSelectedPoint(null);
            }}
            onClick={() => {
              confirmAlert({

                message: "Are you sure that you want to edit the name?",
                buttons: [
                  {
                    label: "はい",
                    onClick: () => {
                      // event.stopPropagation();
                      this.setState({ editable: true });
                      setSelectedPoint(null);
                    }
                  },
                  {
                    label: "いいえ",
                    onClick: () => null,
                  }
                ]

              })
            }}


          >
            <i className="fas fa-pencil-alt edit_icon"></i>
            Edit
          </button>

        </div>

        <commentPointer />



        {/* </div> */}
      </div>


  }
}