import React, { useEffect } from "react";
import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import { Link, useHistory } from "react-router-dom";
import "./ContactThankYou.css";

const ContactThankYou = () => {
    let history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //Contact thankyou design
    const renderGreenBar = () => {
        return <div className="thankyou_barC flt">お問い合わせ完了</div>;
    };
    //content of thank you page
    const renderTextContentContactThankYou = () => {
        return (
            <div className="thankyou_para_contact flt">
                <div className="thanks_para flt">
                    このたびはお問い合わせいただきありがとうございます。
                    <br />
                    ご入力いただいたメールアドレス宛に受付確認メールを送りしましたのでご確認ください。
                    <br />
                    <br />
                    確認メールが届いていない場合には、メールアドレスが誤っているか、確認メールが
                    <br />
                    迷惑メールフォルダ等に振り分けられている可能性がありますので、再度ご確認をご確認をお願いいたします。
                    <br />
                    <br />
                    なお、お問い合わせ内容につきましては、通常3営業日程度を目処に
                    <br />
                    印コーディングサービススタッフよりご回答させていただきます。今しばらくお待ちくださいませ。{" "}
                </div>
            </div>
        );
    };
//redirect button design
    const renderReturnToTopButtonContact = () => {
        return (
            <div className="thank_submit">
                <button onClick={() => history.push("/")} className="thankyou_btn">
                    <div className="fltlft">TOPに戻る</div>
                    <div className="fltrgt">
                        <i class="fas fa-chevron-right"></i>
                    </div>
                </button>
            </div>
        );
    };

    return (
        <div className="flt">
            <Header />
            <div className="container">
                <div className="main_containers_contact flt">
                    {renderGreenBar()}
                    {renderTextContentContactThankYou()}
                    {renderReturnToTopButtonContact()}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactThankYou;