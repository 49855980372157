import io from "socket.io-client";
import { Link, useHistory } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import logo from "../../../assets/images/logo192.png";
import "./Userpanel.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notficationAction } from "../../../_actions/notification.action";

function Sidebar() {
  console.log("User panel side bar ");
  let history = useHistory();

  const notficationCount = useSelector(
    (state) => state.noficationCount.chatCount
  );
  const dispatch = useDispatch();

  function logout() {
    history.push("/login");
    localStorage.clear();
  }

  useEffect(() => {
    console.log("user panel useeffcet");
    var underscoreID = localStorage.getItem(
      `${process.env.REACT_APP_PROJECT_NAME}` + "_UsersId"
    );
    var underscoreID1 = JSON.parse(underscoreID);
    var chat_id = localStorage.getItem("chatId");
    var socket = io("https://chat.recursive-design.com/chat-" + chat_id, {
      query: "userId=" + underscoreID1,
    });

    socket.on("new message", (data) => {
      console.log(data);
      dispatch(notficationAction.notificationCount());
    });
    dispatch(notficationAction.notificationCount());
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar_logo flt">
        <img className="sidelogo" src={logo} alt="logo" />
      </div>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: "scroll" } }}
        className="sidebar_nav"
      >
        <ul>
          <li>
            <Link to="/chat">
              <span className="nav_icon">
                <i class="fas fa-comments"></i>
              </span>
              <span className="nav_txt">チャット</span>
              {notficationCount !== 0 && (
                <label className="unreadmsgs">{notficationCount}</label>
              )}
            </Link>
          </li>
          <li>
            <Link target="_blank" to="/contact">
              <span className="nav_icon">
                <i className="fas fa-address-book"></i>
              </span>
              <span className="nav_txt">コンタクト</span>
            </Link>
          </li>
          <li>
            <Link to="/user-panel">
              <span className="nav_icon">
                <i className="fas fa-cog"></i>
              </span>
              <span className="nav_txt">設定</span>
            </Link>
          </li>
          <li>
            <Link onClick={logout}>
              <span className="nav_icon">
                <i class="fas fa-sign-out-alt"></i>
              </span>
              <span className="nav_txt">ログアウト</span>
            </Link>
          </li>
        </ul>
      </OverlayScrollbarsComponent>
    </div>
  );
}

export default Sidebar;
