import React, { useEffect } from "react";
import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";
import "./RegistrationThankYou.css";

const RegistrationThankYou = () => {
    let history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //render green thank you bar
    const renderGreenBarThankU = () => {
        return <div className="thankyou_bar_reg flt">会員登録完了</div>;
    };
    //thank you page content display
    const renderContentThankyou = () => {
        return (
            <div className="thankyou_para_reg flt">
                <div className="thanks_para flt">
                    このたびは印コーディングサービスにご登録いただきありがとうございます。
                    <br />
                    先ほど、登録完了のメールをお送りいたしました。 <br />
                    ご確認ください。
                    <br />
                    <br />
                    10分経ってもメールが届かない場合は、
                    <br />
                    お手数ですが、再度ご登録いただくか、
                    お問い合わせフォームまたは、チャットツールより
                    <br />
                    ご連絡ください。{" "}
                </div>
            </div>
        );
    };
    //button for top page
    const renderTopButtonThankyou = () => {
        return (
            <div className="thank_submit">
                <button onClick={() => history.push("/")} className="thankyou_btn">
                    <div className="fltlft">TOPに戻る</div>
                    <div className="fltrgt">
                        <i class="fas fa-chevron-right"></i>
                    </div>
                </button>
            </div>
        );
    };

    return (
        <div className="flt">
            <Header />
            <div className="container">
                <div className="main_containers_contact flt">
                    {renderGreenBarThankU()}
                    {renderContentThankyou()}
                    {renderTopButtonThankyou()}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RegistrationThankYou;