import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import QAService from "../../../_services/QA.services";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import img from "../../../assets/images/445.png"

const Accordion = ({ title2, title, children }) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    //using accordian for toggle cases
    <div className="accordion-wrapper">
      <div className="flex a-btn">
        <div className="lblpadding">{title2}</div>

        <div
          className={`accordion-title ${isOpen ? "open" : ""} lblpadding2`}
          onClick={() => setOpen(!isOpen)}
        >
          {title}
        </div>
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};

const QA = () => {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [current, setcurrent] = useState(1);

  //api call for questions and answers
  useEffect(() => {
    QAService.getData(current).then((data) => {
      setData(data);
      console.log(data && data);
    });
    console.log(data && data.data);
  }, []);

  const renderGreenBarFAQ = () => {
    return (
      <div className="service_bar flt">

        <img className="img_length" src={img}></img>
        <div className="service_content">
          よくある質問
        </div>
      </div>);
  };
  //render frequently asked questions
  const renderAccordianFAQ = () => {
    return (
      <div className="wrapper">
        {data &&
          data.data &&
          data.data.faq.map((ht, i) => {
            return (
              <div className="alter">
                <Accordion
                  title={<button className="accor_btn"></button>}
                  title2={"Q  " + ht.title}
                >
                  {"A"}&nbsp;&nbsp;
                  {ht.description}
                </Accordion>
              </div>
            );
          })}
      </div>
    );
  };

  const renderMixedGreenButtonFAQ = () => {
    return (
      <div className="algn_centr">
        <button
          className="faq_btn"
          onClick={() => history.push("/Registration")}
        >
          <div className="fltlft">
            初めての方は <br />
            会員登録して無料お見積もり
          </div>
          <div className="fltrgttt">
            <i class="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        <Header />
        {renderGreenBarFAQ()}
        <div className="flt">
          <div className="container">
            <div className="FAQ_container">
              {renderAccordianFAQ()}
              {/* {renderMixedGreenButtonFAQ()} */}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default QA;