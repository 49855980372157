import MessageServices from "../_services/message.services"
import { notificationConstants } from "../_constants/notfication.constants";

function notificationCount()
{
    return(dispatch)=>{
        
        MessageServices.getchatcount().then((res)=>{
          
            dispatch(Total(res.data.message));
        })
    }
}

function notificationClear(chatid)
{
    return(dispatch)=>{
        
        MessageServices.clearchatcount(chatid).then((res)=>{
      
            dispatch(Clear(res.data.message));
        })
    }
}



function Total (data)
{
    return { type:notificationConstants.NOTIFICATION_TOTAL,payload:data}
}

function Clear (data)
{
    return { type:notificationConstants.NOTIFICATION_CLEAR,payload:data}
}

export const notficationAction={
    notificationCount,
    notificationClear
}