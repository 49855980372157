import "./Footer.css";
import { Link, useHistory } from "react-router-dom";
import footer_man from "../../../assets/images/image_footer.svg";
import footer_logo from "../../../assets/images/footer_logo.png";

const Footer = () => {
  //importing all states and methods

  let history = useHistory();

  const footer_body = () => {
    return (
      <div className="footer_top flt">
        <div className="footer_box flt">
          <div className="footer_head flt">
            ログインすれば管理画面の
            <br />
            チャット機能で全て即対応
          </div>
          <div className="footer_cont flt">
            相談・見積もり・データ入稿・不明点相談・
            <br />
            運用方法相談・マニュアル作成・サーバーアップロード
          </div>
          <img alt="" src={footer_man} className="footer_man" />
        </div>
        <div className="footer_btn flt">
          <div className="filler"></div>
          <button
            onClick={() => history.push("/Registration")}
            className="green"
          >
            初めての方は
            <br />
            会員登録して見積もり
          </button>
          <button onClick={() => history.push("/login")} className="black">
            ログイン
          </button>
          <div className="filler"></div>
        </div>
      </div>
    );
  };
  const footer_main1 = () => {
    return (
      <div className="flt">
        <div className="row flt">
          <div className="col-sm-2 kk">
            <div>&nbsp;</div>
            <div>
              <Link to="/">
                <img src={footer_logo} alt=" " className="" />
              </Link>
            </div>
          </div>
          <div className="col-sm-9">
            <div className="footer_contact flt">
              <ul>
                <li>
                  <b>Tel:078-855-8220</b>
                </li>
                <li>
                  <b>Mail:shirusi@avii.jp</b>
                </li>
              </ul>
            </div>
            <div className="footer_link flt">
              <ul>
                {/* <li> <Link to="/"><img src={footer_logo} alt=" " className="kk" /></Link></li> */}
                <li>
                  <Link to="/firstuser">印コーディングとは</Link>
                </li>
                <li>
                  <Link to="/price">価格</Link>
                </li>
                <li>
                  <Link to="/service">サービス</Link>
                </li>
                <li>
                  <Link to="/howtouse">ご利用の流れ</Link>
                </li>
                <li>
                  <Link to="/os">運用体制</Link>
                </li>
                <li>
                  <Link to="/column">コラム</Link>
                </li>
                <li>
                  <Link to="/qna">お問い合わせ</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-1"></div>
        </div>
      </div>
    );
  };

  const footer_copy = () => {
    return <div className="copyright flt">All rights reserved.</div>;
  };
  // const footer_contact = () => {
  //   return (
  //     <div className="footer_contact flt">
  //       <ul>
  //         <li><b>Tel:078-855-8220</b></li>
  //         <li><b>Mail:shirusi@avii.jp</b></li>
  //       </ul>
  //     </div>
  //   )
  // }

  return (
    <div className="footer_container flt">
      <div className="container">
        {/* {footer_button()} */}
        {footer_body()}
        {/* {footer_contact()} */}
        <div className="row header_flex footer_box_flex flt">
          {/* {footer_logo_shir()} */}
          {/* {footer_link()} */}
          {/* <div className="col-sm-1">
          </div> */}
        </div>
        {/* {footer_link1()} */}
        {footer_main1()}
        {footer_copy()}
      </div>
    </div>
  );
};

export default Footer;
