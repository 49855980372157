import { useState, useEffect } from "react";
import ColumnServices from "../../../_services/column.services";
import ArticledetailsServices from "../../../_services/articledetails.services";
function useColumnHooks() {
  const imag = process.env.REACT_APP_MEDIA_DOMAIN; // profile image url
  const profile_url = process.env.REACT_APP_MEDIA_DOMAIN;

  const [data, setData] = useState([]);
  //slider image state
  const [sliderImg, setsliderImg] = useState([]);
  //blog data state
  const [blogdata, setBlogdata] = useState([]);
  //most viewed data state
  const [mostvieweddata, setMostvieweddata] = useState([]);
  //most featured data state
  const [featureddata, setFeatureddata] = useState([]);

  //advertisement data
  const [advertisementdata, setAdvertisementdata] = useState([]);
  const [current, setcurrent] = useState(1);
  //slider image plugin
  var settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: data,
    slidesToScroll: 1,
  };

  useEffect(() => {
    //move to top screen
    window.scrollTo(0, 0);
    //blog data api call
    ColumnServices.getBlogData(current).then((data) => {
      setBlogdata(data);
    });
    //most viewd data api call
    ColumnServices.getMostViewdData(current).then((data) => {
      setMostvieweddata(data);
    });
    //artical details api call
    ArticledetailsServices.getAdvertisementData(current).then((data) => {
      setAdvertisementdata(data);
    });
    //api call for slider image
    ColumnServices.getSliderImg(current).then((data) => {
      setsliderImg(data);
      if (data.data.banner.length < 5) {
        setData(data.data.banner.length);
      } else {
        setData(4);
      }
    });
  }, []);

  useEffect(() => {
    ColumnServices.getData(current).then((data) => setBlogdata(data));
    ArticledetailsServices.getFeaturedData(current).then((data) => {
      setFeatureddata(data);
    });
  }, [current]);
  const changeCurrentPage = (page) => {
    setcurrent(page.selected + 1);
  };
  return {
    imag,
    profile_url,
    data,
    sliderImg,
    blogdata,
    mostvieweddata,
    featureddata,
    advertisementdata,
    current,
    settings,
    changeCurrentPage,
  };
}
export default useColumnHooks;
