import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import { Strings, Colors, Images, Fonts } from "../../../res/strings";
import React, { useState, useEffect } from "react";
import img from "../../../assets/images/331.png";
import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";
import mark_logo from "../../../assets/images/mark_logo.png";
import triangle from "../../../assets/images/triangle.png";
import point_title from "../../../assets/images/point_title.png";
import price_new1 from "../../../assets/images/price-new1.png";
import price_new2 from "../../../assets/images/preice-new-2.png";
import price_new3 from "../../../assets/images/price-new-3.png";
import price_new4 from "../../../assets/images/price-new-4.png";
import "./Price.css";
function Service() {
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // heading display
  const renderGreenBarService = () => {
    return (
      <div className="service_bar flt">
        <img className="img_length" src={img}></img>
        <div className="service_content5">料金</div>
      </div>
    );
  };

  //different button display
  const renderGreenButtonsService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="box_container">
          <div className="mark_logo flt">
            <img src={mark_logo} alt="image" />
          </div>
          <div className="mark_head flt">料金体系について</div>
        </div>

        <div className="service_bar flt">
          <img className="img_length10" src={price_new1}></img>
          <img className="img_length2" src={price_new2}></img>
          <div className="os_content6">
            <p>
              *[PC、もしくはスマホのみでページの長さが6,000px以下の場合の料金です。]
            </p>
          </div>
        </div>

        <div className="service_bar flt">
          <img className="img_length2" src={price_new3}></img>
          <div className="os_content6">
            <p>
              *[PC、もしくはスマホのみでページの長さが10,000px以下の場合の料金です。]
            </p>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-sm-3">
            <img src={websys} alt="image" className="pervice-img" />
          </div>
          <div className="col-sm-3">
            <img src={hmenu} alt="image" className="pervice-img" />
          </div>
          <div className="col-sm-3">
            <img src={form} alt="image" className="pervice-img" />
          </div>
          <div className="col-sm-3">
            <img src={cms} alt="image" className="pervice-img" />
          </div>
        </div> */}

        {/* <img src={price} className="price-img" alt="image" />
        <div className="price-img">
          <div className="point_cont1">
            ※ ・　トップページを含む7P想定。 <br />
            ・アニメーション（フェードイン） <br />
            ・レスポンシブ対応。 <br />
            ・ワードプレス挿入し、お知らせ・ブログは更新時、トップページへ自動表示。
          </div>
        </div> */}
      </div>
    );
  };
  //the text for respective button
  const renderTextContentsService = () => {
    return (
      <div className="serv_buttons_container">
        <div className="box_container">
          <div className="point_title flt">
            <span>
              <img src={point_title} className="point_title_pic" alt="image" />
              ページ料金
              <img src={triangle} className="point_title_arrow" alt="image" />
            </span>
          </div>
        </div>

        <div className="service_bar flt">
          <img className="img_length2" src={price_new4}></img>
          <div className="os_content6">
            <p>
              *[PC、もしくはスマホのみでページの長さが5,000px以下の場合の料金です。]
            </p>
          </div>
        </div>
      </div>
    );
  };
  //button display
  const renderMixGreenButtonPDFService = () => {
    return (
      <div className="algn_centr">
        <button
          className="serv_btn"
          onClick={() => history.push("/Registration")}
        >
          <div className="fltlft">
            会員登録なしでPDFで
            <br />
            無料見積もりしてみる
          </div>
          <div className="fltrgttt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        {/* Actual Home page starts */}
        <Header />

        {/*Start of  FAQ section */}
        <div className="flt">
          {renderGreenBarService()}
          <div className="container">
            <div className="serv_container">
              {renderGreenButtonsService()}
              {renderTextContentsService()}
              {/* {renderTextContentsServices()} */}
              {/* {renderMixGreenButtonPDFService()} */}
            </div>
          </div>
        </div>
        {/*End of  FAQ section */}

        <Footer />
      </div>
      {/* Actual Home page Ends */}
    </div>
  );
}

export default Service;
