import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import loginimg from "../../../assets/images/loginlogo.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LoginHooks from "./LoginHooks";
const Login = () => {
  let history = useHistory();
  const {
    login,
    submitted,
    submittedlink,
    handleChange,
    handleSubmit,
    handleSubmitLink,
  } = LoginHooks();

  const renderGreenBarLogin = () => {
    return <div className="login_bar">LOGIN</div>;
  };

  const renderSirusiLogoLogin = () => {
    return (
      <div className="login_logo_contnr">
        <div className="login_logo">
          <img src={loginimg} />{" "}
        </div>{" "}
      </div>
    );
  };
  const renderLoginForm = () => {
    return (
      <div className="login_items_container">
        <form className="login_form flt" onSubmit={handleSubmit}>
          <div className="algn_centr1">
            <div className="tester_container ">
              <div className="login_head"> ログイン</div>
              <div className="logcomp_contnr">
                <div className="margn_bot">
                  <div className="row ">
                    <div className="col-sm-4">
                      <label for="uname" className="uidstyl">
                        ユーザーID
                      </label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        id="user_id"
                        name="user_id"
                        autoComplete="off"
                        value={login.user_id}
                        onChange={handleChange}
                        className={
                          (submitted || submittedlink) && !login.user_id
                            ? " is-invalid"
                            : "login_lbl"
                        }
                      />{" "}
                      {(submitted || submittedlink) && !login.user_id && (
                        <div className="invalid-feedback">
                          ユーザIDが必要です
                        </div>
                      )}
                    </div>
                  </div>{" "}
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <label for="pwd" className="pwdstyl">
                      パスワード
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={login.password}
                      onChange={handleChange}
                      className={
                        submitted && !login.password
                          ? " is-invalid"
                          : "login_lbl"
                      }
                    />{" "}
                    {submitted && !login.password && (
                      <div className="invalid-feedback">
                        パスワードが必要です
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="algn_centr flt">
                <button className="login_btn">
                  <div className="">
                    {" "}
                    {/* {loggingIn && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )} */}
                    ログイン{" "}
                  </div>
                </button>
              </div>
              <div className="forgtpwdtxt algn_centr">
                <Link className="forgttxt" to="" onClick={handleSubmitLink}>
                  パスワードをお忘れの方
                </Link>
              </div>
            </div>
          </div>

          <div className="algn_centr flt">
            <label to="" className="firsttime">
              初めてご利用になる方
            </label>
          </div>
        </form>
      </div>
    );
  };
  const renderLoginButton = () => {
    return (
      <div className="algn_centr flt">
        <button
          className="signup_btn"
          onClick={() => {
            history.push("/registration");
          }}
        >
          <div className="fltlft"> 新規登録</div>
          <div className="fltrgt">
            <i class="fas fa-chevron-right"></i>
          </div>
        </button>
        <div className="login_ptxt algn_centr">
          <p>
            ご利用には会員登録（無料）が必要です。
            <br />
            未登録の方は「新規登録」より会員登録をお願いします。
          </p>{" "}
        </div>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        <Header />
        <div className="flt">
          <div className="container">
            <div className="logi_container">
              {renderGreenBarLogin()}
              {renderSirusiLogoLogin()}
              {renderLoginForm()}
              {renderLoginButton()}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
