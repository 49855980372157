import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import usePrivacyPolicyHooks from './PrivacyPolicyHooks';

function Privacy() {
  // const [data, setData] = useState([]);
  // const [current, setcurrent] = useState(1);
  const { data } = usePrivacyPolicyHooks();



  //header
  const renderPrivacyPageGreenBar = () => {
    return (
      <div className="rectanglepriv flt">
        <span className="fontclrpriv">Privacy policy</span>
      </div>
    );
  };
  //privacy policy content display
  const renderPrivacyPageText = () => {
    return (
      <div className="para1priv flt">
        <div>
          {/* <p>{data.data && data.data.content} </p> */}

          <p dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
      </div>
    );
  };

  return (
    <div className="flt">
      <Header />
      <div className="container">
        <div className="main-containerC flt">
          {renderPrivacyPageGreenBar()}
          {renderPrivacyPageText()}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;