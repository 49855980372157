import "./Header.css";
import { Link, useHistory } from "react-router-dom";
// import logoimg from "../../../assets/images/logosir.png";
import loginimg from "../../../assets/images/loginlogo.png";
// import { isClose, token } from './HeaderFunction';
import useHeaderHooks from "./HeaderHooks";

function Header() {
  let history = useHistory();
  //importing all states and methods
  const { isOpen, token, openmenu, logout } = useHeaderHooks();

  //page design
  return (
    <div className="flt">
      <div className="header-container flt">
        <div className="row header_flex flt">
          <div className="col-sm-1">
            <div className="logo flex">
              <div className="header_logo_ico">
                <Link to="/">
                  <img src={loginimg} alt=" " />
                </Link>
              </div>
              <div className="filler"></div>
              <div class="mobileMenu_btn hide_desktop">
                <button onClick={openmenu}>
                  {/*change the icon of close and open menu */}
                  {!isOpen && <i className="fas fa-bars "></i>}
                  {isOpen && <i className="fas fa-times"></i>}
                </button>
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="col-sm-8">
              <div className="navdiv " id="mobileMenu">
                <ul className="nav_links blockview">
                  {/* menu links */}

                  <li className=" navbar-toggler">
                    <Link to="/firstuser">
                      印コーディングとは
                      <br />
                      <span className="clrchange">ABOUT</span>
                    </Link>
                  </li>

                  {/* <li className=" navbar-toggler">
                    <Link to="/design">
                      デザインもコーディング
                      <br />
                      <span className="clrchange">DESIGN & CODING</span>
                    </Link>
                  </li> */}
                  <li className="navbar-toggler">
                    <Link to="/price">
                      価格
                      <br />
                      <span className="clrchange">PRICE</span>
                    </Link>
                  </li>
                  {/* <li className="navbar-toggler">
                    <Link to="/prices">
                      価格s
                      <br />
                      <span className="clrchange">PRICEs</span>
                    </Link>
                  </li> */}
                  <li className="navbar-toggler">
                    <Link to="/service">
                      サービス
                      <br />
                      <span className="clrchange">SERVICE</span>
                    </Link>
                  </li>
                  <li className="navbar-toggler">
                    <Link to="/howtouse">
                      ご利用の流れ
                      <br />
                      <span className="clrchange">FLOW</span>
                    </Link>
                  </li>
                  {/* <li className="navbar-toggler">
                    <Link to="/os">
                      運用体制
                      <br />
                      <span className="clrchange">SYSTEM</span>
                    </Link>
                  </li> */}
                  <li className="navbar-toggler">
                    <Link to="/column">
                      コラム
                      <br />
                      <span className="clrchange">COLUMN</span>
                    </Link>
                  </li>
                  <li className="navbar-toggler">
                    <Link to="/qna">
                      よくある質問 <br />
                      <span className="clrchange">FAQ</span>
                    </Link>
                  </li>
                  <li className="navbar-toggler">
                    <a href={"https://avii.jp/"}>
                      運営会社サイト <br />
                      <span className="clrchange">AVII HP</span>
                    </a>
                  </li>
                  <li className="navbar-toggler">
                    <Link to="/contact">
                      お問い合わせ
                      <br />
                      <span className="clrchange">CONTACT</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {/* after login displaying  logout and dashboard button  */}

          <div className="col-sm-3">
            <div className="links flex_div">
              <div className="btn_center">
                {!token && (
                  <button
                    class="header_btn"
                    onClick={() => history.push("/Registration")}
                  >
                    <span className="header_span">
                      新規登録して、
                      <br />
                      チャットルームへ
                    </span>
                    <i class="fas fa-chevron-right arrow_header"></i>
                  </button>
                )}
                {token && (
                  <button class="header_btn" onClick={logout}>
                    <span className="header_span">ログアウト</span>
                    <i class="fas fa-chevron-right arrow_header"></i>
                  </button>
                )}
              </div>

              <div className="header_button btn_center">
                {!token && (
                  <button
                    class="header_btn"
                    onClick={() => history.push("/login")}
                  >
                    <span className="header_span">
                      ログインして
                      <br />
                      チャット
                    </span>
                    <i class="fas fa-chevron-right arrow_header1"></i>
                  </button>
                )}
                {token && (
                  <button
                    class="header_btn"
                    onClick={() => history.push("/chat")}
                  >
                    <span className="header_span">ダッシュボード</span>
                    <i class="fas fa-chevron-right arrow_header"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
