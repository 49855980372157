import pointerActions from '../_actions/pointer.actions';
import { setLocalData, getLocalData } from '../utility';

const initState = getLocalData();

export default function pointerRed(state = initState, action) {
  switch (action.type) {
    case pointerActions.ADD_IMAGE: {
      const { points, comments } = state;
      points[action.newImage.id] = [];
      comments[action.newImage.id] = [];
      const isImageCheck = state.images.filter(val => val.file === action.newImage.file);
      const totalImages = state.images.length;

      const newState = {
        ...state,
        images: totalImages > 14 || (isImageCheck && isImageCheck.length > 0) ? [...state.images] : [action.newImage, ...state.images],
        points,
        comments,
        selectedImage: state.images.length > 0 ? state.images[state.images.length - 1] : action.newImage,
        newPoint: null,
        selectedPoint: null,
        // isImageCheck: isImageCheck,
        totalImages: totalImages,
        message: isImageCheck && isImageCheck.length > 0 ? "画像はすでに存在しています" : totalImages >= 14 ? "15個のイメージをアップロードすることができます" : "",
        message2: totalImages >= 14 ? "15個のイメージをアップロードすることができます" : ""
      };



      setTimeout(() => {
        // alert("Dgfdsf");
        newState.message = '';
      }, 4000)

      setTimeout(() => {
        // alert("Dgfdsf");
        newState.message2 = '';
      }, 4000)



      // const newState = {
      //   ...state,
      //   images: [action.newImage, ...state.images],
      //   points,
      //   comments,
      //   selectedImage: action.newImage,
      //   newPoint: null,
      //   selectedPoint: null
      // };
      const error = setLocalData(newState);

      // if (state.images.length >= 5) {
      //   alert("hii");
      //   return false
      // }
      if (error) {
        //alert(error);
        // return state;
      }
      return newState;
    }
    case pointerActions.CHANGE_SELECTED_IMAGE: {
      const newState = {
        ...state,
        selectedImage: action.id,
        newPoint: null,
        selectedPoint: null
      };
      setLocalData(newState);
      return newState;
    }
    case pointerActions.EDIT_IMAGE: {
      const newImages = [];
      state.images.forEach(image => {
        if (image.id === action.image.id) {
          newImages.push(action.image);
        } else {
          newImages.push(image);
        }
      });
      const newState = {
        ...state,
        images: newImages
      };
      setLocalData(newState);
      return newState;
    }
    case pointerActions.DELETE_IMAGE: {
      const newImages = [];
      const newData = [];
      state.images.forEach((image, i) => {
        if (image.id === action.image.id) {
          newData.push(i);
        }
        if (image.id !== action.image.id) {
          newImages.push(image);
        }
      });




      const { points, comments } = state;
      points[action.image.id] = [];
      comments[action.image.id] = [];
      const newState = {
        ...state,
        images: newImages,
        points,
        comments,
        selectedImage: newImages[newData[0]] ? newImages[newData[0]] : newImages[newImages.length - 1]

      };

      setLocalData(newState);
      return newState;
    }
    case pointerActions.TOGGLE_OPTIONS: {
      return {
        ...state,
        [action.key]: action.value
      };
    }
    case pointerActions.SET_DIMENSION: {
      return { ...state, dimensions: action.dimensions };

    }

    case pointerActions.SET_NEW_POINT: {
      return {
        ...state,
        newPoint: action.newPoint,
        selectedPoint: action.newPoint
      };
    }
    case pointerActions.SET_SELECTED_POINT: {
      return {
        ...state,
        selectedPoint: action.selectedPoint,
        newPoint: null
      };
    }
    case pointerActions.SET_COMMENTS: {
      const id = action.selectedImage.id;
      const { points, comments } = state;
      points[id] = action.points;
      comments[id] = action.comments;
      const newState = {
        ...state,
        points,
        comments,
        newPoint: action.newPoint
      };
      setLocalData(newState);
      return newState;
    }
    default:
      return state;
  }
}
