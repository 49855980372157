import React from "react";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import "./Inquiry.css";

function Inquiry() {
    //heading display
    const renderGreenBarInq = () => {
        return (
            <div className="rectangleInq flt">
                <span className="fontclrInq">お問い合わせ完了</span>
            </div>
        );
    };
//inquiry text display
    const renderContentTextInq = () => {
        return (
            <p>
                サンプルテキスト サンプルテキスト サンプルテキスト サンプルテキスト
                サンプルテキスト サンプルテキスト サンプルテキスト サンプルテキスト
                サンプルテキスト サンプルテキスト サンプルテキスト サンプルテキスト
                サンプル テキストサンプルテキスト サンプルテキスト サンプルテキスト
                サンプルテキスト サンプルテキスト サンプルテキスト
            </p>
        );
    };
//tredirect to top page
    const renderTopPageButtonInq = () => {
        return (
            <div className="btInq">
                <button className="btnstyInq">
                    TOPに戻る
                    <i className="fas fa-chevron-right arrowInq"></i>
                </button>
            </div>
        );
    };
    return (
        <div className="flt">
            <Header />
            <div className="container">
                <div className="main-containerI flt">
                    {renderGreenBarInq()}
                    <div className="paraInq flt">
                        {renderContentTextInq()}
                        {renderTopPageButtonInq()}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Inquiry;