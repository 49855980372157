import React, { useEffect } from "react";
import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";
import img from "../../../assets/images/9999.png";
import img1 from "../../../assets/images/g1.png";
import img3 from "../../../assets/images/crc.png";
import img4 from "../../../assets/images/arr.png";
import img5 from "../../../assets/images/3circle_arrow.png";
import hazure from "../../../assets/images/hazuure.png";

const FirstUser = () => {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderGreenBarFirstUser = () => {
    return (
      <div className="service_bar flt">
        <img className="img_length" src={img1}></img>
        <div className="service_content3">初めてご利用される方へ</div>
      </div>
    );
  };

  const renderGreenButtonsFirstUser = () => {
    return (
      <div className="firstusr_buttons_container">
        <div className="row">
          <div className="col-sm-4">
            <a href="#1">
              <button className="firstusr_three_btn ">
                <div className="fltlft">印コーディングサービスとは？</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#2">
              <button className="firstusr_three_btn ">
                <div className="fltlft">選ばれる理由</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#3">
              <button className="firstusr_three_btn ">
                <div className="fltlft">ご利用イメージ</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>{" "}
            </a>
          </div>
          <br />
          <br />
          <div className="col-sm-4">
            <a href="#4">
              <button className="firstusr_three_btn ">
                <div className="fltlft">サポート・セキュリティー</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>{" "}
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#5">
              <button className="firstusr_three_btn ">
                <div className="fltlft">インドについて</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>{" "}
            </a>
          </div>
          <div className="col-sm-4">
            <a href="#6">
              <button className="firstusr_three_btn ">
                <div className="fltlft">海外アウトソーシングについて</div>
                <div className="fltrgt">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </button>{" "}
            </a>
          </div>
        </div>
      </div>
    );
  };
  const renderTextContentsFirstUser = () => {
    return (
      <div className="firstusr_para_cont">
        <div id="">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">印コーディングサービスとは？ </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="firstusr_para" id="1">
          <br />
          <div className="main_content_service">
            <div className="flt arrow_div_shir">
              <img src={img4}></img>
            </div>
            <div className="main_subcontent_service">
              印（しるし）コーディングサービスとは、デザインをアップロードするだけで、Webページ作成を行う画期的なサービスです。株
              <br />
              <span className="sms">
                式会社AVII IMAGEWORKSの運営するこのサービスは、AVII
                IMAGEWORKSインド支社と協力体制を構築し、コストを
              </span>
              <br />
              抑え筒もハイクオリティーなWebコンテンツが制作できるサービスを実現しました。
              <br />
              <br />
            </div>
          </div>
        </div>
        <div id="">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">選ばれる理由 </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="firstusr_para" id="2">
          <br />
          <div className="main_content_service ">
            <div className="flt arrow_div_shir">
              <img src={hazure}></img>
            </div>
            <div className="main_subcontent_service">
              ・手軽さ：でWebページの制作能力がなくても、 <br />
              &nbsp;&nbsp;
              &nbsp;デザインさえあれば誰でもWebページ制作を行うことが可能。
              <br />
              &nbsp;&nbsp;
              &nbsp;納品データをサーバーにアップロードしWEBサイトとして表示するところまで動画マニュアルでサポートします。
              <br />
              &nbsp;&nbsp;
              &nbsp;ドメイン、サーバーをご契約いただいていればドメイン、サーバーの設定、アップロードまでサポートさせていただくことも可能です。
              <br />
              <br />
              ・低コスト：インドは日本の1/５程の物価であることから、 <br />
              &nbsp;&nbsp; &nbsp;日本では実現不可能だった価格を実現。
              <br />
              <br />
            </div>
          </div>
        </div>
        <div id="stampcoding">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">利用イメージ </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="firstusr_para" id="3">
          <br />
          <div className="main_content_service">
            <div className="flt arrow_div_shir">
              <img src={img5}></img>
            </div>
            <div className="main_subcontent_service">
              お申し込みから約10分と短時間でご利用いただけます。
              <br />
              &nbsp;&nbsp; &nbsp;STEP1：新規登録 　<br />
              &nbsp;&nbsp; &nbsp;STEP2：データアップロード <br />
              &nbsp;&nbsp; &nbsp;STEP3：お打ち合わせ、その後インドにて制作{" "}
              <br />
              &nbsp;&nbsp; &nbsp;STEP4：修正等確認後、データ納品
              <br />
              <br />
            </div>
          </div>
        </div>
        <div id="stampcoding">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">サポート・セキュリティー </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="firstusr_para" id="4">
          <br />
          <div className="main_content_service">
            <div className="main_subcontent_service">
              <span>◉サポートについて</span>
              <br />
              印コーディングサービスサポートデスクは、各業界の品質基準、Web制作に精通した業界出身の日本人スタッフを中心に
              <br />
              構成されており、細かいご指定にも柔軟に対応しております。
              <br />
              平日は夜18時までのサポート受付対応、また、チャットツールでのサポートもおこなっており、気になる点がありましたら細かく、
              <br />
              お気軽にお問い合わせいただけます <br />
              <br />
              <span>◉セキュリティについて</span>
              <br />
              お客様のデータの安全を担保するため、印コーディングサービス日本支社、インド支社ともに高レベルのセキュリティ環境で運
              <br />
              用を行っております。（サーバーは日本サーバー使用）
              <br />
              <br />
            </div>
          </div>
        </div>
        <div id="stampcoding">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">インドの環境について </div>
        </div>{" "}
        <hr classNmae="new4" />{" "}
        <div className="firstusr_para" id="5">
          <br />
          <div className="flt">
            <img src={img}></img>
            <br />
            <br />
          </div>
          <div className="main_content_service">
            <div className="main_subcontent_service">
              学の強さと、英語の堪能さからIT大国になったインドでは、他国とは比べものにならないほど有能なエンジニアがおりま
              <br />
              す。日本のエンジニア会社ではあり得ないさまざまな言語を使い分け大きなプロジェクトを進行することができます。某大
              <br />
              手IT企業にも多くのインド人が活躍しているほど教育文化から優秀なエンジニアが沢山活されています。
              <br />
              <br />
            </div>
          </div>
        </div>
        <div id="stampcoding">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">海外アウトソーシングにつて </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="firstusr_para" id="6">
          <br />
          <div className="main_content_service">
            <div className="main_subcontent_service">
              海外アウトソーシングでのシステム開発やコーディングは「ベトナム」「ミャンマー」「台湾」などが近年では多く取り入れられてい
              <br />
              ます。もちろん、言語の壁もあったり感覚が日本人と違うことからトラブルにつながるケースは少なからずあるでしょう。
              <br />
              生まれ育った環境が違うことから、その様なトラブルはもちろんあり、そのようなトラブルをいかに少なくしていくかが肝心となります。
              <br />
              また能力は、文化的に勤勉で適当なことが少ない「ベトナム」「台湾」ですが、日本人と大差ないぐらいの方もいるでしょう。
              <br />
              <br />
              でもいかがでしょう？
              <br />
              <br />
              日本人より優秀で納期を3ヶ月プロジェクトを1ヶ月も早めてこれる力を持ち、
              <br />
              トラブルもなく、制作感覚は日本の感覚でコーディング、開発が格安でできれば、、、
              <br />
              <br />
              弊社ではそれを可能にしております。 <br />
              アウトソーシングとは少し違います。弊社、株式会社AVII
              IMAGEWORKSに依頼していただき日本人と制作内容を詰め合
              <br />
              わせます。サイトであればデザイン、システムであればUI、UX設計までご一緒し、あとはコーディング、システムとして具現
              <br />
              化するところをインド支部で行います。
              <br />
              <br />
              インド支部に所属する優秀エンジニアチームとの掛橋は弊社に在籍するインドスタッフが行います。実はデザ
              <br />
              イン、UI、UXの段階からフロントエンドはこのまま再現すれば良い状態として制作進行されています。またシステムですが設
              <br />
              計図はこちらでつくているのでインドでは組み立て作業を行いエラーがない様に制作するだけとなっています。この大きな
              <br />
              作業をエラーはほぼない状態かつ、
              凄まじいスピードで仕上げてきます。
              <br />
              <br />
              <br />
              これが私たち、印コーディングサービスです。
              <br />
              <br />
            </div>
          </div>
        </div>
        <div id="stampcoding">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">なぜインドなのか？ </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="firstusr_para" id="7">
          <br />
          <div className="main_content_service">
            <div className="main_subcontent_service">
              <br />
              インド人は基本的に積極的に物事を進めます。「前のめり」な姿勢で、出来ないという言葉を聞きません。これは生まれた
              <br />
              時から身分がきまってるカースト制度。この格差を超えていくためにはチャンスを掴み、そこで実力を発揮しないといけない
              <br />
              神からなのででしょう。
              <br />
              <br />
              またインド人のエンジニアとしての能力は非常に高いです。
              <br />
              インド国内だけでも多くの言葉がある中、第2言語は英語であることからビジネスでは基本英語は使われます。エンジニアは
              <br />
              基本的に英語をネイティブレベルで話し、多くの情報も掴み取っていることから言語習得も早く複数言語を扱います。日本
              <br />
              のエンジニア会社ではあり得ない種類の言語を使い分け様々なプロジェクトを進行することができます。
              <br />
              <br />
              日本人みたいに責任の所存、責任を追いたくないといった文化はなく、結果を出すためにどれが近道なのか？「まず実
              <br />
              行する」という精神のもと取り組み、また判断が早いです。トントン拍子で物事をすすめながら改善していきます。
              <br />
              日本の様に都度相談、合意をとりながらすすめるのではないことから早いスピード感と高い精度が期待できるでしょう。
              <br />
              <br />
              様々記載しましたが、
              <br />
              こんなにも優秀なインド人エンジニアですが、何よりインド物価はかなり安いです。こんな積極的で速度のはやいインドのエン
              <br />
              ジニアがいるからこそ私達はインドに支部を持つことを決めました。
              <br />
              <br />
            </div>
          </div>
        </div>
        <div id="stampcoding">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">インドのコーディング事情 </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="firstusr_para" id="7">
          <br />
          <div className="main_content_service">
            <div className="main_subcontent_service">
              <br />
              インドでは人口が多いことから若手エンジニアの就職率が50％以下となっておりエンジニアとして技術を
              <br />
              習得しても優秀でければ生き残れないのです。そんな環境だからこそ貪欲であり優秀なエンジニアが多くいます。アメリカの大手企業やシリ
              <br />
              コンバレーなどの最先端企業で活躍しているインド人もまた多くいます。
              <br />
              <br />
            </div>
          </div>
        </div>
        <div id="stampcoding">
          <i className="fas fa-square-full firstusr_fa"></i>
          <div className="firstusr_texthead">
            インドと日本のコーディング言語の違い{" "}
          </div>
        </div>{" "}
        <hr className="new4" />{" "}
        <div className="firstusr_para" id="7">
          <br />
          <div className="main_content_service">
            <div className="main_subcontent_service">
              日本のエンジニアは基本的にメインとする得意な言語を習得し、得意言語に関連したり似つかわしい言語習得している
              <br />
              ケースが多いですが、インドのエンジニアは多くの言語を習得しているケースが多いです。チャンスを掴まないと仕事も獲得で
              <br />
              きなことから多くの書籍を手に取って常に学ぶ姿勢をもっています。案件が生まれてから習得しながら具現化していく事も時
              <br />
              にはあったりもします。
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRegistrationButtonFirstUser = () => {
    return (
      <div className="algn_centr">
        <button
          className="firstusr_btn"
          onClick={() => history.push("/Registration")}
        >
          <div className="fltlft">
            会員登録なしでPDFで
            <br />
            無料見積もりしてみる
          </div>
          <div className="fltrgttt">
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };

  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        <Header />
        {renderGreenBarFirstUser()}
        <div className="flt">
          <div className="container">
            <div className="firstusr_container">
              {renderGreenButtonsFirstUser()}
              {renderTextContentsFirstUser()}
              {/* {renderRegistrationButtonFirstUser()} */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default FirstUser;
