import { Component } from 'react';
import PointComments from './pointComments';
import { getPointData } from '../../../utility';
import { colors } from '../../../styles';

const pointRadius = 5;

export default class extends Component {
  render() {
    const {
      id,
      selectedPoint,
      newPoint,
      showPoints,
      setSelectedPoint, x,
      y,
    } = this.props;
    const { width, height } = getPointData(this.props);
    const marginTop = height - pointRadius;
    const marginLeft = width - pointRadius;
    const backgroundColor = showPoints
      ? selectedPoint === id ? colors.selectedPoint : colors.primary
      : colors.transparent;
    return (
      <div
        style={{
          position: 'absolute',
          marginTop,
          marginLeft,

        }}
      >
        <div
          style={{
            width: pointRadius * 2,
            height: pointRadius * 2,
            backgroundColor,
            borderRadius: '50%',
            pointerEvents: 'auto'

          }}
          onClick={event => {
            event.stopPropagation();
            const newSelectedPoint = id === selectedPoint ? null : id;
            newSelectedPoint ? setSelectedPoint(newSelectedPoint) : setSelectedPoint(newSelectedPoint);

          }}
        />
        <PointComments newPoint={newPoint} pointId={id} x={x} y={y} />
      </div>
    );
  }
}
