import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

const useHeaderHooks = () => {
    const [isOpen, setIsOpen] = useState(true);
    const isClose = false;
    const [token, setToken] = useState(false);
    let history = useHistory();
    //media query menu
    function openmenu() {
        setIsOpen(!isOpen);
    }

    //code for logout
    function logout() {
        history.push('/login');
        // remove user from local storage to log user out
        localStorage.removeItem(`${process.env.REACT_APP_PROJECT_NAME}` + '_access_token');
        localStorage.removeItem(`${process.env.REACT_APP_PROJECT_NAME}` + '_user');
    }

    //closing memu
    const closeMenu = () => {
        setIsOpen(isOpen);
    }

    //get access token from local storage
    useEffect(() => {
        if (localStorage.getItem('shironuki_access_token') !== null) {
            setToken(true)
        }
    }, [])
    //returning all the states and functions
    return {
        isOpen,
        isClose,
        token,
        logout,
        closeMenu,
        openmenu
    }

}

export default useHeaderHooks;

