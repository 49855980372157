import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import loginimg from "../../../assets/images/loginlogo.png";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userActions } from "../../../_actions";
import forgotpasswordServices from "../../../_services/forgotpassword.services";
import message from "../../_common/Message/message";
import { confirmAlert } from "react-confirm-alert";
import { alertActions } from "../../../_actions/alert.actions";
import "./forgot password.css";
import useForgotPasswordHooks from './ForgotPasswordHook'
const ForgotPassword = () => {
  
  const {
    changepwd,
    passwordCheck,
    submitted,
    ResetPasswordButtonClickHandler,
    loggingIn,
    changeHandler,
    passwordValid
  } = useForgotPasswordHooks();
  //heading for forgot password
  const renderGreenBarForgotPwd = () => {
    return <div className="forgtpwd_bar">PASSWORD RECOVERY</div>;
  };
//logo forgot password
  const renderLogoForgotPwd = () => {
    return (
      <div className="forgtpwd_logo">
        <img src={loginimg} />{" "}
      </div>
    );
  };
//actual form design
  const renderFormForgotpwd = () => {
    return (
      <div className="forgtpwd_items_container">
        <form className="forgtpwd_form flt">
          <div className="forgtpwd_head">
            新しいパスワードを入力してください
          </div>
          <div className="forgtpwd_contnr">
            <div className="row">
              <div className="col-sm-4">
                <label for="uname" className="newpwdstyl">
                  新しいパスワード
                </label>
              </div>
              <div className="col-sm-4">
                <input
                  type="password"
                  name="password"
                  value={changepwd.password}
                  onChange={changeHandler}
                  className={
                    passwordValid && submitted && !changepwd.password
                      ? " is-invalid"
                      : "forgotpwd_input"
                  }
                />
                {submitted && !changepwd.password && (
                  <div className="invalid-feedback_m">
                    パスワード欄は必須項目です
                  </div>
                )}{" "}
                {!passwordValid && changepwd.password && (
                  <div className="invalid-feedback_m">
                    パスワードは6文字以上である必要があります。
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <label for="pwd" className="confirmpwdstyl">
                  新しいパスワードを確認{" "}
                </label>
              </div>
              <div className="col-sm-4">
                <input
                  type="password"
                  name="re_password"
                  value={changepwd.re_password}
                  onChange={changeHandler}
                  className={
                    passwordCheck && submitted && !changepwd.re_password
                      ? " is-invalid"
                      : "forgotpwd_input"
                  }
                />
                {!passwordCheck && changepwd.re_password && (
                  <div className="invalid-feedback_m">
                    両方のパスワードが一致する必要があります
                  </div>
                )}
                {submitted && !changepwd.re_password && (
                  <div className="invalid-feedback_m">
                    パスワードの再入力欄は必須です
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="algn_centr flt">
            <button
              className="forgtpwd_btn"
              onClick={ResetPasswordButtonClickHandler}
            >
              <div className="">
                {" "}
                {loggingIn && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                パスワードを更新する{" "}
              </div>
            </button>
          </div>
        </form>
      </div>
    );
  };
  return (
    <div className="main_container flt">
      <div className="landing_container flt">
        <Header />
        <div className="flt">
          <div className="container">
            <div className="forgtpwd_container">
              {renderGreenBarForgotPwd()}
              {renderLogoForgotPwd()}
              {renderFormForgotpwd()}
            </div>
          </div>
        </div>
        {/*End of  login contents section */}
      </div>
      {/*  Actual login page Ends */}
      <Footer />
    </div>
  );
};


export default ForgotPassword;
