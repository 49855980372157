import { useDispatch } from "react-redux";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";
import img from "../../../assets/images/img334.png";
import "./Contact.css";

import useContactPageHooks from "./ContactPageFunction";

function Contact() {
  //import all state and methods
  const {
    submitted,
    emailValid,
    checked,
    numberValid,
    add,
    button_disable,
    changeHandler,
    handleSubmit,
  } = useContactPageHooks();

  const renderGreenBarContact = () => {
    return (
      <div className="service_bar flt">
        <img className="img_length" src={img}></img>
        <div className="service_content">お問い合わせ</div>
      </div>
    );
  };
  const renderTextContact = () => {
    return (
      <p className="contact_space">
        下記の必須項目をご入力の上、入力内容にお間違いが無い場合は
        <br />
        「入力内容を確認」をクリック後、案内に従って送信してください。
        <br />
        <br />{" "}
        お急ぎの場合やお電話で相談をご希望の方は下記番号までお問い合わせください。
      </p>
    );
  };

  const renderGreyBoxContact = () => {
    return (
      <div className="bgclr flt">
        <div className="contact_text flt">
          <div className="flt line">
            <button className="button-icon">
              <i className="fas fa-phone-volume contact-icon"></i>
            </button>
            <span className="Ldiv">078-855-8220</span>
          </div>
          <div className="cont_box flt">
            <div className="span1">
              <span className="text1">受付時間</span>
              <span className="text2">9:00 - 18:00</span>
            </div>
            <div className="span2">
              <span className="text1">定休日</span>
              <span className="text2">日曜・祝祭日</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFormContact = () => {
    return (
      <div className="flt">
        <form className="form-group flt" autoComplete="off">
          <div className="row namepadding">
            <div className="col-sm-3">
              <label className="labeldesign">
                お名前<span className="label2design">必須</span>
              </label>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control inputdesign flt"
                placeholder="田中　太郎"
                name="name"
                value={add && add.name}
                onChange={changeHandler}
                className={submitted && add && !add.name ? " is-invalid" : ""}
              />
              {submitted && add && !add.name && (
                <div className="invalid-feedback">名前を変更してください</div>
              )}
            </div>
          </div>

          <div className="row namepadding">
            <div className="col-sm-3">
              <label className="labeldesign">電話番号</label>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control inputdesign"
                placeholder="090-XXXX-XXXX"
                name="mobile_no"
                value={add && add.mobile_no}
                maxLength="11"
                onChange={changeHandler}
                className={
                  numberValid && submitted && add && !add.mobile_no
                    ? " is-invalid"
                    : ""
                }
              />
              {!numberValid && add && add.mobile_no && (
                <div className="invalid-feedback">電話番号が間違っています</div>
              )}
              {submitted && add && !add.mobile_no && (
                <div className="invalid-feedback">電話番号は必須項目です</div>
              )}
            </div>
          </div>

          <div className="row namepadding">
            <div className="col-sm-3">
              <label className="labeldesign">
                メールアドレス<span className="label2design">必須</span>
              </label>
            </div>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control inputdesign"
                placeholder="tarou-tanaka@mail.co.jp"
                name="email"
                value={add && add.email}
                onChange={changeHandler}
                className={
                  emailValid && submitted && add && !add.email
                    ? " is-invalid"
                    : ""
                }
              />
              {!emailValid && add && add.email && (
                <div className="invalid-feedback">
                  メールアドレスが間違っています
                </div>
              )}
              {submitted && add && !add.email && (
                <div className="invalid-feedback">
                  メールアドレスは必須項目です
                </div>
              )}
            </div>
          </div>


          <div className="row namepadding">
            <div className="col-sm-3">
              <label className="labeldesign">
                ご相談内容<span className="label2design">必須</span>
              </label>
            </div>
            <div className="col-sm-8">
              <textarea
                className="form-control"
                rows="10"
                name="reason"
                value={add && add.reason}
                onChange={changeHandler}
                className={submitted && add && !add.reason ? " is-invalid" : ""}
              ></textarea>
              {submitted && add && !add.reason && (
                <div className="invalid-feedback">必須</div>
              )}
            </div>
          </div>
          <div className="row namepadding_top">
            <div className="col-sm-3"></div>
            <div className="col-sm-8 contact_content">
              ※求人の方は志望動機を記入ください。
            </div>
          </div>


          <div className="row namepadding">
            <div className="col-sm-3">
              <label className="labeldesign">
                当社を何で知りましたか<span className="label2design">必須</span>
              </label>
            </div>
            <div className="col-sm-8">
              <ul className="conrad">
                <li>
                  <input
                    type="radio"
                    className="form-control"
                    name="about"
                    value="WEB"
                    onChange={changeHandler}
                    className={
                      submitted && add && !add.about
                        ? " is-invalid"
                        : ""
                    }
                  />
                  <label>WEB</label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="form-control"
                    name="about"
                    value="SNSで見つけた "
                    onChange={changeHandler}
                    className={
                      submitted && add && !add.about
                        ? " is-invalid"
                        : ""
                    }
                  />
                  <label>SNSで見つけた </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="form-control"
                    name="about"
                    value="「コーディングサービス」と検索して、こちらのサイトを見つけた"
                    onChange={changeHandler}
                    className={
                      submitted && add && !add.about
                        ? " is-invalid"
                        : ""
                    }
                  />
                  <label>「コーディングサービス」と検索して、こちらのサイトを見つけた</label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="form-control"
                    name="about"
                    value="知り合いからの紹介"
                    onChange={changeHandler}
                    className={
                      submitted && add && !add.about
                        ? " is-invalid"
                        : ""
                    }
                  />
                  <label>知り合いからの紹介</label>
                </li>
                {submitted && add && !add.about && (
                  <div className="invalid-feedback">
                    必須
                  </div>
                )}
              </ul>
            </div>
          </div>


          <div className="row namepadding_bottom">
            <div className="col-sm-3"></div>
            <div className="col-sm-8">
              <span className="star">※</span>
              <span>
                <a className="clrReg" href="privacy" target="_blank">
                  プライバシーポリシー
                </a>
                と
              </span>
              をお読みいただき、同意の上ボタンを押して送信してください。
            </div>
          </div>

          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-8">
              <div className="contact_checkbox">
                <input
                  type="checkbox"
                  defaultChecked={checked}
                  onChange={button_disable}
                  className={
                    checked && submitted && add && !add.reason ? " " : ""
                  }
                />

                <label className="checklbl">
                  プライバシーポリシーに同意します。
                </label>
                <div className="bt">
                  <button
                    className={
                      !checked ? "disable btnsty_contact" : "btnsty_contact"
                    }
                    onClick={handleSubmit}
                  >
                    上記の内容で送信する
                    <i className="fas fa-chevron-right arrowcont"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="flt">
      <Header />
      {renderGreenBarContact()}
      <div className="container">
        <div className="main-containerC flt">
          <div className="contact_paragraph flt">
            {renderTextContact()}
            {renderGreyBoxContact()}
            {renderFormContact()}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
