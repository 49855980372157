const pointerActions = {
  ADD_IMAGE: 'ADD_IMAGE',
  CHANGE_SELECTED_IMAGE: 'CHANGE_SELECTED_IMAGE',
  EDIT_IMAGE: 'EDIT_IMAGE',
  DELETE_IMAGE: 'DELETE_IMAGE',
  TOGGLE_OPTIONS: 'TOGGLE_OPTIONS',
  SET_DIMENSION: 'SET_DIMENSION',
  SET_NEW_POINT: 'SET_NEW_POINT',
  SET_SELECTED_POINT: 'SET_SELECTED_POINT',
  SET_COMMENTS: 'SET_COMMENTS',
  addImage: (id, file, name) => {

    // localStorage.setItem('file', JSON.stringify(file.file))
    return (dispatch, getState) => {

      dispatch({
        type: pointerActions.ADD_IMAGE,
        newImage: {
          id,
          file,
          name



        }
      });
    };
  },
  changSelectedeImage: id => ({
    type: pointerActions.CHANGE_SELECTED_IMAGE,
    id
  }),
  editImage: image => ({
    type: pointerActions.EDIT_IMAGE,
    image
  }),
  deleteImage: image => ({
    type: pointerActions.DELETE_IMAGE,
    image
  }),
  toggleOptions: (key, value) => ({
    type: pointerActions.TOGGLE_OPTIONS,
    key,
    value
  }),
  setDimensions: dimensions => ({
    type: pointerActions.SET_DIMENSION,
    dimensions
  }),
  setNewPoint: newPoint => ({
    type: pointerActions.SET_NEW_POINT,
    newPoint
  }),
  setSelectedPoint: selectedPoint => ({
    type: pointerActions.SET_SELECTED_POINT,
    selectedPoint
  }),
  setComments: (selectedImage, points, comments, newPoint, x, y) => ({
    type: pointerActions.SET_COMMENTS,
    selectedImage,
    points,
    comments,
    newPoint,
    x, y
  })
};
export default pointerActions;
