import "./mainUI.css";
import io from "socket.io-client";
import { useEffect, useState } from "react";
import Chat from "./chat";
import Sidebar from "../../UserPanel/Userpanel";
import MessageServices from "../../../../_services/message.services";

const finaluserid = localStorage.getItem(
  `${process.env.REACT_APP_PROJECT_NAME}` + "_user_id_fetched"
);
function MainUI() {
  const [chatidd, setChatidd] = useState(1);
  const loadChatId = async () => {
    const data = await MessageServices.getchat();
    setChatidd(data.data[0].chat_id);
  };

  useEffect(() => {
    loadChatId();
  }, []);
  var underscoreID = localStorage.getItem(
    `${process.env.REACT_APP_PROJECT_NAME}` + "_UsersId"
  );
  var underscoreID1 = JSON.parse(underscoreID);
  var socket = io("https://chat.recursive-design.com/chat-" + chatidd, {
    query: "userId=" + underscoreID1,
  });

  return (
    <div>
      <div className="main_container">
        <Sidebar />

        <div className="coolchat">
          {chatidd && (
            <Chat
              socket={socket}
              username={finaluserid}
              settingChatId={chatidd}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainUI;
