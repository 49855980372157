// import { authHeader } from '../_helpers';
import axios from "axios";

export const userService = {
  login,
  logout,
};

async function login(user_id, password) {

  console.log(process.env.REACT_APP_BASE_URL);
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", user_id);
  bodyFormData.append("password", password);
  const requestOptions = {
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL}users/login`,
    data: bodyFormData,
    // headers: { "Content-Type": "multipart/form-data" },
  };

  return await axios(requestOptions)
    .then(handleResponse)
    .then((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem(
        `${process.env.REACT_APP_PROJECT_NAME}`+"_access_token",
        JSON.stringify(data.data.access_token)

      );
      localStorage.setItem(`${process.env.REACT_APP_PROJECT_NAME}`+"_UsersId", JSON.stringify(data.data.user._id));
      localStorage.setItem(`${process.env.REACT_APP_PROJECT_NAME}`+"_UserInfo", JSON.stringify(data.data.user));
      
      // localStorage.setItem("UserPassword", JSON.stringify(data.data.password));
    
      return data;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function handleResponse(response) {
  console.log(response);
  if (!response.statusText) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      logout();
      //window.location.reload(true);
    }
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data;
}
