import React, { useState } from 'react';
import { connect } from 'react-redux';
import BlankCenterDiv from './blankCenterDiv';
import pointerActions from '../../../_actions/pointer.actions';
import styles from '../../../styles';
import './image.css';
import moment from 'moment';

const { setSelectedPoint } = pointerActions;
const style = styles.AllComments;

function AllComments(props) {
  const {
    dimensions,
    selectedPoint,
    showAllComments,
    comments,
    setSelectedPoint
  } = props;
  var www = props.comments ? props.comments.length : 0;
  const commentComponent = comment => (
    <div
      key={comment.pointId}

      style={
        comment.pointId === selectedPoint ? (
          style.selecteCommentComponent
        ) : (
          style.commentComponent
        )
      }
      onClick={event => {
        event.stopPropagation();
        setSelectedPoint(comment.pointId);
      }}
    >
      <div style={style.commentBody} className="flex alias" >
        <div className="comment_icon_div">  <i class="fas fa-comment comment_ico"></i></div>
        <div>   <span className="commentSpan">{comment.comment}</span></div>
      </div>
      <hr className="imgtagcomment_hr" />
      <span className="commentTime">{moment(comment.time).startOf('ss').fromNow()}</span>
    </div>
  );
  const bodyStyle = showAllComments ? style.main : style.main;
  return (
    <div className="main">
      <span className="comment_header">コメントを挿入({www})</span>
      <hr className="imgtag_hr" />
      {comments === null ? (
        <BlankCenterDiv text="No Comments" />
      ) : (
        comments && comments.map(commentComponent)

      )}

    </div>
  );
}

function mapStateToProps(state) {
  const {
    dimensions,
    selectedImage,
    selectedPoint,
    comments,
    showAllComments
  } = state.pointerReducer;
  if (selectedImage) {
    const { id } = selectedImage;
    return {
      dimensions,
      selectedImage,
      selectedPoint,
      showAllComments,
      comments: comments[id]
    };
  }

}
export default connect(mapStateToProps, { setSelectedPoint })(AllComments);