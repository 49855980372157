import { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';

export default class extends Component {
  state = {
    input: true,
    fileValid: true
  };
  componentDidMount() {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    if (window.location.href.includes('cp/')) {
      console.log('button hide');
      this.setState({ input: false })


    } else {
      console.log('button show');
      this.setState({ input: true })
    }
  }
  handleImageChange = event => {

    console.log(event.target.files[0].name);
    if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {

      return false;
    }
    // event.preventDefault();

    // uploadField.onchange = function () {
    if (event.target.files[0].size > 8388608) {
      this.setState({ fileValid: false })
      confirmAlert({


        message: <div>イメージ画像は8MB以下とします</div>,
        closeOnEscape: false,
        closeOnClickOutside: false,
        //onClick={displayImage(data.data.comment[0].link)} 
        buttons: [
          {
            label: '退出',
            onClick: () => null
          }]
      })

      // // alert(this.state.fileValid)
      return false;

    };



    // };
    this.setState({ fileValid: '' })
    const { addImage } = this.props;
    const id = new Date().getTime();
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      if (reader.result) {
        addImage(id, reader.result, file.name);
      }
    };

    reader.readAsDataURL(file);

  };

  onInputReset = (e) => {
    e.target.value = '';
  }


  render() {
    return (

      // this.state.input ?
      //   (
      <div className="upbtn flt">

        <div className="filebackground">
          <div className={this.state.input ? "file_plus_ico" : "file_plus_ico1"}>
            <label for="file">  <i class="fas fa-upload upload_icon alias"></i></label>

            <input
              id="file"

              type="file"
              accept=".png,.jpeg,.jpg"
              className="inputUpload d-none "
              onChange={e => this.handleImageChange(e)}
              onClick={e => this.onInputReset(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}