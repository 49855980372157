import axios from "axios";
import { authHeader } from "../_helpers";
//test
const MessageServices = {
  get_message,
  fileUpload,
  getchat,
  getchatcount,
  clearchatcount,
  AcceptOrCorrect,
};

// let admin_Id = admin_Id;
// let userId = userId;

async function get_message(chat_id, page, search = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    url:
      `${process.env.REACT_APP_BASE_URL}users/message/` +
      chat_id +
      `?page=` +
      page +
      `&search=` +
      search,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}

async function fileUpload(file_path) {
  var bodyFormData = new FormData();
  bodyFormData.append("file_path", file_path);
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    url: `${process.env.REACT_APP_BASE_URL}/users/messages-fileupload`,
    data: bodyFormData,
  };
  return await axios(requestOptions).then(handleResponse);
}

async function getchat() {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    url: `${process.env.REACT_APP_BASE_URL}users/getchatId`,
    // headers: authHeader()
  };


  return await axios(requestOptions).then(handleResponse);
}

async function getchatcount() {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    url: `${process.env.REACT_APP_BASE_URL}users/getChatcount`,
    // headers: authHeader()
  };

  return await axios(requestOptions).then(handleResponse);
}

async function clearchatcount(chat_ide) {
  var bodyFormData = new FormData();
  bodyFormData.append("msgId", chat_ide);
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    url: `${process.env.REACT_APP_BASE_URL}users/chatread`,
    data: bodyFormData,
  };
  return await axios(requestOptions).then(handleResponse);
}
async function AcceptOrCorrect(chat_status, msgId) {
  var bodyFormData = new FormData();
  bodyFormData.append("chat_status", chat_status);
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    },
    url: `${process.env.REACT_APP_BASE_URL}users/chat-status/${msgId}`,
    data: bodyFormData,
  };

  return await axios(requestOptions).then(handleResponse);
}



function handleResponse(response) {
  console.log(response.data);

  if (!response.statusText) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      //window.location.reload(true);
    }
    const error =
      (response.data && response.data.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data;
}

export default MessageServices;

//////////////////////////////////////////////////////////////
