import "../../../assets/css/style.css";


function Dashboard() {
  return (
    <div className="main_container flt">
      <div className="landing_container flt">


        {/* Start of new section */}
        <div className="rectanglepriv flt">
          <span className="fontclrpriv">Dashboard Demo</span>
        </div>{" "}
      </div>
    </div>
  );
}

export default Dashboard;
