import React from "react";
import "../../../assets/css/style.css";
import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import { useHistory } from "react-router-dom";
import "./thankYou.css";

const Thankyou = () => {
  let history = useHistory();

  const renderGreenBarThankPage = () => {
    return <div className="thankyou_barC flt">登録内容</div>;
  };
  //thank you page content
  const renderTextContentThankPage = () => {
    return (
      <div className="thankyou_para_contact flt">
        <div className="thanks_para flt">
          この度は弊社WEBサイトよりお問い合わせいただき誠にありがとうございました。
          ご記入頂いた情報は無事送信されました。
          3日営業日以内に担当者より折り返しご連絡させていただきます{" "}
        </div>
      </div>
    );
  };
  //redirect to top page
  const renderReturnToTopThankPage = () => {
    return (
      <div className="thank_submit">
        <button onClick={() => history.push("/")} className="thankyou_btn">
          <div className="fltlft">TOPに戻る</div>
          <div className="fltrgt">
            <i class="fas fa-chevron-right"></i>
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="flt">
      <Header />
      <div className="container">
        <div className="main_containers flt">
          {renderGreenBarThankPage()}
          {renderTextContentThankPage()}
          {renderReturnToTopThankPage()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Thankyou;
