import { useState, useEffect } from "react";
import TermsServices from "../../../_services/Terms.services";
function useTermsHooks() {
    const [data, setData] = useState([]);
    let type = "tc";

    useEffect(() => {
        TermsServices.getData(type).then((data) => {
            setData(data.data);
        });
    }, []);
    return {
        data, type
    }
}
export default useTermsHooks