import { Component } from 'react';
import './img.css';
import { connect } from 'react-redux';
import ImagePicker from '../component/pages/ShirImageTag/imagePicker';
import SingleImage from '../component/pages/ShirImageTag/singleImage';
import actions from '../_actions/pointer.actions';
import styles, { isDesktop, isMac, isMobile } from '../styles';
import { setPointData } from '../utility';
var imageDimension;
// if (window.screen.width < 2048) {
//   imageDimension =
//   {
//     "bottom": 558.92578125,
//     "height": 2000,
//     "left": 5,
//     "right": 578.984375,
//     "top": 169.94140625,
//     "width": 822

//   }
//   // style.commentDiv = { width: '822px', height: '2000px' }
// }
// if (window.screen.width >= 2048) {
//   imageDimension =
//   {
//     "bottom": 558.92578125,
//     "height": 2000,
//     "left": 5,
//     "right": 578.984375,
//     "top": 169.94140625,
//     "width": 1252

//   }
//   // style.commentDiv = { width: '1252px', height: '2000px' }
// }
if (isDesktop()) {
  imageDimension = {
    "bottom": 558.92578125,
    "height": 2000,
    "left": 5,
    "right": 578.984375,
    "top": 169.94140625,
    "width": 664

  }
}
else if (isMobile()) {
  imageDimension = {
    "bottom": 558.92578125,
    "height": 2000,
    "left": 5,
    "right": 578.984375,
    "top": 169.94140625,
    "width": 600

  }
} else if (isMac()) {
  imageDimension = {
    "bottom": 558.92578125,
    "height": 3000,
    "left": 5,
    "right": 578.984375,
    "top": 169.94140625,
    "width": 1252

  }
} else {
  imageDimension = {
    "bottom": 558.92578125,
    "height": 2000,
    "left": 5,
    "right": 578.984375,
    "top": 169.94140625,
    "width": 775

  }
}


const { addImage, changSelectedeImage, setSelectedPoint, setComments } = actions;
const style = styles.Gallary;

class Gallary extends Component {

  constructor(props) {
    super(props);

    // this.state = { images: [],selectedData = null };
    this.state = { images: [], dimensions: [] };
  }

  // componentDidMount() {
  //   this.initializeData();

  // }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.initializeData();
      localStorage.removeItem('reactDotImageState');
    }
  }


  initializeData = () => {
    var imgDataDummy = [];
    const finalDimension = this.state.dimensions && this.state.dimensions.height ? this.state.dimensions : imageDimension;
    this.props.data && this.props.data.map(image => {
      const id = image.id;
      imgDataDummy.push({ id, file: image.file, name: image.name });
      this.props.addImage(id, image.file, image.name)
      console.error(image.link)
      var commentArr = [];
      var pointArr = [];

      image && image.pointData && image.pointData.map(pointData => {
        const pointId = setPointData(finalDimension, pointData.x, pointData.y);
        const x = pointData.x;
        const y = pointData.y;
        const comment = pointData.comment;
        const newComment = {
          comment,
          id: new Date().getTime(),
          time: pointData.time,
          user: 'User',
          pointId,
          x,
          y

        };

        //const newComments = [...this.props.comments, newComment];
        // const newPoints = [...this.props.points, pointId];
        //this.props.setComments(this.props.selectedImage, newPoints, newComments);
        //commentArr = this.props.comments.slice();
        commentArr.push(newComment);

        //pointArr = this.props.points.slice();
        pointArr.push(pointId)
      })
      this.props.setComments({ id, file: image.file, name: image.name }, pointArr, commentArr);

    });
    this.setState({ images: imgDataDummy });

  }

  /* componentDidMount() {
     var d = [];
     this.props.data.map(image => {
       return
       d.push({
         file: image.image,
         id: image._id
       })
       
     });
     this.setState({
       ...this.state,
       imgData: d
     });
   }*/
  handleSubmit = event => {
    event.preventDefault();
  };

  handleImageChange = event => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    console.log(file)
    reader.onload = () => {
      if (reader.result) {
        this.props.addImage(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };
  render() {
    localStorage.removeItem("reactDotImageState")


    const { changSelectedeImage, setSelectedPoint } = this.props;
    // console.log(this.props.data.data && this.props.data.data.images);
    console.log(this.props.data);
    return (

      <div
        // style={style.main}
        className="main_image"
        onClick={() => {
          setSelectedPoint(null);
        }}
      >


        <div className={this.props.images.length >= 15 ? 'imgdisable' : 'imgdisable1'} >
          {this.props.images && this.props.images.length <= 15 &&
            <ImagePicker addImage={this.props.addImage} />

          }

          {/* {this.props.images && this.props.images.length >= 15 && <p className="image_length">Only 15 images are allowed</p>}
          {this.props.message && <p className="image_length">{this.props.message}</p>} */}
        </div>
        {/* <div className="divflex"></div> */}
        <div style={style.images} className="abc">

          {this.props.images && this.props.images.map(image => (

            <SingleImage
              key={image.id}
              image={image}
              changSelectedeImage={changSelectedeImage}

            />


          ))}

        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // const loggingIn = useSelector(state => state.authentication.loggingIn);
  const images = state.pointerReducer.images;
  const selectedImage = state.pointerReducer.selectedImage;
  const points = state.pointerReducer.points;
  const comments = state.pointerReducer.comments;
  const dimensions = state.pointerReducer.dimensions;
  const message = state.pointerReducer.message;
  return { images, selectedImage, points, comments, dimensions, message };


}

export default connect(mapStateToProps, {
  addImage,
  setComments,
  changSelectedeImage,
  setSelectedPoint
})(Gallary);